/*Package importing*/
import React, { Component } from 'react';
import { render } from 'react-dom';
import ReactAwesomePlayer from 'react-awesome-player';


/*Components*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';

/*Assets*/

/* Define */
var videoData = {};

class VideoPlayer2 extends Component {
  constructor(props){
    super(props);
    this.state = {
      options: null
    }
  }

  componentDidMount() {

    if(this.props.data){
      videoData = this.props.data;
    }
    else{
      videoData = {
        video_url: "https://alicebluecoursebucket.s3.ap-south-1.amazonaws.com/Pexels+Videos+4800.mp4"
      }
    }

    var tempArry = {
      sources: [{
        type: "video/mp4",
        src: videoData.video_url
      }],
      subtitles: [{
          language: 'en',
          url: "https://feawesome.github.io/react-awesome-player/en.vtt",
          label: "EN"
      }],
      defaultSubtitle: 'en',
     autoplay: true
    }
    tempArry.sources.src = videoData.video_url;
    this.setState({ options: tempArry, videoPlayerStatus: true});
  }

  loadeddata() {
    // console.log('loadeddata')
  }
  canplay() {
    // console.log('canplay')
  }
  canplaythrough() {
    // console.log('canplaythrough')
  }
  play() {
    // console.log('play')
  }
  pause() {
    // console.log('pause')
  }
  waiting() {
    // console.log('waiting')
  }
  playing() {
    // console.log('playing')
  }
  ended() {
    var url = devconfig.user+"/course/mark-as-viewed";
    var data = JSON.stringify({
      "video_id" : videoData.id
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.props.videoEnd(videoData.id);
      }
    }.bind(this))
  }
  error() {
    // console.log('error')
  }

  render() {
    // console.log("this.props.data.id");
    // console.log(this.props.data.id);

    const { options } = this.state
    return (
      <div>
        {
          this.state.options ?
            <ReactAwesomePlayer
              options={this.state.options}
              loadeddata={this.loadeddata}
              canplay={this.canplay}
              canplaythrough={this.canplaythrough}
              play={this.play}
              pause={this.pause}
              waiting={this.waiting}
              playing={this.playing}
              ended={this.ended.bind(this)}
              error={this.error}
            />
          :
            null
        }

      </div>
    );
  }
}

export default VideoPlayer2;
