/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import CardPlaceholder from '../../components/placeholder/card';
import CourseCard from '../../components/cards/card';
import NoDataFound from '../../components/common/nothing';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';

/*Assets*/
import cover from '../../assets/images/home/cover.png';
import BannerPattern1 from '../../assets/images/all-courses/banner-pattern-1.svg';
import BannerPattern2 from '../../assets/images/all-courses/banner-pattern-2.svg';

class AllCourseListing extends Component {
  constructor(props){
    super(props);
    this.state = {
      filterActive: false,
      langFilterActive: false,
      sortActive: false,
      data: null,
      dataCategory: null,
      dataLang: null,
      dataLoading: false,
      placeholder4: [1, 2, 3, 4],
      selectedCategories: [],
      selectedLang: [],
      selectedPricing: null,
      selectedSort: 1,
      pagination: false,
      pageNum: 1,
      paginationLoading: false,
      selectedOffer: false,
      catCourseCount: [],
      lagCourseCount: [],
    }
  }

  componentDidMount() {
    new WOW.WOW().init();
    $('html,body').animate({ scrollTop: 0 }, 'slow');

    var query = this.getUrlVars();
    if(query.id){
      this.getData(this.state.selectedPricing, [parseInt(query.id)], this.state.selectedLang, this.state.selectedSort, this.state.pageNum, this.state.selectedOffer);
      this.setState({selectedCategories: [parseInt(query.id)]});
    }
    else if(query.offer){
      this.setState({selectedOffer: true});
      this.getData(this.state.selectedPricing, this.state.selectedCategories, this.state.selectedLang, this.state.selectedSort, this.state.pageNum, false, true);
    }
    else{
      this.getData(this.state.selectedPricing, this.state.selectedCategories, this.state.selectedLang, this.state.selectedSort, this.state.pageNum, false, this.state.selectedOffer);
    }
    
    this.getCategoryData();
    this.getLangData();
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  filterClick(){
    this.setState({filterActive: !this.state.filterActive});
  }

  langFilterClick(){
    this.setState({langFilterActive: !this.state.langFilterActive});
  }

  sortFilterClick(){
    this.setState({sortActive: !this.state.sortActive});
  }

  getData(tempFree, tempCategories, tempLang, tempSort, tempPageNum, tempPagenumCallType, tempOffer){
    this.setState({dataLoading: true});

    var tempPrice = tempFree;
    if(tempOffer){
      tempPrice = null
    }

    var url = devconfig.user+"/course/all-courses";
    var data = JSON.stringify({
      "page_num" : tempPageNum,
      "free" : tempPrice,
      "offer" : tempOffer,
      "category_ids" : tempCategories,
      "language_ids" : tempLang,
      "sort_value" : tempSort
    })
    APIPost(url, data).then(function(response){
      if(response.status){


        if(this.state.data && tempPagenumCallType){
          var tempArray = this.state.data;
          tempArray.push(response.data);
          this.setState({ data: tempArray, dataLoading: false, paginationLoading: false, catCourseCount: response.category_course_count, lagCourseCount: response.course_count});
        }
        else{
          this.setState({ data: response.data, dataLoading: false, paginationLoading: false, catCourseCount: response.category_course_count, lagCourseCount: response.course_count});
        }

        if(response.data.length >= 39){
          this.setState({ pagination: true});
        }
        else{
          this.setState({ pagination: false});
        }


        new WOW.WOW().init();
      }
      else{
        this.setState({ paginationLoading: false, dataLoading: false});
      }
    }.bind(this))
  }

  getCategoryData(){
    var url = devconfig.user+"/course-category/get-all";
    
    APIGet(url).then(function(response){
      if(response.status){
        console.log(response.data);

        this.setState({ dataCategory: response.data});
        new WOW.WOW().init();

        var query = this.getUrlVars();
        if(!query.id){

          var tempArry = [];
          response.data.map(function(item, index){
            tempArry.push(item.id);
          });

          this.setState({ selectedCategories: tempArry});
        }

      }
      else{
        this.setState({ dataCategory: null});
      }
    }.bind(this))
  }

  getLangData(){
    var url = devconfig.user+"/course-language/get-all";
    
    APIGet(url).then(function(response){
      if(response.status){
        console.log(response.data);

        this.setState({ dataLang: response.data});
        new WOW.WOW().init();

        var query = this.getUrlVars();
        if(!query.id){

          var tempArry = [];
          response.data.map(function(item, index){
            tempArry.push(item.id);
          });

          this.setState({ selectedLang: tempArry});
        }


      }
      else{
        this.setState({ dataLang: null});
      }
    }.bind(this))
  }

  checkClick(tempItem){
    var tempArry = this.state.selectedCategories;

    if(tempArry.indexOf(tempItem) == -1){
      tempArry.push(tempItem);
    }
    else{
      var index = tempArry.indexOf(tempItem);
      tempArry.splice(index, 1)
    }
    this.setState({ selectedCategories: tempArry});
  }

  pricingClick(tempItem){
    this.setState({ selectedPricing: tempItem, selectedOffer: false});
  }

  applyFilterClick(){
    this.getData(this.state.selectedPricing, this.state.selectedCategories, this.state.selectedLang, this.state.selectedSort, this.state.pageNum, false, this.state.selectedOffer);
    this.filterClick();
  }

  checkLangClick(tempItem){
    var tempArry = this.state.selectedLang;

    if(tempArry.indexOf(tempItem) == -1){
      tempArry.push(tempItem);
    }
    else{
      var index = tempArry.indexOf(tempItem);
      tempArry.splice(index, 1)
    }
    this.setState({ selectedLang: tempArry});
  }

  applyLangFilterClick(){
    this.getData(this.state.selectedPricing, this.state.selectedCategories, this.state.selectedLang, this.state.selectedSort, this.state.pageNum, false, this.state.selectedOffer);
    this.langFilterClick();
  }

  sortClick(tempItem){
    this.setState({ selectedSort: tempItem});
  }

  applySortClick(){
    this.getData(this.state.selectedPricing, this.state.selectedCategories, this.state.selectedLang, this.state.selectedSort, this.state.pageNum, false, this.state.selectedOffer);
    this.sortFilterClick();
  }

  clearLangClick(){
    this.setState({ selectedLang: [] });
  }

  clearFilterClick(){
    this.setState({ selectedCategories: [] });
  }

  loadMore(){
    this.getData(this.state.selectedPricing, this.state.selectedCategories, this.state.selectedLang, this.state.selectedSort, this.state.pageNum + 1, true, this.state.selectedOffer);
    this.setState({ pageNum: this.state.pageNum + 1, paginationLoading: true});
  }

  offerClick(tempStatus){
    this.setState({ selectedOffer:  tempStatus});
  }

  


  render() {
    var tempIndex = 1;
    return (
      <div className="e-home e-all-course-wrap pb-5">
        <section className="e-banner-section position-relative e-allcourse-banner">
          <div className="container position-relative">
            <img className="e-banner-pattern-1 wow fadeIn" data-wow-duration="4s" src={BannerPattern1} alt="Tradeschool courses listing"/>
            <div className="row">
              <div className="col-lg-12">
                <h3>All Courses</h3>
              </div>
            </div>
            <img className="e-banner-pattern-2 wow fadeIn" data-wow-duration="4s" src={BannerPattern2} alt="Tradeschool courses listing"/>
          </div>
        </section>

        <section className="my-4">
          <div className="container">
            <div className="row mb-4 position-relative">
              <div className="col-lg-2 col-md-4 col-6">
                <div className="e-filter-item" onClick={this.filterClick.bind(this)}>
                  Filter
                  {
                    this.state.selectedCategories.length ?
                      <span>{this.state.selectedCategories.length}</span>
                    :
                      <span className="e-green-dot"></span>
                  }


                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="e-filter-item e-filter-item-lang" onClick={this.langFilterClick.bind(this)}>
                  Language
                  {
                    this.state.selectedLang.length ?
                      <span>{this.state.selectedLang.length}</span>
                    :
                      null
                  }
                </div>
                {
                  this.state.langFilterActive ?
                    <div className="e-filter-wrap e-lang-filter-wrap">
                      <h6 className="mb-3">Languages</h6>
                      {
                        this.state.dataCategory ?
                          <div className="row">
                            {/*<div className="col-lg-4 mb-3">
                              <label className="e-checkbox-wrap">
                                All
                                {
                                  this.state.selectedLang.length ?
                                    <span>
                                      <input type="checkbox" onClick={this.clearLangClick.bind(this)}/>
                                      <span className="checkmark"></span>
                                    </span>
                                  :
                                    <span>
                                      <input type="checkbox" defaultChecked onClick={this.clearLangClick.bind(this)}/>
                                      <span className="checkmark"></span>
                                    </span>
                                }
                                
                              </label>
                            </div>*/}

                            {
                              this.state.dataLang.map( (data, index) => {
                                var tempArry = this.state.selectedLang;

                                var tempItem = $.grep(this.state.lagCourseCount, function(item) {
                                  return item.id === data.id;
                                });

                                console.log("tempItem");
                                console.log(tempItem);


                                return(
                                  <div className="col-lg-12 mb-3" key={index}>
                                    <label className="e-checkbox-wrap">
                                      {data.name} {tempItem.length ? <span className="e-videos-count-tag">{tempItem[0].count} videos</span> : <span className="e-videos-count-tag">0 videos</span>}


                                      {
                                        tempArry.indexOf(data.id) == -1 ?
                                          <input type="checkbox" onClick={this.checkLangClick.bind(this, data.id)}/>
                                        :
                                          <input type="checkbox" onClick={this.checkLangClick.bind(this, data.id)} defaultChecked/>
                                      }
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                );
                              })
                            }
                          </div>
                        :
                          null
                      }
                      <div className="row">
                        <div className="col-lg-12 text-right">
                          <span className="e-p-btn" onClick={this.applyLangFilterClick.bind(this)}>Apply Filter</span>
                        </div>
                      </div>
                    </div>
                  :
                    null
                }
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="e-filter-item e-filter-item-sort" onClick={this.sortFilterClick.bind(this)}>
                  Sort
                  <span></span>
                </div>
                {
                  this.state.sortActive ?
                    <div className="e-filter-wrap e-lang-filter-wrap e-sort-filter-wrap">
                      <h6 className="mb-3">Sort</h6>
                      <div>
                        {
                          this.state.selectedSort == 1 ?
                            <input type="radio" id="test2" name="radio-group" onClick={this.sortClick.bind(this, 1)} defaultChecked/>
                          :
                            <input type="radio" id="test2" name="radio-group" onClick={this.sortClick.bind(this, 1)}/>
                        }
                        <label htmlFor="test2">Popularity</label>

                        {
                          this.state.selectedSort == 2 ?
                            <input type="radio" id="test3" name="radio-group" onClick={this.sortClick.bind(this, 2)} defaultChecked/>
                          :
                            <input type="radio" id="test3" name="radio-group" onClick={this.sortClick.bind(this, 2)}/>
                        }
                        
                        <label htmlFor="test3">Created by date</label>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-12 text-right">
                          <span className="e-p-btn" onClick={this.applySortClick.bind(this)}>Apply Sort</span>
                        </div>
                      </div>
                    </div>
                  :
                    null
                }

              </div>

              {
                this.state.filterActive ?
                  <div className="e-filter-wrap">
                    <h6 className="mb-3">Pricing</h6>
                    <div>
                      {
                        this.state.selectedPricing == null ?
                          <input type="radio" id="test1" name="radio-group" onClick={this.pricingClick.bind(this, null)} defaultChecked/>
                        :
                          <input type="radio" id="test1" name="radio-group" onClick={this.pricingClick.bind(this, null)}/>
                      }
                      <label htmlFor="test1">All</label>

                      {
                        this.state.selectedPricing == true ?
                          <input type="radio" id="test2" name="radio-group" onClick={this.pricingClick.bind(this, true)} defaultChecked/>
                        :
                          <input type="radio" id="test2" name="radio-group" onClick={this.pricingClick.bind(this, true)}/>
                      }
                      <label htmlFor="test2">Free</label>

                      {
                        this.state.selectedPricing == false ?
                          <input type="radio" id="test3" name="radio-group" onClick={this.pricingClick.bind(this, false)} defaultChecked/>
                        :
                          <input type="radio" id="test3" name="radio-group" onClick={this.pricingClick.bind(this, false)}/>
                      }
                      <label htmlFor="test3">Paid</label>

                      {
                        this.state.selectedOffer == true ?
                          <input type="radio" id="test4" name="radio-group" onClick={this.offerClick.bind(this, false)} defaultChecked/>
                        :
                          <input type="radio" id="test4" name="radio-group" onClick={this.offerClick.bind(this, true)}/>
                      }
                      <label htmlFor="test4" className='mt-2' >Courses with Offer</label>
                    </div>

                    <h6 className="mb-3 mt-5">Categories</h6>
                    {
                      this.state.dataCategory ?
                        <div className="row">

                          {/*<div className="col-lg-4 mb-3">
                            <label className="e-checkbox-wrap">
                            
                            All
                            {
                              this.state.selectedCategories.length ?
                                <span>
                                  <input type="checkbox" onClick={this.clearFilterClick.bind(this)}/>
                                  <span className="checkmark"></span>
                                </span>
                              :
                                <span>
                                  <input type="checkbox" onClick={this.clearFilterClick.bind(this)} defaultChecked/>
                                  <span className="checkmark"></span>
                                </span>
                            }
                            </label>

                          </div>*/}

                          {
                            this.state.dataCategory.map( (data, index) => {
                              var tempArry = this.state.selectedCategories;

                              var tempItem = $.grep(this.state.catCourseCount, function(item) {
                                return item.id === data.id;
                              });

                              console.log("tempItem");
                              console.log(tempItem);


                              return(
                                <div className="col-lg-4 mb-3" key={index}>
                                  <label className="e-checkbox-wrap">
                                    {data.name} {tempItem.length ? <span className="e-videos-count-tag">{tempItem[0].count} videos</span> : <span className="e-videos-count-tag">0 videos</span>}
                                    {
                                      tempArry.indexOf(data.id) == -1 ?
                                        <input type="checkbox" onClick={this.checkClick.bind(this, data.id)}/>
                                      :
                                        <input type="checkbox" onClick={this.checkClick.bind(this, data.id)} defaultChecked/>
                                    }
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              );
                            })
                          }
                        </div>
                      :
                        null
                    }
                    <div className="row">
                      <div className="col-lg-12 text-right">
                        {
                          this.state.selectedCategories && this.state.selectedCategories.length?
                            <span className="e-p-btn" onClick={this.applyFilterClick.bind(this)}>Apply Filter</span>
                          :
                            <span className="e-p-btn e-inactive" >Apply Filter</span>
                        }
                      </div>
                    </div>
                  </div>
                :
                  null
              }
              
            </div>
            {
              this.state.filterActive ?
                <div className="e-filter-overlay" onClick={this.filterClick.bind(this)}></div>
              :
                null
            }

            {
              this.state.langFilterActive ?
                <div className="e-filter-overlay" onClick={this.langFilterClick.bind(this)}></div>
              :
                null
            }

            {
              this.state.sortActive ?
                <div className="e-filter-overlay" onClick={this.sortFilterClick.bind(this)}></div>
              :
                null
            }


            {
              this.state.dataLoading ?
                <div className="row">
                  { 
                    this.state.placeholder4.map( (data, index) => {
                      return(
                        <div className="col-lg-3 col-md-6 col-6" key={index}>
                          <CardPlaceholder/>
                        </div>
                      );
                    })
                  }
                </div>
              :
                <div>
                  {
                    this.state.data && this.state.data.length ?
                      <div className="row">
                        { 
                          
                          this.state.data.map( (data, index) => {
                            if(tempIndex>9){
                              tempIndex = 1;
                            }
                            tempIndex = parseInt(tempIndex) + 1;

                            var tempDelay = "."+tempIndex+"s";
                            var tempURL = "/course-details?id="+data.id;

                            return(
                              <div className="col-lg-3 col-md-6 mb-4" key={index}>
                                <Link to={tempURL}>
                                  <CourseCard data={data} delay={tempDelay}/>
                                </Link>
                              </div>
                            );
                          })
                        }
                      </div>
                    :
                      <div>
                        <NoDataFound head="No Result" data="Reset the filter for more courses."/>
                      </div>
                  }
                </div>
            }


            {
              this.state.pagination ?
                <div className="row mt-3">
                  <div className="col-lg-12 text-center">
                    {
                      this.state.paginationLoading ?
                        <span className="e-pagination-loader"></span>
                      :
                        <span className="e-s-btn" onClick={this.loadMore.bind(this)}>Load more</span>
                    }
                  </div>
                </div>
              :
                null
            }
          </div>
        </section>
      </div>

      
    );
  }
}

export default AllCourseListing;
