/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';
import GoogleLogin from 'react-google-login';

/*Components*/
import BuyCourserModal from '../../components/modals/buy-course';
import VideoPlayer2 from '../../components/player/videoPlayer2';
import AddReviewModal from '../../components/modals/add-review';
import EditProfileModal from '../../components/modals/edit-profile';
import NoDataFound from '../../components/common/nothing';
import ReferEarn from '../../components/widget/ReferEarn';
/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/apiCall/post';
import GetURLVar from '../../services/getParams/get-url.js';

/*Assets*/
import pattern1 from '../../assets/images/home/banner-pattern-1.svg';
import cover from '../../assets/images/home/cover.png';
import AuthLogo from '../../assets/images/course-detail/author-logo.svg';
import PosterBanner1 from '../../assets/images/course-detail/app-store-banner-pattern-1.svg';
import PosterBanner2 from '../../assets/images/course-detail/app-store-banner-pattern-2.svg';
import Appstore from '../../assets/images/home/app-store.svg';
import Playstore from '../../assets/images/home/play-store.svg';
import PosterBanner from '../../assets/images/course-detail/poster-banner.png';
import PlayBtn from '../../assets/images/course-detail/play-btn.svg';

class CourseDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      id: 0,
      reviewData: null,
      videoPlayerStatus: false,
      videoData: null,
      videoLoading: false,
      enrolled: false,
      bookmarked: false,
      data: null,
      activeVideo: 0,
      tempClick: false,
      completedVideos: [],
      useWallet: false
    }
  }

  componentDidMount() {
    new WOW.WOW().init();

    $('html,body').animate({ scrollTop: 0 }, 'slow');

    GetURLVar().then(function(query){
     this.getData(query.id);
    this.getReviewData(query.id);
    this.setState({ id: query.id, profileData: JSON.parse(localStorage.getItem("userData")) });
        if(query.purchased){
         this.setState({purchased: true});
        
      }

        if(query.ts_refcode){
          this.setState({ref: query.ts_refcode});
          localStorage.setItem("ts_id", query.id);
          localStorage.setItem("ts_ref", query.ts_refcode);
        }
        else{
          if(query.id == localStorage.getItem("ts_id")){
            this.setState({ref: localStorage.getItem("ts_ref")});
          }
        }

         if(query.fbclid){
         var curl=  window.location.href;

           var newurl =curl.replace(/\&fbclid.+/, "");
        window.location.href=newurl
      }
      }.bind(this));

  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  tabItemClick(tempItem){
    $('html, body').animate({
      scrollTop: $(tempItem).offset().top
    }, 1000);
  }

  getData(tempID, tempEditSuccess){
    this.setState({dataLoading: true});

    var url = devconfig.user+"/course/detail";
    var data = JSON.stringify({
      "id" : tempID
    })
    APIPost(url, data).then(function(response){
      if(response.status){

        this.setState({ data: response.data, dataLoading: false, bookmarked: response.data.is_bookmarked});

        if(tempEditSuccess){
          $('#buyCourserModal').modal('show');
        }
        if(this.state.purchased == true){
          $('#addReviewModal').modal('show')
        }
      }
      else{
        this.setState({ data: null, dataLoading: false});
      }
    }.bind(this))
  }

  getReviewData(tempId){
    var url = devconfig.user+"/course/reviews";
    var data = JSON.stringify({
      "course_id" : tempId,
      "page_num" : 1,
      "filter_type" : null
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({ reviewData: response.data});
      }
      else{
        this.setState({ reviewData: []});
      }
    }.bind(this))
  }


  getVideoData(tempId){
    this.setState({ videoLoading: true});
    $('html,body').animate({ scrollTop: 0 }, 'slow');
    var url = devconfig.user+"/course/video";
    var data = JSON.stringify({
      "video_id" : tempId
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({ videoData: response.data, videoPlayerStatus: true, videoLoading: false});
        new WOW.WOW().init();
      }
      else{
        this.setState({ videoData: []});
      }
    }.bind(this))
  }

  videoPlayClick(tempVideoId, tempDivId){
    this.getVideoData(tempVideoId);
    this.setState({ activeVideo: tempVideoId});
  }

  wishlistToggle(){
    var url = devconfig.user+"/course/bookmark";
    var data = JSON.stringify({
      "course_id" : this.state.id
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({ bookmarked: !this.state.bookmarked});
      }
    }.bind(this))
  }

  addReviewSuccess(){
    this.getReviewData(this.state.id);

    $('#addReviewModal').modal('hide');
  }

  editSuccess(){
    this.getData(this.state.id, true);

    $('#buyCourserModal').modal('show');
  }

  responseGoogle(response){

    if(!response.error){
      this.apiSocialLogin(response.profileObj.name, response.profileObj.email, response.profileObj.imageUrl, response.googleId);
    }
    else{
      this.setState({triggerGoogleLogin: false, googleLoginLoading: false});
    }
  }

  googleLoginClick(){
    this.setState({triggerGoogleLogin: true, googleLoginLoading: true});
    $(".e-google-login-wrap button").trigger( "click" );
  }

  apiSocialLogin(tempName, tempEmail, tempURL, tempID){
    var url = devconfig.user+"/auth/login";
    var data = JSON.stringify({
      "email" : tempEmail,
      "name" : tempName,
      "social_user_id" : tempID,
      "image" : tempURL,
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        localStorage.setItem("tradeschool_access_token", response.access_token);
        localStorage.setItem("userData", JSON.stringify(response.data));
        localStorage.setItem("tradeschool_token", true);
        window.location.reload();
      }
      else{
      }
    }.bind(this))
  }

  videoEnded(tempId){
    var tempCompleted = this.state.completedVideos;
    tempCompleted.push(tempId);
    this.setState({completedVideos: tempCompleted});

  }
  openModal =() =>{
     this.setState({useWallet : true});
     $('#buyCourserModal').modal('toggle');
  }
  render() {
    if(this.state.dataLoading){
      return (
        <div className="e-home e-course-detail-wrap">
          <section className="e-banner-section position-relative e-placeholder">
          </section>
          <section className="e-tab-title-wrap e-placeholder e-card-placeholder e-order-placeholder position-relative">
            <span></span>
          </section>
          <section className="e-modules-wrap e-placeholder mt-5">

          </section>
        </div>
      );
    }
    else{
      var tempRightSectionClass = "col-lg-5 order-lg-2 order-md-1 order-sm-1 order-1 e-course-right-section";

      if(this.state.videoPlayerStatus || (this.state.data && this.state.data.is_enrolled)){
        tempRightSectionClass = "col-lg-5 order-lg-2 order-md-1 order-sm-1 order-1 e-course-right-section e-player-active";
      }

      if(this.state.data){
        var tempCreatorUrl = "/tutor?id="+this.state.data.creator_id
      }


      return (

        <div>
          {
            this.state.data ?
              <div className="e-home e-course-detail-wrap">
                {
                  this.state.videoPlayerStatus ?
                    <section className="position-relative">
                      {
                        this.state.videoLoading ?
                          <div className="e-video-laoder">
                            <div>Loading video...</div>
                          </div>
                        :
                          <div>
                            <VideoPlayer2 data={this.state.videoData} videoEnd={this.videoEnded.bind(this)} />
                          </div>
                      }
                    </section>
                  :
                    <section className="e-banner-section e-banner-section-course position-relative">

                      <img className="e-banner-pattern-1 wow fadeIn" data-wow-duration="4s" src={pattern1} alt="Tradeschool courses listing"/>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-10">
                            <h1>{this.state.data.name}</h1>
                            <h2 className="pb-4">
                            {
                              this.state.data.categories.map( (data, index) => {
                                return(
                                  <span key={index}>{data.category} <b>•</b></span>
                                );
                              })
                            }
                            </h2>
                            <div className="e-course-tag-wrap">
                              <span className="e-course-tag">
                                <span className="e-review">{this.state.data.rating}</span>
                              </span>
                              <span className="e-course-tag">
                                {this.state.data.language}
                              </span>

                              <span>
                                {
                                  localStorage.getItem("tradeschool_access_token") ?
                                    <span>
                                      {
                                        this.state.bookmarked ?
                                          <span className="e-course-wishlist e-bookmarked" onClick={this.wishlistToggle.bind(this)}></span>
                                        :
                                          <span className="e-course-wishlist" onClick={this.wishlistToggle.bind(this)}></span>
                                      }
                                    </span>
                                  :
                                    null
                                }

                              </span>
                            </div>

                            <div className="e-author-detail e-card-transition">
                              <Link to={tempCreatorUrl}>
                                <div className="media">
                                  {
                                    this.state.data.creator_image ?
                                      <img className="mr-3" src={this.state.data.creator_image} alt="Tradeschool Author Logo"/>
                                    :
                                      null
                                  }

                                  <div className="media-body">
                                    <h5 className="mt-0">{this.state.data.creator}</h5>
                                    <p>{this.state.data.last_published}</p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Video Player dummy init */}
                      <div className='e-video-dummy'>
                        <VideoPlayer2 data={this.state.videoData} videoEnd={this.videoEnded.bind(this)}/>
                      </div>

                    </section>
                }

                <section className="e-tab-title-wrap">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <span className="e-course-tab-item e-active" onClick={this.tabItemClick.bind(this, "#e-modules")}>Lectures</span>
                        {
                          this.state.data.description ?
                            <span className="e-course-tab-item" onClick={this.tabItemClick.bind(this, "#e-brief")}>What you’ll learn</span>
                          :
                            null

                        }

                        {
                          this.state.reviewData && this.state.reviewData.reviews.length ?
                            <span className="e-course-tab-item" onClick={this.tabItemClick.bind(this, "#e-review")}>Reviews ({this.state.reviewData.reviews.length})</span>
                          :
                            null
                        }

                      </div>
                    </div>
                  </div>
                </section>

                <section id="e-modules" className="e-modules-wrap mt-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 order-lg-1 order-md-2 order-sm-2 order-2">
                        <h4>Modules</h4>

                        {
                          this.state.data.course_modules.map( (data, index) => {
                            return(
                              <div className="e-module-item-wrap" key={index} >
                                <h6>{index+1}. {data.name}</h6>

                                {
                                  data.videos.map( (video, index1) => {
                                    var tempClass = "e-module-item e-card-transition";

                                    if(this.state.activeVideo == video.id){
                                      tempClass = tempClass + " e-active-video";
                                    }

                                    if(video.viewed && this.state.data.is_enrolled){
                                      tempClass = tempClass + " e-viewed-video";
                                    }

                                    var tempCompleted = this.state.completedVideos.filter(function(item) {
                                        return item == video.id;
                                    });

                                    if(video.completed && this.state.data.is_enrolled || tempCompleted.length){
                                      tempClass = tempClass + " e-completed-video";
                                    }

                                    var tempID = "e-video"+video.id;
                                    var tempIDSelect = "#e-video"+video.id;

                                    return(
                                      <div key={index1} id={ index1 == data.videos.length-1 ? "e-brief" : null}>
                                        {
                                          video.free || this.state.data.is_enrolled ?
                                            <div id={tempID} className={tempClass} onClick={this.videoPlayClick.bind(this, video.id, tempIDSelect)}>
                                              <div className="media">
                                                <div className="position-relative mr-3">
                                                  <img className="" src={video.thumbnail} alt={video.name}/>
                                                  <span className="e-video-preview-overlay"></span>
                                                </div>
                                                <div className="media-body position-relative">
                                                  <h5 className="mt-0">{video.name}</h5>
                                                  <p>{video.duration_string}</p>
                                                  {
                                                    !this.state.data.is_enrolled && this.state.activeVideo != video.id ?
                                                      <span className="e-free-tag">Free</span>
                                                    :
                                                      null
                                                  }

                                                </div>
                                              </div>
                                            </div>
                                          :
                                            <div className="e-module-item" key={index}>
                                              <div className="media">
                                                <div className="position-relative mr-3">
                                                  <img className="" src={video.thumbnail} alt={video.name}/>
                                                  <span className="e-video-preview-overlay"></span>
                                                </div>
                                                <div className="media-body position-relative">
                                                  <h5 className="mt-0">{video.name}</h5>
                                                  <p>{video.duration_string}</p>
                                                  <span className="e-lock-tag"></span>
                                                </div>
                                              </div>
                                            </div>
                                        }
                                      </div>

                                    );
                                  })
                                }
                              </div>
                            );
                          })
                        }

                        {
                          this.state.data.description ?
                            <div className="my-5 e-descp-wrap" >
                              <h4 className="mb-4">What you will learn</h4>
                              <div dangerouslySetInnerHTML={{__html: this.state.data.description}}></div>
                            </div>
                          :
                            null
                        }


                      </div>
                      <div className={tempRightSectionClass}>

                        {
                          this.state.data.is_enrolled ?
                            <div className="e-enroll-widget e-enrolled-widget">
                              <h4>{this.state.data.name}</h4>
                              <p className="e-tag-wrap">
                                {
                                  this.state.data.categories.map( (data, index) => {
                                    return(
                                      <span key={index}>{data.category} <b>•</b></span>
                                    );
                                  })
                                }
                              </p>
                              <div className="e-course-tag-wrap mt-4">
                                {
                                  this.state.data.rating ?
                                    <span className="e-course-tag">
                                      <span className="e-review">&#9733; {this.state.data.rating}</span>
                                    </span>
                                  :
                                    null
                                }

                                <span className="e-course-tag">
                                  {this.state.data.language}
                                </span>
                                {
                                  this.state.bookmarked ?
                                    <span className="e-course-wishlist e-bookmarked" onClick={this.wishlistToggle.bind(this)}></span>
                                  :
                                    <span className="e-course-wishlist" onClick={this.wishlistToggle.bind(this)}></span>
                                }
                              </div>

                              <div className="e-author-detail e-card-transition">
                                <Link to={tempCreatorUrl}>
                                  <div className="media">
                                    <img className="mr-3" src={this.state.data.creator_image} alt="Tradeschool Author Logo"/>
                                    <div className="media-body">
                                      <h5 className="mt-0">{this.state.data.creator}</h5>
                                      <p>Added on: {this.state.data.last_published}</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          :
                            <div>
                              <div className="e-enroll-widget">
                                <div className="e-enroll-banner-wrap position-relative e-card-transition" onClick={this.videoPlayClick.bind(this, this.state.data.preview_id)}>
                                  <span className="e-discount-tag">{this.state.data.discount}% discount</span>
                                  <img className="" src={this.state.data.image} alt="Tradeschool Couse video banner"/>

                                  <img className="e-play-btn" src={PlayBtn} alt="Tradeschool Couse video banner"/>
                                </div>

                                {
                                  this.state.data.free ?
                                    <div className="e-free-course-tag text-center">
                                      <span>Free Course</span>
                                    </div>
                                  :
                                    <React.Fragment>
                                      {
                                        localStorage.getItem("tradeschool_access_token") ?
                                          <div>
                                            {
                                              this.state.data.state_id ?
                                               
                                               
                                                
                                                <div>
                                                {
                                                this.state.data && localStorage.getItem("wallet_balance") > this.state.data.offer_price ?

                                                    <div className="e-p-btn e-enroll-btn mb-4" onClick={this.openModal}>
                                                        {
                                                          this.state.data.offer ?
                                                          <span> <span>Buy Using Wallet <strike>₹ {this.state.data.price}</strike></span> <b>₹ {this.state.data.offer_price}</b></span>
                                                           :
                                                           <span>Buy Using Wallet <b>₹ {this.state.data.price}</b></span>
                                                        }
                                                     
                                                    </div>
                                                    :
                                                    null
                                                  }
                                                     
                                                  {
                                                   
                                                    this.state.data.offer ?
                                                      <div className="e-p-btn e-enroll-btn" data-toggle="modal" data-target="#buyCourserModal">
                                                        <span>Enroll for <strike>₹ {this.state.data.price}</strike></span> <b>₹ {this.state.data.offer_price}</b>
                                                      </div>
                                                    :
                                                      <div className="e-p-btn e-enroll-btn" data-toggle="modal" data-target="#buyCourserModal">
                                                        <span>Enroll for</span> <b>₹ {this.state.data.price}</b>
                                                      </div>
                                                  }
                                                </div>
                                              :
                                                <div>

                                                  {
                                                    this.state.data.offer ?
                                                      <div className="e-p-btn e-enroll-btn" data-toggle="modal" data-target="#editProfileModal">
                                                        <span>Enroll for <strike>₹ {this.state.data.price}</strike></span> <b>₹ {this.state.data.offer_price}</b>
                                                      </div>
                                                    :
                                                      <div className="e-p-btn e-enroll-btn" data-toggle="modal" data-target="#editProfileModal">
                                                        <span>Enroll for</span> <b>₹ {this.state.data.price}</b>
                                                      </div>
                                                  }
                                                </div>
                                            }
                                          </div>
                                        :
                                        <div>
                                          <div className="e-p-btn e-enroll-btn" onClick={this.googleLoginClick.bind(this)}>
                                            Login with Google
                                          </div>

                                          <span className="e-google-login-wrap">
                                            <GoogleLogin clientId={devconfig.googleClientId} buttonText="LOGIN WITH GOOGLE" onSuccess={this.responseGoogle.bind(this)} onFailure={this.responseGoogle.bind(this)} />
                                          </span>
                                        </div>

                                      }
                                    </React.Fragment>
                                }



                                <p>{this.state.data.enrolled_count ? <span>{this.state.data.enrolled_count} people enrolled</span>: null } {this.state.duration ?<span> • {this.state.duration} hours</span> : null}</p>

                              </div>
                            </div>
                        }

                         <div className="">
                         {
                          localStorage.getItem("tradeschool_token") ?
                          <ReferEarn productId={this.state.id} partner_id={this.state.profileData ? this.state.profileData.alice_blue_id : null}/>
                          :
                          null
                         }
                          
                        </div>
                        <div className="e-app-store-banner-wrap position-relative mt-4" >
                          <img className="e-pattern-1" src={PosterBanner1} alt="Tradeschool Couse video preview"/>
                          <img className="e-pattern-2" src={PosterBanner2} alt="Tradeschool Couse video preview"/>

                          <h5>Get our <br/>mobile app</h5>

                          <p>Available on</p>
                          <img className="e-store-link e-card-transition" src={Appstore} alt="Tradeschool Appstore"/>
                          <a href="https://play.google.com/store/apps/details?id=com.tradeschool&hl=en_IN&gl=US" target="_blank">
                            <img className="e-store-link e-card-transition" src={Playstore} alt="Tradeschool Playstore"/>
                          </a>

                          <img className="e-store-poster-banner  e-cta-banner-d-none" src={PosterBanner} alt="Tradeschool Poster" />
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </section>

                {/*
                  this.state.data.description ?
                    <section id="e-brief" className="e-course-brief my-5 ">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-7">
                            <h4 className="mb-4">What you will learn</h4>
                            <p>{this.state.data.description}</p>
                          </div>
                        </div>
                      </div>
                    </section>
                  :
                    null
                */}


                {
                  this.state.reviewData && this.state.reviewData.reviews.length ?
                    <section id="e-review" className="e-course-review">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6 mb-5">
                            <h4 className="mb-4">Reviews ({this.state.reviewData.total_reviews})</h4>
                            <span className="e-review-count">{this.state.reviewData.rating}</span>
                          </div>
                          {
                            this.state.data.is_enrolled ?
                              <div className="col-lg-6 col-md-6 col-sm-6 text-right e-review-btn-wrap mb-5">
                                <span className="e-p-btn e-add-review-btn" data-toggle="modal" data-target="#addReviewModal">Add Review</span>
                              </div>
                            :
                              null
                          }
                        </div>
                        <div className="row">
                          {
                            this.state.reviewData.reviews.map( (data, index) => {
                              return(
                                <div className="col-lg-6" key={index}>
                                  <div className="e-course-review-item">
                                    <div className="media">
                                      {
                                        data.image ?
                                          <img className="mr-3" src={data.image} alt="Tradeschool Couse video review"/>
                                        :
                                          <img className="mr-3" src={AuthLogo} alt="Tradeschool Couse video review"/>
                                      }

                                      <div className="media-body">
                                        <h5 className="mt-0">{data.user_name} • <b>{data.rating}</b></h5>
                                      </div>
                                    </div>
                                    <p className="mt-4">{data.review}</p>
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </section>
                  :
                    <section>
                      {
                        this.state.data.is_enrolled ?
                          <div className="container pb-5 e-no-review-wrap">
                            <div className="row">
                              <div className="col-lg-12 mb-5">
                                <div className="d-flex justify-content-between">
                                  <h4 className="mb-4">Reviews</h4>
                                </div>

                              </div>
                            </div>
                            <div className="row mb-5">
                              <div className="col-lg-12 text-center">
                                <h6 className="mb-4">No reviews yet! Be the first to review this course.</h6>

                                <span className="e-p-btn" data-toggle="modal" data-target="#addReviewModal">Add Review</span>

                              </div>
                            </div>
                          </div>
                        :
                          null
                      }
                    </section>
                }

                <BuyCourserModal data={this.state.data} name={this.state.data.name} pmethod={this.state.useWallet} id={this.state.id} price={this.state.data.offer_price ? this.state.data.offer_price : this.state.data.price}/>
              </div>
            :
              <div className="e-no-data-wrap">
                <NoDataFound head="No Result" data="Unable to find course details."/>
              </div>
          }


        {/* Modals */}
        
        {
          this.state.data && this.state.profileData ?
            <EditProfileModal data={this.state.profileData} success={this.editSuccess.bind(this)} type={2}/>
          :
            null
        }
        <AddReviewModal id={this.state.id} success={this.addReviewSuccess.bind(this)}/>

        </div>
      );

    }

  }

}

export default CourseDetail;
