/*Package importing*/
import React, { Component } from 'react';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/apiCall/post';

class MobSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    }
  }

  searchChange(e) {
    this.setState({ search: e.target.value });
  }

  searchSubmit() {
    var tempURL = "/search?kw=" + this.state.search;
    window.location.href = tempURL;
  }

  triggerSearchKeydown(e) {
    if (e.keyCode == 13 && this.state.search.length) {
      this.searchSubmit();
    }
  }

  render() {
    // var tempClass = "mt-2"
    // if(this.state.comment.length){
    //   tempClass = tempClass + " e-active"
    // }
    return (
      <div className="modal fade e-modal-wrap e-search-modal" id="mobSearchModal" tabIndex="-1" role="dialog" aria-labelledby="mobSearchModalLabel" aria-hidden="true">
        <div className="modal-dialog e-rate-modal" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="position-relative">
                <input className="e-search" type="text"
                  value={this.state.search}
                  onKeyDown={this.triggerSearchKeydown.bind(this)}
                  onChange={this.searchChange.bind(this)}
                  placeholder="What do you want to learn?" />
                {
                  this.state.search.length ?
                    <span className="e-search-submit" onClick={this.searchSubmit.bind(this)}></span>
                    :
                    null
                }
              </div>
            </div>
            {/*<div className="modal-footer">
              {
                this.state.starStatus && this.state.comment.length && this.state.comment.replace(/\s/g, '').length ?
                  <span className="e-p-btn e-s-btn-up-effect" onClick={this.submitClick.bind(this)}>Submit Review</span>
                :
                  <span className="e-p-btn e-inactive e-s-btn-up-effect e-inActive-btn">Submit Review</span>
              }
            </div>*/}
          </div>
        </div>
      </div>

    )
  }
}

export default MobSearch;
