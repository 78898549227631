/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Assets*/
import Reviewer from '../../assets/images/webinar-detail/profile.png';
import Rating from '../../assets/images/webinar-detail/rating.png';

class ReviewCard extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="card e-review-card e-card-transition card3 wow fadeInUp border-0">
        <div className='d-flex'>
          <img src={Reviewer} alt="" className='e-reviewer' />
          <div className='pl-3 pt-3'>
            <h6 className='mb-0'>Becca carlos</h6>
            <p>
              <img src={Rating} alt="" className='mr-4'/>
              Apr 17, 20
            </p>
            
          </div>
        </div>
        <div className='row'>
          <div className='offset-lg-1 col-lg-11'>
          <p className='e-review-content pl-2'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl quisque quam massa aliquam arcu sed. Eleifend odio nunc gravida quis ornare odio. Ac purus, odio parturient consequat interdum et auctor. Feugiat quis id pharetra urna morbi ac vitae malesuada. Vitae malesuada fermentum lectus nam ullamcorper. 
            </p>
          </div>
        </div>

      </div>

    );
  }
}

export default ReviewCard;
