/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import ThreeCardSlider from '../../components/slider/three-card-slider';
import ProfileWebinarCard from '../../components/cards/profileSavedWebinar.js';
import ApiLoader from '../../components/common/apiLoader';
/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';


/*Assets*/

class WalletHistory extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,

    }
  }

  componentDidMount() {
    new WOW.WOW().init();
    this.getHistory();
  }

 getHistory=()=>{
    this.setState({loading: true});
    var url = devconfig.user+"/auth/wallet-history";
    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ history: response.data, loading: false});
      }
      else{
        this.setState({ history: null, loading: false});
      }
    }.bind(this))
  }

  render() {
    return (
      <React.Fragment>
      <React.Fragment>
        {
          this.state.loading ?
          <div className="col-lg-12 text-center py-5">
            <ApiLoader/>
          </div>
          :
          null
        }
      </React.Fragment>

      {
        this.state.history ?
     
      <React.Fragment>
        <section className="e-banner-section e-wallet-history-banner position-relative">
          <div className="container position-relative">
            <h6>Wallet Balance</h6>
            <h3 className=""><span className="e-rupees">₹</span> {this.state.history.wallet.balance}</h3>
          </div>
        </section>
        <section className="e-wallet-transactions position-relative">
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-12">
                <h5>All Transactions</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="e-transaction-wrapper pl-3">
                {
                  this.state.history ? 
                  this.state.history.history.map((item, key)=>{
                    return(
                      <div className="row e-transaction-border pt-4" key={key}>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                      <p className="e-transaction-date">{item.date}</p>
                    </div>
                     <div className="col-lg-4 col-md-5 col-sm-5 col-12">
                      <p className="e-transaction-desc">{item.info}</p>
                    </div>
                    <div className="col-lg-4 col-md-3 col-sm-3 col-12">
                      {/*<p className="e-credit-amount">- <span className="e-rupees">₹</span> 100</p>*/}
                      {
                        item.transaction_type == 1 ?
                        <p className={" e-receive"}>{"+₹ " +item.amount}</p>
                        :
                        <p className={"e-credit-amount"}>{"-₹ " +item.amount}</p>
                      }
                      
                    </div>
                  </div>
                    );
                  })
                  :
                  null
                }
                  



                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
        :
        null
       }

      </React.Fragment>
    );
  }
}

export default WalletHistory;
