/*
 *   File : calender.js
 *   Author URI : https://evoqins.com
 *   Description : date picker component
 *   Integrations :  DatePicker
 *   Version : v1.1
*/

import React, { Component } from 'react';
import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import calenderImg from '../../assets/images/calender.png';

const CalendarIcon = () => {
    return (
        <>
            <img className='e-calender-img'
                src={calenderImg}
                alt='icon' />
        </>
    )
}

const today = new Date();

class CalenderInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    
    // _handleDateChange = (selectedDate) => {
    //     this.setState({ 
    //         selectedDate 
    //     });
    // };

    

    render() {
        // console.log('this.props.value:::', this.props.value)
        return (
            < div className='position-relative'>
                <DatePicker dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    format="dd-MM-yyyy"
                    minDate={today}

                    calendarType={'gregory'}
                    clearIcon={this.props.clearIcon}
                    autoFocus={false}
                    calendarIcon={<CalendarIcon />}
                    value={this.props.value}
                    style={{ top: -60 }}
                    onChange={this.props.onChange} 

                    />
            </div>

        );
    }

}
export default CalenderInput;