/*
 *   File : youtube.js
 *   Author URI : https://evoqins.com
 *   Description : youtube video modal
 *   Integrations :  NA
 *   Version : v1.1
*/

import React, { Component } from "react";

//Assets
import Close from "../../assets/images/close.png";
import { el } from "date-fns/locale";

class YoutubeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    _handleCloseModal = () => {
        const youtubeIframe = document.getElementById("youtubeIframe");
        if (youtubeIframe) {
            youtubeIframe.src = this.props.youtubeData.intro_video;
        }
    };

    render() {
        return (
            <div className="modal fade e-form-modal "
                id="youtubeModal"
                tabIndex="-1" role="dialog"
                aria-labelledby="youtubeModalLabel"
                aria-hidden="true"  >
                <div className="modal-dialog e-modal-dialog" role="document">
                    <div className="modal-content p-0">

                        <div className="modal-body position-relative">
                            <button type="button"
                                className="e-youtube-modal-close rounded-circle"
                                data-dismiss="modal"
                                aria-label="Close" onClick={this._handleCloseModal}>
                                <span aria-hidden="true">
                                    <img draggable="false" className="" src={Close} alt="close" />
                                </span>
                            </button>
                            {
                                this.props.youtubeData &&
                            <iframe
                                title="YouTube Video"
                                width="100%"
                                id="youtubeIframe"
                                // src='https://www.youtube.com/embed/yXZI96SVjy8'
                                src={this.props.youtubeData.intro_video}
                                target="_blank"
                                className=' h-360px'
                                allowFullScreen />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default YoutubeModal;