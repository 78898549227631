/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import CardPlaceholder from '../../components/placeholder/card';
import CourseCard from '../../components/cards/card';
import NoDataFound from '../../components/common/nothing';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';

/*Assets*/
import cover from '../../assets/images/home/cover.png';
import BannerPattern1 from '../../assets/images/all-courses/banner-pattern-1.svg';
import BannerPattern2 from '../../assets/images/all-courses/banner-pattern-2.svg';

class Tutor extends Component {
  constructor(props){
    super(props);
    this.state = {
      id: 0,
      tabActive: 1,
      data: null,
      dataLoading: false,
      placeholder4: [1, 2, 3, 4],
      profilePlaceholder: "",
      pageNum: 1,
      paginationLoading: false,
    }
  }

  componentDidMount() {
    new WOW.WOW().init();
    $('html,body').animate({ scrollTop: 0 }, 'slow');
    var query = this.getUrlVars();

    this.getData(query.id, this.state.pageNum);
    this.setState({ id: query.id });
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  tabItemClick(tempItem){
    this.setState({tabActive: tempItem});
  }

  getData(tempID, tempPagenum){
    this.setState({dataLoading: true});

    var url = devconfig.user+"/course/creator-courses";
    var data = JSON.stringify({
      "creator_id" : tempID,
      "page_num" : tempPagenum
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        var tempData = response.data.name;
        var tempNameSplit = tempData.split(" ");
        var tempPlaceholder = tempNameSplit[0].charAt(0)+tempNameSplit[tempNameSplit.length-1].charAt(0);

        if(this.state.data){
          var tempArray = this.state.data;
          tempArray.push(response.data);
          this.setState({ data: tempArray, dataLoading: false, profilePlaceholder: tempPlaceholder, paginationLoading: false});
        }
        else{
          this.setState({ data: response.data, dataLoading: false, profilePlaceholder: tempPlaceholder, paginationLoading: false});
        }

        if(response.data.length >= 39){
          this.setState({ pagination: true});
        }
        else{
          this.setState({ pagination: false, paginationLoading: false});
        }

        new WOW.WOW().init();
      }
      else{
        this.setState({ data: null, dataLoading: false});
      }
    }.bind(this))
  }
  
  loadMore(){
    this.getData(this.state.id, this.state.pageNum + 1);
    this.setState({ pageNum: this.state.pageNum + 1, paginationLoading: true});
  }

  render() {
    if(this.state.dataLoading){
      return(
        <div className="e-home e-tutor-wrap">
          <section className="e-banner-section position-relative">
            <div className="container position-relative">
              <div className="row">
                <div className="col-lg-12">

                </div>
              </div>
            </div>
          </section>
          <section className="e-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-6 mb-4">
                  <CardPlaceholder/>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4">
                  <CardPlaceholder/>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4">
                  <CardPlaceholder/>
                </div>
                <div className="col-lg-3 col-md-6 col-6 mb-4">
                  <CardPlaceholder/>
                </div>
              </div>
            </div>
          </section>

        </div>
      );
    }
    else{
      return (
        <div>
          {
            this.state.data ?
              <div className="e-home e-tutor-wrap">
                <section className="e-banner-section position-relative e-tutor-banner">
                  <div className="container position-relative">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="media e-profile-details-wrap e-tutor-detail-wrap">
                          {
                            this.state.data.image ?
                              <img className="e-profile-pic-placeholder mr-4" src={this.state.data.image} alt="Courses Tutor"/>
                            :
                              <span className="e-profile-pic-placeholder mr-4">{this.state.profilePlaceholder}</span>
                          }
                          
                          <div className="media-body">
                            <p className="mb-1">Tutor</p>
                            <h5 className="mt-0">{this.state.data.name}</h5>
                            {
                              this.state.data.designation && this.state.data.designation.length ?
                                <p className="mt-0 e-designation">{this.state.data.designation}</p>
                              :
                                null
                            }

                            {/*<span className="e-course-wishlist"></span>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="e-profile-tab-wrap">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <span className="e-profile-tab-item e-profile-tab-courses e-active">All Courses</span>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Tab content item 1 */}
                {
                  this.state.tabActive === 1 ?
                    <div>
                      <section className="e-section">
                        <div className="container">

                          {
                            this.state.topSectionDataLoading ?
                              <div className="row">
                                { 
                                  this.state.placeholder4.map( (data, index) => {
                                    return(
                                      <div className="col-lg-3 col-md-6 mb-4" key={index}>
                                        <CardPlaceholder/>
                                      </div>
                                    );
                                  })
                                }
                              </div>
                            :
                              <div>
                                {
                                  this.state.data ?
                                    <div className="row">
                                      { 
                                        this.state.data.courses.map( (data, index) => {
                                          var tempDelay = "."+index+2+"s";
                                          var tempURL = "/course-details?id="+data.id;
                                          return(
                                            <div className="col-lg-3 col-md-6 mb-4" key={index}>
                                              <Link to={tempURL}>
                                                <CourseCard data={data} delay={tempDelay}/>
                                              </Link>
                                            </div>
                                          );
                                        })
                                      }
                                    </div>
                                  :
                                    <div>
                                      <NoDataFound head="No Result" data="No courses found."/>
                                    </div>
                                }
                              </div>
                          }

                          {
                            this.state.pagination ?
                              <div className="row mt-3">
                                <div className="col-lg-12 text-center">
                                  {
                                    this.state.paginationLoading ?
                                      <span className="e-pagination-loader"></span>
                                    :
                                      <span className="e-s-btn" onClick={this.loadMore.bind(this)}>Load more</span>
                                  }
                                </div>
                              </div>
                            :
                              null
                          }
                        </div>
                      </section>
                    </div>
                  :
                    null
                }

                
              </div>
            :
              <div className="e-no-data-wrap">
                <NoDataFound head="No Result" data="No creator found with this ID."/>
              </div>
          }
        </div>
        
      );
    }
    
  }
}

export default Tutor;
