/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../formElements/input';
import SelectBox from '../formElements/select-box';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';

/*Assets*/
import Success from '../../assets/images/modals/success.svg';

class EditProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      apiCallLoading: false,
      mainCat: [],
      mainCatOption: null,
      gstno: ""
    }
  }

  componentDidMount() {
    this.statesData();

    if (this.props.data) {
      this.setState({ gstno: this.props.data.gst_no, mainCatOption: { value: this.props.data.state_id, label: this.props.data.state } });
    }
  }

  update() {
    var url = devconfig.user + "/auth/update-profile";
    var data = JSON.stringify({
      "image": this.props.data.image,
      "name": this.props.data.name,
      "state_id": this.state.mainCatOption.value,
      "gst_no": this.state.gstno
    })
    APIPost(url, data).then(function (response) {
      if (response.status) {
        $("#editProfileModal").modal("hide");
        window.location.reload();
        this.props.success();
      }
      else {
        $("#editProfileModal").modal("hide");
      }
    }.bind(this))

  }

  statesData() {
    var url = devconfig.user + "/states/get-all";
    APIGet(url).then(function (response) {
      if (response.status) {
        var tempArray = [];
        response.data.map(function (key, index) {
          var temp = { value: key.id, label: key.name };
          tempArray.push(temp);
        }.bind(this));
        this.setState({ mainCat: tempArray });
      }
    }.bind(this))
  }

  mainCatChange = (mainCatOption) => {
    this.setState({ mainCatOption });
  }

  render() {

    var mainCatOption = { value: this.props.data.state_id, label: this.props.data.state }

    return (
      <div className="modal fade e-form-modal e-open-account-modal" id="editProfileModal" tabIndex="-1" role="dialog" aria-labelledby="editProfileModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {
              this.state.success ?
                <div className="e-success">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <img src={Success} alt="success" />
                    <h6 className="mt-3">Success!</h6>
                    <p>Thank you for contacting us, our business executive will contact you soon.</p>
                  </div>
                </div>
                :
                <div>
                  <div className="modal-header">
                    {
                      this.props.type == 2 ?
                        <h5 className="modal-title">Add Profile Details</h5>
                        :
                        <h5 className="modal-title">Edit Profile Details</h5>
                    }

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {/* <Input label="GST No" id="u-gstno" type="text" value={this.state.gstno} valueChange={(tempVal) => this.setState({ gstno: tempVal })} /> */}
                    <SelectBox className="e-edit-select"
                      search={true}
                      defaultValue={mainCatOption}
                      options={this.state.mainCat}
                      selectchange={this.mainCatChange}
                      placeholder="State" />
                    {
                      this.state.errorMessage && this.state.errorMessage.length ?
                        <div className="e-error">{this.state.errorMessage}</div>
                        :
                        null
                    }

                  </div>
                  {
                    this.props.type == 2 ?
                      <div className="modal-footer pt-0">
                        {
                          this.state.mainCatOption && this.state.mainCatOption.value ?
                            <span className="e-p-btn e-login-with-gmail" onClick={this.update.bind(this)}>Submit</span>
                            :
                            <span className="e-p-btn e-login-with-gmail e-inactive">Submit</span>
                        }
                      </div>
                      :
                      <div className="modal-footer pt-0">
                        {
                          this.state.mainCatOption && this.state.mainCatOption.value ?
                            <span className="e-p-btn e-login-with-gmail" onClick={this.update.bind(this)}>Update</span>
                            :
                            <span className="e-p-btn e-login-with-gmail e-inactive">Update</span>
                        }
                      </div>
                  }

                </div>
            }
          </div>
        </div>
      </div>

    );
  }
}

export default EditProfileModal;
