/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Components*/
import CourseCard from '../cards/card';

class FourCardSlider extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }



  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
         breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            dots: false,
          }
        },
        {
         breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            dots: false,
          }
        }
      ]
    };
    if(this.props.data){
      return (
        <div>
          <Slider {...settings}>
            {
              this.props.data.map( (data, index) => {
                var tempDelay = "."+index+2+"s";
                var tempURL = "/course-details?id="+data.id;
                return(
                  <div className="e-slider-item" key={index}>
                    <Link to={tempURL}>
                      <CourseCard data={data} delay={tempDelay}/>
                    </Link>
                  </div>
                );
              })
            }
          </Slider>
        </div>
      );
    }
    else{
      return(
        null
      );
    }

  }
}

export default FourCardSlider;
