/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Import custom components and assets*/
import devconfig from '../../config/config.js';
// import ApiLoader from '../../components/loader/apiLoader';
import APIPost from '../../services/apiCall/post';

class Callback extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  componentDidMount() {
    const query = this.getUrlVars();
    if(query.authCode && query.userId && query.appcode){
      this.getABAccessToken(query.authCode, query.userId, query.appcode);
    }
  }

  getABAccessToken(authCode, userId, appCode){

    const url = `${devconfig.user}/auth/generate-ab-token`;
    // console.log(code)
    const data = JSON.stringify({
      "authCode" : authCode,
      "userId" : userId,
      "appCode": appCode
    });
    APIPost(url, data).then((response) => {
      if(response.status){
        const data = {
          email: response.data.email,
          name: response.data.name,
          user_id: response.data.user_id,
          password: response.data.password,
          image: response.data.image ? response.data.image : undefined
        };
      this.getFirebaseToken(data);

      }
      else{
        this.setState({errorMessage: response.message});
      }
    })
  }

  async getFirebaseToken(data){
    var url = devconfig.user + "/auth/alice-blue-login";
    var data = JSON.stringify(data);

    APIPost(url, data).then(function(response){
      if(response.status){
        if (response.status) {
          localStorage.setItem("tradeschool_access_token", response.access_token);
          localStorage.setItem("firebase_token", response.firebase_token);
          localStorage.setItem("uid", response.data.firebase_uid);
          localStorage.setItem("userData", JSON.stringify(response.data));
          localStorage.setItem("tradeschool_token", true);

          if(sessionStorage.getItem("ts_login_redirect")){
            window.location.href = sessionStorage.getItem("ts_login_redirect");
          }
          else{
            window.location.href = '/';
          }
          
        }
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }




  render() {
    return (
      <div className="e-body">
        <section className="e-callback-wrap">
          <div className="text-center p-5">
            {/*<ApiLoader/>*/}
          </div>
        </section>
      </div>
    );
  }
}

export default Callback;
