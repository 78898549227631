/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../formElements/input';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';

/*Assets*/
import Success from '../../assets/images/modals/success.svg';

class JoinTutorModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            apiCallLoading: false
        }
    }

    async send() {
        var validationSuccess = true;

        await PhoneValidation(this.state.phone).then(function (response) {
            if (!response) {
                this.setState({ errorMessage: "Invalid phone number, please check and retry.", apiLoading: false });
                validationSuccess = false;
            }
        }.bind(this))

        await EmailValidation(this.state.email).then(function (response) {
            if (!response) {
                this.setState({ errorMessage: "Invalid email address, please check and retry.", apiLoading: false });
                validationSuccess = false;
            }
        }.bind(this))

        if (validationSuccess) {
            var url = devconfig.creator + "/auth/join-as-tutor";
            var data = JSON.stringify({
                "name": this.state.name,
                "email": this.state.email,
                "mobile": this.state.phone
            })
            APIPost(url, data).then(function (response) {
                if (response.status) {
                    this.setState({ success: true });

                    setTimeout(function () {
                        $('#joinTutorModal').modal('hide');
                        this.setState({ success: false });
                    }.bind(this), 2000);
                }
                else {
                    this.setState({ errorMessage: response.message });
                }

                this.setState({
                    name: "",
                    email: "",
                    mobile: "",
                    apiLoading: false
                });

            }.bind(this))
        }

    }



    render() {
        return (
            <div className="modal fade e-form-modal" id="joinTutorModal" tabIndex="-1" role="dialog" aria-labelledby="joinTutorModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {
                            this.state.success ?
                                <div className="e-success">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={Success} alt="success" />
                                        <h6 className="mt-3">Success!</h6>
                                        <p>Thank you for contacting us, our business executive will contact you soon.</p>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Join As Tutor</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Input
                                            label="Your Name"
                                            id="u-name"
                                            type="text"
                                            tabIndex="1"
                                            value={this.state.name}
                                            valueChange={(tempVal) => this.setState({
                                                name: tempVal,
                                                errorMessage: ""
                                            })} />
                                        <Input
                                            label="Phone Number"
                                            id="u-phone"
                                            type="text"
                                            tabIndex="2"
                                            value={this.state.phone}
                                            valueChange={(tempVal) => this.setState({
                                                phone: tempVal,
                                                errorMessage: ""
                                            })} />
                                        <Input
                                            label="Email Address"
                                            id="u-email"
                                            type="text"
                                            tabIndex="3"
                                            value={this.state.email}
                                            valueChange={(tempVal) => this.setState({
                                                email: tempVal,
                                                errorMessage: ""
                                            })} />
                                        {
                                            this.state.errorMessage && this.state.errorMessage.length ?
                                                <div className="e-error">{this.state.errorMessage}</div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="modal-footer pt-0">
                                        {
                                            this.state.name && this.state.phone && this.state.email ?
                                                <span className="e-p-btn e-login-with-gmail" onClick={this.send.bind(this)}>Send Enquiry</span>
                                                :
                                                <span className="e-p-btn e-login-with-gmail e-inactive">Send Enquiry</span>
                                        }
                                    </div>
                                </div>
                        }


                    </div>

                </div>
            </div>

        );
    }
}

export default JoinTutorModal;
