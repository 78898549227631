/*Package importing*/
import React, { Component } from 'react';

/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/apiCall/post';

class ProductRateModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      starStatus: 0,
      comment: ""
    }
  }

  starClick(itemId){
    this.setState({ starStatus: 6 - itemId });
  }

  commentChange(e){
    this.setState({comment: e.target.value});
  }

  submitClick(){
    var url = devconfig.user+"/course/add-review";
    var data = JSON.stringify({
      "course_id" : this.props.id,
      "review" : this.state.comment,
      "rating" : this.state.starStatus
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.props.success(this.props.id);
      }
      else{
        this.setState({errorMessage: response.message});
      }
    }.bind(this))
  }

  render() {
    var tempClass = "mt-2"
    if(this.state.comment.length){
      tempClass = tempClass + " e-active"
    }
    return(
      <div className="modal fade e-modal-wrap" id="addReviewModal" tabIndex="-1" role="dialog" aria-labelledby="addReviewModalLabel" aria-hidden="true">
        <div className="modal-dialog e-rate-modal" role="document">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <h5 className="modal-title" id="exampleModalLabel">Rate Product</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-4 e-rate-star-wrap">
                {
                  this.state.starStatus >= 5 ?
                    <span className="e-rate-star e-active" onClick={this.starClick.bind(this, 1)}></span>
                  :
                    <span className="e-rate-star" onClick={this.starClick.bind(this, 1)}></span>
                }

                {
                  this.state.starStatus >= 4 ? 
                    <span className="e-rate-star e-active" onClick={this.starClick.bind(this, 2)}></span>
                  :
                    <span className="e-rate-star" onClick={this.starClick.bind(this, 2)}></span>
                }
                
                {
                  this.state.starStatus >= 3 ?
                    <span className="e-rate-star e-active" onClick={this.starClick.bind(this, 3)}></span>
                  :
                    <span className="e-rate-star" onClick={this.starClick.bind(this, 3)}></span> 
                }

                {
                  this.state.starStatus >= 2 ?
                    <span className="e-rate-star e-active" onClick={this.starClick.bind(this, 4)}></span>
                  :
                    <span className="e-rate-star" onClick={this.starClick.bind(this, 4)}></span>
                }
                
                {
                  this.state.starStatus >= 1 ?
                    <span className="e-rate-star e-active" onClick={this.starClick.bind(this, 5)}></span>
                  :
                    <span className="e-rate-star" onClick={this.starClick.bind(this, 5)}></span>
                }
                
              </div>
              <div className="e-input-wrap mb-0">
                <textarea className={tempClass} htmlFor="e-review-input" rows="7" cols="50" onChange={this.commentChange.bind(this)} value={this.state.comment}></textarea>
                <label className="modal-title" id="e-review-input">Feedback</label>
              </div>
            </div>
            <div className="modal-footer">
              {
                this.state.starStatus && this.state.comment.length && this.state.comment.replace(/\s/g, '').length ?
                  <span className="e-p-btn e-s-btn-up-effect" onClick={this.submitClick.bind(this)}>Submit Review</span>
                :
                  <span className="e-p-btn e-inactive e-s-btn-up-effect e-inActive-btn">Submit Review</span>
              }
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default ProductRateModal;
