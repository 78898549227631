/*Package importing*/
import React, { Component } from 'react';
/*Components*/


/*Assets*/
import card from '../../assets/images/placeholder/order.png';

class OrderPlaceholder extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  

  render() {
    return (
      <div className="e-card-placeholder e-order-placeholder position-relative wow fadeIn">
        <img className="" src={card} alt="Course card"/>
        <span></span>
      </div>
      
    );
  }
}

export default OrderPlaceholder;
