/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import CardPlaceholder from '../../components/placeholder/card';
import CourseCard from '../../components/cards/card';
import OrderHistory from './orderHistory';
import Notification from './notification';
import YourWebinars from './webinar';
import FourCardSlider from '../../components/slider/four-card-slider';
import NoDataFound from '../../components/common/nothing';
import EditProfileModal from '../../components/modals/edit-profile';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import ApiLoader from '../../components/common/overlayLoader.js';

/*Assets*/
import Wallet from '../../assets/images/profile/wallets.svg';
import Edit from '../../assets/images/icons/edit-black.svg';
import cover from '../../assets/images/home/cover.png';
import BannerPattern1 from '../../assets/images/all-courses/banner-pattern-1.svg';
import BannerPattern2 from '../../assets/images/all-courses/banner-pattern-2.svg';
import CourseBanner from '../../assets/images/profile/course.svg';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabActive: 1,
            data: null,
            profileData: null,
            profilePlaceholder: null,
            savedData: null,
            savedDataLoading: false,
            enrolledData: null,
            enrolledDataLoading: false,
            placeholder4: [1, 2, 3, 4],
            isPartner: false,
            loading: true
        }
    }

    componentDidMount() {
        new WOW.WOW().init();
        $('html,body').animate({ scrollTop: 0 }, 'slow');

        var tempData = JSON.parse(localStorage.getItem('userData'));
        var tempNameSplit = tempData.name.split(" ");
        var tempPlaceholder = tempNameSplit[0].charAt(0) + tempNameSplit[tempNameSplit.length - 1].charAt(0);
        this.setState({ data: tempData, profilePlaceholder: tempPlaceholder });

        this.profileData();
        this.getSavedData();
        // this.getOpenData();
        // this.getEnrolledData();
        this.getBalance();

        var query = this.getUrlVars();
        if (query.favwebinar) {
            this.setState({ tabActive: 2 });
        }
        if (query.order) {
            this.setState({ tabActive: 3 });
        }
        if (query.notification) {
            this.setState({ tabActive: 4 });
        }

        console.log('pro::', this.state.profileData)
    }
    getBalance() {

        var url = devconfig.user + "/auth/wallet-balance";
        APIGet(url).then(function (response) {
            if (response.status) {
                this.setState({ balance: response.data.balance });
            }
            else {
                this.setState({ balance: response.data.balance });
            }
        }.bind(this))
    }

    getUrlVars() {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    tabItemClick(tempItem) {
        this.setState({ tabActive: tempItem });
    }

    profileData() {
        this.setState({ savedDataLoading: true });

        var base_url = devconfig.user;
        let partner_token = localStorage.getItem("partner_token") ;
        // # checking if partner 
        if(partner_token==="true"){
            base_url = devconfig.partnerV1;
            this.setState({isPartner: true});
        }
        var url = base_url + "/auth/profile";
        APIGet(url).then(function (response) {
            if (response.status) {
                this.setState({ profileData: response.data, loading: false });
            }
            else {
                this.setState({ profileData: null, loading: false });
            }
        }.bind(this))
    }

    getSavedData() {
        this.setState({ savedDataLoading: true });

        var url = devconfig.user + "/course/saved-courses";
        APIGet(url).then(function (response) {
            if (response.status) {
                this.setState({ savedData: response.data, savedDataLoading: false });
                // new WOW.WOW().init();
            }
            else {
                this.setState({ savedData: null, savedDataLoading: false });
            }
        }.bind(this))
    }

    getOpenData() {
        this.setState({ openDataLoading: true });

        var url = devconfig.user + "/course/opened-courses";
        APIGet(url).then(function (response) {
            if (response.status) {

                this.setState({ openData: response.data, openDataLoading: false });
                new WOW.WOW().init();
            }
            else {
                this.setState({ openData: null, openDataLoading: false });
            }
        }.bind(this))
    }

    getEnrolledData() {
        this.setState({ enrolledDataLoading: true });

        var url = devconfig.user + "/course/enrolled-courses";
        APIGet(url).then(function (response) {
            if (response.status) {
                this.setState({ enrolledData: response.data, enrolledDataLoading: false });
                new WOW.WOW().init();
            }
            else {
                this.setState({ enrolledData: null, enrolledDataLoading: false });
            }
        }.bind(this))
    }

    editSuccess() {
        this.setState({ profileData: null });
        this.profileData();
        this.getSavedData();
        this.getOpenData();
    }

    render() {
        return (
            <div className="e-home e-profile-wrap">
                <section className="e-banner-section position-relative e-profile-banner">
                    <div className="container position-relative">
                        <img className="e-banner-pattern-1 wow fadeIn" data-wow-duration="4s" src={BannerPattern1} alt="Tradeschool courses listing" />
                        {
                          this.state.loading ?
                            <ApiLoader/>
                          :
                            this.state.profileData ?
                              <div className="row">
                                  <div className="col-lg-7">
                                    <div className="e-profile-wraper">
                                      <div className='row'>
                                          <div className='col-10'>
                                              <div className='d-flex mt-3'>
                                                  <span className='e-profile-icon-span'>{this.state.profilePlaceholder}</span>
                                                  <h5 className="e-profile-name mt-0 py-3 pl-2">{this.state.data.name}</h5>
                                              </div>
                                          </div>
                                          <div className='col-2 text-right'>
                                              <span className="e-profile-edit" data-toggle="modal" data-target="#editProfileModal">
                                                  <img draggable="false" src={Edit} alt="" className='pr-2' />Edit</span>
                                          </div>
                                          <div className='col-lg-12 mt-2 pt-1'>
                                              <div className='e-profile-detail-wrap'>
                                                  <div className='row'>
                                                      <div className='col-lg-7 col-md-7'>
                                                          <p>Email ID: {this.state.data.email}</p>
                                                      </div>
                                                      {/* <div className='col-lg-5 col-md-5'>
                                                          <p>GST no: {this.state.profileData.gst_no ? this.state.profileData.gst_no : "--"}</p>
                                                      </div> */}
                                                      <div className='col-lg-5 col-md-5'>
                                                          <p>Alice Blue ID: {this.state.profileData.alice_blue_id}</p>
                                                      </div>
                                                  </div>
                                                  {!this.state.isPartner && <div className='row'>
                                                      <div className='col-lg-6 col-md-7'>
                                                          <p className='mb-0'>Phone no: {this.state.profileData.number}</p>
                                                      </div>
                                                      <div className='col-lg-6 col-md-5 pt-lg-0 pt-md-0 pt-3'>
                                                          <p className='mb-0'>State: {this.state.profileData.state}</p>
                                                      </div>
                                                  </div>}
                                                  {
                                                    this.state.isPartner && <div className='row'>
                                                      <div className='col-7'>
                                                          <p className='mb-0'>Reffered enrollments: {this.state.profileData.referred_products}</p>
                                                      </div>
                                                      {/* <div className='col-lg-4 col-md-5 pt-lg-0 pt-md-0 pt-3'>
                                                          <p className='mb-0'>Commision: {this.state.profileData.commission}</p>
                                                      </div> */}
                                                      <div className='col-lg-5 col-md-5'>
                                                          <p className='mb-0'>Leads generated: {this.state.profileData.clients_joined}</p>
                                                      </div>
                                                    </div>
                                                  }
                                              </div>
                                          </div>
                                      </div>
                                    </div>

                                  </div>
                                  {/* <div className='col-lg-5 pt-lg-0 pt-4'>
                  <Link to="/wallet-history">
                    <div className="e-profile-wraper">
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='d-flex mt-3'>
                            <span className='e-profile-icon-span'>
                              <img src={Wallet} alt="" />
                            </span>
                            <h5 className="mt-0 py-3 pl-2">Wallet</h5>
                          </div>
                        </div>
                        <div className='col-lg-12 mt-2 pt-1'>
                          <div className='e-profile-detail-wrap' style={{ width: "100%" }}>
                            <div className='row'>
                              <div className='col-lg-12'>
                                <p className='e-balnce mb-0'>Balance Amt: <span className='pl-3'>₹ {this.state.balance ? this.state.balance : "0"}</span></p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}
                              </div>
                            :
                            null
                        }

                    </div>
                </section>

                <section className="e-profile-tab-wrap">
                    <div className="container">
                        <div className="row">
                            {/* {
                this.state.tabActive === 1 ?
                  <div className="col-lg-12 e-tab-items">
                    <span className="e-profile-tab-item e-profile-tab-courses e-active" onClick={this.tabItemClick.bind(this, 1)}>Your Courses</span>
                    <span className="e-profile-tab-item e-profile-tab-webinar" onClick={this.tabItemClick.bind(this, 2)}>Your Webinars</span>
                    <span className="e-profile-tab-item e-profile-tab-history" onClick={this.tabItemClick.bind(this, 3)}>Order History</span>
                    <span className="e-profile-tab-item e-profile-tab-notify" onClick={this.tabItemClick.bind(this, 4)}>Notifications</span>
                  </div>
                  :
                  null
              } */}

                            {
                                this.state.tabActive === 2 ?
                                    <div className="col-lg-12 e-tab-items">
                                        {/* <span className="e-profile-tab-item e-profile-tab-courses" onClick={this.tabItemClick.bind(this, 1)}>Your Courses</span> */}
                                        <span className="e-profile-tab-item e-profile-tab-webinar e-active" onClick={this.tabItemClick.bind(this, 2)}>Saved Webinars</span>
                                        {/* <span className="e-profile-tab-item e-profile-tab-history" onClick={this.tabItemClick.bind(this, 3)}>Order History</span> */}
                                        <span className="e-profile-tab-item e-profile-tab-notify" onClick={this.tabItemClick.bind(this, 4)}>Notifications</span>
                                    </div>
                                    :
                                    null
                            }

                            {/* {
                this.state.tabActive === 3 ?
                  <div className="col-lg-12 e-tab-items">
                    <span className="e-profile-tab-item e-profile-tab-courses" onClick={this.tabItemClick.bind(this, 1)}>Your Courses</span>
                    <span className="e-profile-tab-item e-profile-tab-webinar" onClick={this.tabItemClick.bind(this, 2)}>Your Webinars</span>
                    <span className="e-profile-tab-item e-profile-tab-history e-active" onClick={this.tabItemClick.bind(this, 3)}>Order History</span>
                    <span className="e-profile-tab-item e-profile-tab-notify" onClick={this.tabItemClick.bind(this, 4)}>Notifications</span>
                  </div>
                  :
                  null
              } */}
                            {
                                this.state.tabActive === 4 ?
                                    <div className="col-lg-12 e-tab-items">
                                        {/* <span className="e-profile-tab-item e-profile-tab-courses" onClick={this.tabItemClick.bind(this, 1)}>Your Courses</span> */}
                                        <span className="e-profile-tab-item e-profile-tab-webinar" onClick={this.tabItemClick.bind(this, 2)}>Saved Webinars</span>
                                        {/* <span className="e-profile-tab-item e-profile-tab-history" onClick={this.tabItemClick.bind(this, 3)}>Order History</span> */}
                                        <span className="e-profile-tab-item e-profile-tab-notify e-active" onClick={this.tabItemClick.bind(this, 4)}>Notifications</span>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </div>
                </section>

                {/* Tab content item 1 */}
                {/* {
          this.state.tabActive === 1 ?
            <div>
              {
                this.state.openDataLoading ?
                  <section className="e-profile-open-course-wrap">
                    <div className="container">
                      <div className="row mb-4">
                        {
                          this.state.placeholder4.map((data, index) => {
                            return (
                              <div className="col-lg-3 col-md-6" key={index}>
                                <CardPlaceholder />
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </section>
                  :
                  <div>
                    {
                      this.state.openData ?
                        <section className="e-profile-open-course-wrap">
                          <div className="container">
                            <div className="row mb-2">
                              <div className="col-lg-6">
                                <span className="e-head-secondary">Continue</span>
                                <h4>Your Open Courses</h4>
                              </div>
                            </div>
                            <div>
                              <FourCardSlider data={this.state.openData} />
                            </div>
                          </div>
                        </section>
                        :
                        null
                    }
                  </div>
              }

              {
                this.state.savedDataLoading ?
                  <section className="e-section">
                    <div className="container">
                      <div className="row mb-4">
                        {
                          this.state.placeholder4.map((data, index) => {
                            return (
                              <div className="col-lg-3 col-md-6" key={index}>
                                <CardPlaceholder />
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </section>
                  :
                  <div>
                    {
                      this.state.savedData ?
                        <section className="e-section">
                          <div className="container">
                            <div className="row mb-2">
                              <div className="col-lg-6 ">
                                <h4>Saved Courses</h4>
                              </div>
                            </div>
                            <div>
                              <FourCardSlider data={this.state.savedData} />
                            </div>
                          </div>
                        </section>
                        :
                        null
                    }
                  </div>
              }

              {
                this.state.enrolledDataLoading ?
                  <section className="e-section">
                    <div className="container">
                      <div className="row mb-4">
                        {
                          this.state.placeholder4.map((data, index) => {
                            return (
                              <div className="col-lg-3 col-md-6" key={index}>
                                <CardPlaceholder />
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </section>
                  :
                  <div>
                    {
                      this.state.enrolledData ?
                        <section className="e-section e-grey-bg">
                          <div className="container">
                            <div className="row mb-4">
                              <div className="col-lg-6 col-md-8 col-8">
                                <h4>Enrolled Courses</h4>
                              </div>
                              <div className="col-lg-6 text-right col-md-4 col-4">
                                <Link className="e-view-all mt-3" to="/">View all</Link>
                              </div>
                            </div>
                            <div className="row">
                              {
                                this.state.enrolledData.map((data, index) => {
                                  var tempDelay = "." + index + 2 + "s";
                                  var tempURL = "/course-details?id=" + data.id;
                                  return (
                                    <div className="col-lg-3 col-md-6 mb-4" key={index}>
                                      <Link to={tempURL}>
                                        <CourseCard data={data} delay={tempDelay} />
                                      </Link>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                        </section>
                        :
                        null
                    }
                  </div>
              }

              {
                !this.state.savedData && !this.state.enrolledData && !this.state.opendData && !this.state.enrolledDataLoading && !this.state.savedDataLoading && !this.state.openDataLoading ?
                  <div className="pb-4">
                    <NoDataFound head="Nothing Found" data="No courses enrolled yet!" />
                  </div>
                  :
                  null
              }
            </div>
            :
            null
        } */}
                {
                    this.state.tabActive === 2 ?
                        <YourWebinars />
                        :
                        null
                }


                {
                    this.state.tabActive === 3 ?
                        <OrderHistory />
                        :
                        null
                }

                {
                    this.state.tabActive === 4 ?
                        <Notification />
                        :
                        null
                }

                {/* Modals */}
                {
                    this.state.profileData ?
                        <EditProfileModal data={this.state.profileData} success={this.editSuccess.bind(this)} type={1} />
                        :
                        null
                }
            </div>
        );
    }
}

export default Profile;
