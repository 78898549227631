/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
/*Components*/


/*Assets*/
import logo from '../../assets/images/logo-white.png';
import Bell from "../../assets/images/bell.png"

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (

            <footer className="e-footer">
                <div className="container">
                    <div className="row pb-5">
                        <div className="col-lg-8 col-sm-8 col-12">
                            <img draggable="false" className="mr-5 e-logo" src={logo} alt="Tradeschool logo" />
                            <div className="e-footer-contact">
                                Email us on <a href="mailto:tradeschool@aliceblueindia.com">tradeschool@aliceblueindia.com</a>
                            </div>
                            <div className="e-footer-copyright">©2023 Trade School. All rights reserved | <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/disclaimer">Disclaimer</Link></div>
                            {/* <div className="e-footer-copyright">©2023 Trade School. All rights reserved | <Link to="/terms-conditions">Terms &  Conditions</Link> | <Link to="/privacy-policy">Privacy Policy</Link></div> */}
                            {/*<Link to="/terms-conditions">Terms & Conditions</Link>*/}
                        </div>
                        <div className="col-lg-4 col-sm-4 col-12 text-right">
                            <div className="e-powered-by ">Powered by
                                <a className='pl-1' href="https://evoqins.com" target="_blank">Evoqins</a>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="e-footer-two">
                    <div className='container'>
                        <div className=' row'>
                            <div className='col-lg-6'>
                                <p>Corporate Office: No. 153/2, 3rd Floor, M.R.B.Arcade, Bagalur Main Road, Dwaraka Nagar, Yelahanka, Bengaluru - 560 063, Karnataka.</p>
                                <p>Alice Blue Financial Services (P) Ltd : NSE EQ | NSE FO | NSE CDS-90112 SEBI REG : INZ000156038</p>
                                <p>Alice Blue Financial Services (P) Ltd : CDSL DP ID 12085300 DP SEBI REG : IN-DP-364-2018</p>
                                <div className='mb-4'>
                                    <a href="https://www.mcxindia.com/" target='_blank'>MCX</a>
                                    <a href="https://www.msei.in/Index" target='_blank'>MCX-SX</a>
                                    <a href="https://www.nseindia.com/" target='_blank'>NSE </a>
                                    <a href="https://www.cdslindia.com/" target='_blank'>CDSL</a>
                                    <a href="https://www.bseindia.com/" target='_blank'>BSE</a>
                                    <a href="https://www.sebi.gov.in/" target='_blank'>SEBI</a>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <p>Registered Office: Old No.56/2 ,New No.58, LIC Nagar, Vinayagar Kovil Street, Erode -8,Tamil Nadu– 638002.</p>
                                <p>Alice Blue Financial Services (P) Ltd : BSE EQ | BSE FO | BSE CD-6670 SEBI REG : INZ000156038</p>
                                <p>Alice Blue Financial Services (P) Ltd : MCX-56710 SEBI REG : INZ000156038</p>
                            </div>


                        </div>
                        <div>
                            <img draggable="false" src={Bell} alt='footer-img' />
                            <span>Attention Investors</span>
                            <p className='m-0'>
                                <marquee>"Prevent unauthorized transactions in your account update your mobile number/ email Id with your Stock Broker. Receive information of your transactions directly from  Exc</marquee>
                            </p>
                        </div>
                    </div>
                </section>
                <section className="e-footer-three">
                    <div className='container'>
                        <p>Procedure to file a complaint on <a href=" https://scores.gov.in/scores/Welcome.html" target='_blank'>SEBI SCORES</a> : Register on SCORES portal. Mandatory details for filing complaints on SCORES: Name, PAN, Address, Mobile Number, E-mail ID. Benefits: Effective Communication, Speedy redressal of the grievances</p>
                        <p>Investments in securities market are subject to market risks, read all the related documents carefully before investing.</p>
                        <p>or queries regarding account opening or activation, email to
                            <a className='mx-1' href='mailto:accountactivation@aliceblueindia.com' target='_blank'>accountactivation@aliceblueindia.com</a>
                            and for fund updates, email to
                            <a className='mx-1' href='mailto:funds@aliceblueindia.com' target='_blank'>funds@aliceblueindia.com</a>
                        </p>
                        <p className='m-0'>
                            <span className='e-font-weight-normal'>Disclaimer :</span> Prevent unauthorised transactions in your account. Update your mobile numbers/email IDs with your stock brokers. Receive information of your transactions directly from Exchange on your mobile/email at the end of the day. Issued in the interest of investors. All clients have to update their email id and mobile number with Member : Investor Grievance grievances@aliceblueindia.com
                            KYC is a one time exercise while dealing in securities markets - once KYC is done through a SEBI registered intermediary (broker, DP, Mutual Fund etc.), you need not undergo the same process again when you approach another intermediary. No need to issue cheques by investors while subscribing to IPO. Just write the bank account number and sign in the application form to authorise your bank to make payment in case of allotment. No worries for the refund as the money remains in investor's account.</p>
                    </div>
                </section>
            </footer>

        );
    }
}

export default Footer;
