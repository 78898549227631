/* import packages */
import React, { useState } from 'react';

/* import assets */
// import Banner from "../../assets/images/faq/faq-banner-img.png"
import Add from "../../assets/images/webinar-detail/down-arrow.svg"
import Cancel from "../../assets/images/webinar-detail/up-arrow.svg"
// import { PrimaryBtn } from '../../components/cta';

//collapse array

const collapseData = [
    {
        id: 1,
        title: "1.  What happens after paying for webinars?",
        description: "The webinars in our Trade School are free. You don’t have to pay anything to attend these webinars."
    },
    {
        id: 2,
        title: "2. How do I get the remainder of the webinars?",
        description: "You’ll receive reminder emails through our Zoom Webinar portal 1 hour, 1 day, or one week before the webinar start time."
    },
    {
        id: 3,
        title: "3. What happens if I miss the webinar?",
        description: "We suggest that you attend the live webinars. If you’re unable to attend the webinar, we can provide the recording on request. However, it depends on the availability of the recording."
    },
    {
        id: 4,
        title: "4. How do I know about the upcoming webinars?",
        description: `You just have to visit our website <a href="https://tradeschool.aliceblueonline.com/" target="_blank">https://tradeschool.aliceblueonline.com/</a>. We keep updating all our upcoming webinars. The available seats for webinars are limited, and we kindly recommend that you secure your registration promptly.`
    },
]

const Faq = () => {

    const [collapse, setCollapse] = useState(collapseData[0].id)

    return (
        <>
            {/*collapse section*/}
            <div className="">

                {
                    collapseData.map((item, key) => {
                        return (
                            <div className="e-collapse-sec mb-2 mt-3" key={key}>

                                <div onClick={() => { setCollapse(item.id); }} >
                                    {
                                        item.id !== collapse ?
                                            <div className='e-faq-wraper'>
                                                <div className="row" style={{ cursor: "pointer" }}>
                                                    <div className="col-lg-10 col-10">
                                                        <h4 className='mb-0' style={{ cursor: "pointer" }}>{item.title}</h4>
                                                    </div>
                                                    <div className="col-lg-2 col-2 text-right">
                                                        <div >
                                                            <img draggable="false" src={Add} alt="" style={{ cursor: "pointer" }} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                <div onClick={() => { setCollapse(null); }} >
                                    {
                                        item.id === collapse ?

                                            <div className="row" style={{ cursor: "pointer" }}>

                                                <div className="col-lg-10 col-10">
                                                    <h4 className='mb-0' style={{ cursor: "pointer" }}>{item.title}</h4>
                                                </div>
                                                <div className="col-lg-2 col-2 text-right">
                                                    <div >
                                                        <img draggable="false" src={Add} alt="" style={{ cursor: "pointer" }} className="e-faq-minus" />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                {
                                    item.id === collapse ?
                                        <div className="row mb-3">
                                            <div className="col-lg-12">
                                                {/* <p className="mb-0 pl-3">{item.description}</p> */}
                                                <p className="mb-0 pl-3" dangerouslySetInnerHTML={{ __html: item.description }} />
                                            </div>

                                        </div>
                                        :
                                        null
                                }
                            </div>
                        );
                    })

                }

            </div>

        </>
    )
}


export default Faq;