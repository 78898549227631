/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


/*Assets*/

class OrderCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      mailSending: false,
      mailsent: false
    }
  }

  sendMail(tempID){
    this.setState({mailSending: true});
    var url = devconfig.user+"/course/email-invoice";
    var data = JSON.stringify({
      "enrolled_id": tempID
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({mailsent: true});

        setTimeout(function() { 
          this.setState({mailsent: false, mailSending: false});
        }.bind(this), 2000);
      }
      else{
        this.setState({mailSending: false, mailsent: false});
      }
    }.bind(this))
  }

  render() {
    return (
      <div className="e-order-item mb-4 wow fadeInUp" data-wow-delay={this.props.delay}>
        <div className="e-order-details">
          <div className="media">
            <img className="mr-3" src={this.props.data.image} alt={this.props.data.name}/>
            <div className="media-body">
              <h5 className="mt-0">{this.props.data.name}</h5>
              <p>{this.props.data.creator}</p>
              <span className="e-review">{this.props.data.rating}</span>
            </div>
          </div>
        </div>
        <div className="e-invoice-detail-wrap">
          <div className="d-flex justify-content-between">
            <h6>Invoice no: {this.props.data.transaction_id}</h6>
            <h6>{this.props.data.purchased_on}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <span className="e-invoice-detail-item">Course Total</span>
            <span className="e-invoice-detail-item">₹ {this.props.data.offer_price ? <span>{this.props.data.offer_price}</span> : <span>{this.props.data.price}</span>}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="e-invoice-detail-item">GST</span>
            <span className="e-invoice-detail-item">₹ {this.props.data.sgst}</span>
          </div>
          <div className="d-flex justify-content-between e-invoice-total-wrap">
            <h6>Total</h6>
            <h6>₹ {this.props.data.total}</h6>
          </div>
          {
            this.state.mailsent ?
              <div className="text-right">
                <span className="e-link-txt-blue e-mail-sent">Successfully send</span>
              </div>
            :
              <div className="text-right">
                {
                  this.state.mailSending ?
                    <span className="e-link-txt-blue">Sending...</span>
                  :
                    <span className="e-link-txt-blue" onClick={this.sendMail.bind(this, this.props.data.enrolled_id)}>Send Invoice to mail</span>
                }
              </div>
          }
        </div>
      </div>
    );
  }
}

export default OrderCard;
