/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import GoogleLogin from 'react-google-login';

/*Components*/
import CategoryHeader from './category';
// 

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';


/*Assets*/
import logo from '../../assets/images/logo.png';
import menu from '../../assets/images/icons/menu.svg';
import close from '../../assets/images/icons/close.svg';
import Aliceblue from "../../assets/images/icons/aliceblue-logo.webp";
import OpenAccountModal from '../modals/open-account';
class HeaderOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            dataCategory: null,
            catToggle: false,
            name: "",
            placeholder: "",
            dDownToggle: false,
            dDownToggleMob: false,
            profileData: {},
            showModal: false,
        }
    }

    componentDidMount() {
        var query = this.getUrlVars();
        this.findActiveItem();
        if (localStorage.getItem("tradeschool_access_token")) {
            var tempData = JSON.parse(localStorage.getItem('userData'));
            var tempNameSplit = tempData.name.split(" ");
            var tempPlaceholder = tempNameSplit[0].charAt(0) + tempNameSplit[tempNameSplit.length - 1].charAt(0);
            this.setState({ name: tempNameSplit[0], placeholder: tempPlaceholder });
            this._getProfileData(false);
        }
    }

    findActiveItem() {
        var tempUrl = [];
        tempUrl = window.location.href.split("/");
        var newUrl = tempUrl[tempUrl.length - 1].split("?");
        this.setState({ activeTab: newUrl[0] });
    }

    getCategoryData() {
        var url = devconfig.user + "/course-category/get-all";

        APIGet(url).then(function (response) {
            if (response.status) {
                this.setState({ dataCategory: response.data });
            }
            else {
                this.setState({ dataCategory: null });
            }
        }.bind(this))
    }

    getUrlVars() {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    responseGoogle(response) {
        if (!response.error) {
            this.apiSocialLogin(response.profileObj.name, response.profileObj.email, response.profileObj.imageUrl, response.googleId);
        }
        else {
            this.setState({ triggerGoogleLogin: false, googleLoginLoading: false });
        }
    }

    googleLoginClick() {
        this.setState({ triggerGoogleLogin: true, googleLoginLoading: true });
        $(".e-google-login-wrap button").trigger("click");
    }

    apiSocialLogin(tempName, tempEmail, tempURL, tempID) {
        var url = devconfig.user + "/auth/login";
        var data = JSON.stringify({
            "email": tempEmail,
            "name": tempName,
            "social_user_id": tempID,
            "image": tempURL,
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                localStorage.setItem("tradeschool_access_token", response.access_token);
                localStorage.setItem("userData", JSON.stringify(response.data));
                localStorage.setItem("tradeschool_token", true);
                window.location.reload();
            }
            else {
            }
        }.bind(this))
    }

    //API - Get Profile data
    _getProfileData(modal_status) {
        var base_url = devconfig.user;
        let partner_token = localStorage.getItem("partner_token") ;
        // # checking if partner 
        if(partner_token==="true"){
            base_url = devconfig.partnerV1;
        }
        var url = base_url + "/auth/profile";
        APIGet(url).then(function (response) {

            if (response.status) {
                this.setState({
                    profileData: response.data,
                    showModal: true, // enable the modal
                    dDownToggleMob: false
                }, ()=> {
                    if (modal_status == true) {
                        $('#openAccountModal').modal('show');
                    }
                });
            }
            else {
                this.setState({
                    profileData: [],
                    showModal: true, // enable the modal
                    dDownToggleMob: false
                }, ()=> {
                    if (modal_status == true) {
                        $('#openAccountModal').modal('show');
                    }
               });
            }
        }.bind(this))
    }

    searchChange(e) {
        this.setState({ search: e.target.value });
    }

    searchSubmit() {
        var tempURL = "/search?kw=" + this.state.search;
        window.location.href = tempURL;
    }

    triggerSearchKeydown(e) {
        if (e.keyCode == 13 && this.state.search.length) {
            this.searchSubmit();
        }
    }

    categoryToggle() {
        this.setState({ catToggle: !this.state.catToggle });
    }

    categoryClick(tempId) {
        var tempURL = "/all-courses?id=" + tempId;
        window.location.href = tempURL;
    }

    dDownToggle() {
        this.setState({ dDownToggle: !this.state.dDownToggle });
    }

    dDownToggleMob() {
        this.setState({ dDownToggleMob: !this.state.dDownToggleMob });
    }

    logout() {
        window.localStorage.clear();
        window.location.href = "/";
    }

    //open an account modal handleClick
    _handleclick = () => {
        this._getProfileData(true);
        this.dDownToggleMob.bind(this);
    }

    render() {
        return (
            <>
                <header className="e-header">

                    <div className="container e-header-desktop">

                        <div className="row">
                            <div className="col-lg-6 position-relative">
                                <Link to="/" className="mr-5">
                                    <img draggable='false'
                                        className="e-logo"
                                        src={logo}
                                        alt="Tradeschool logo" />
                                </Link>
                                <span className="e-categories"><CategoryHeader data={this.state.dataCategory} /></span>
                                {/*
                                        this.state.catToggle ?
                                        <span className="e-categories e-active" onClick={this.categoryToggle.bind(this)}>Categories</span>
                                        :
                                        <span className="e-categories" onClick={this.categoryToggle.bind(this)}>Categories</span>
                                    */}

                                {
                                    this.state.dataCategory && this.state.catToggle ?
                                        <div>
                                            <div className="e-header-categories-wrap">
                                                {
                                                    this.state.dataCategory.map((data, index) => {
                                                        return (
                                                            <div key={index} className="e-header-category" onClick={this.categoryClick.bind(this, data.id)}>{data.name}</div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <div className="e-header-categories-overlay" onClick={this.categoryToggle.bind(this)}></div>
                                        </div>
                                        :
                                        null
                                }
                            </div>

                            {/* <div className="col-lg-3 position-relative pl-0">
                            <input className="e-search"
                                type="text"
                                value={this.state.search}
                                onKeyDown={this.triggerSearchKeydown.bind(this)}
                                onChange={this.searchChange.bind(this)}
                                placeholder="What do you want to learn?" />
                            {
                                localStorage.getItem("tradeschool_access_token") ?
                                    <>
                                        <Link className="mr-3" to="/home">
                                            <span className={this.state.activeTab === "home" ? "e-link-txt-header e-active " : this.state.activeTab === "all-courses" ? "e-link-txt-header e-active " : "e-link-txt-header "} onClick={() => this.setState({ activeTab: "home" })}>Courses</span>
                                            </Link>
                                            <Link className="mr-3" to="/webinar">
                                            <span className={this.state.activeTab === "webinar" ? "e-link-txt-header e-active " : this.state.activeTab === "webinar-details" ? "e-link-txt-header e-active" : this.state.activeTab === "all-webinars" ? "e-link-txt-header e-active " : "e-link-txt-header"} onClick={() => this.setState({ activeTab: "webinar" })}>Webinars</span>
                                            </Link>
                                    </>
                                    : null


                            }
                            {this.state.search.length ?
                                <span className="e-search-submit" onClick={this.searchSubmit.bind(this)}></span>
                                :
                                null}
                                 </div> */}

                            <div className="col-lg-6 text-right position-relative">
                                <Link className="mr-3" to="/">
                                    <span className={this.state.activeTab === "webinar" || "/" ? "e-link-txt-header e-active " : this.state.activeTab === "webinar-details" ? "e-link-txt-header e-active " : this.state.activeTab === "all-webinars" ? "e-link-txt-header e-active " : "e-link-txt-header "}
                                        onClick={() => this.setState({ activeTab: "webinar" })}>
                                        Webinars
                                    </span>
                                </Link>
                            

                                {
                                    this.state.profileData.alice_blue_id == null ?
                                            <span className="e-s-btn mr-2" 
                                                onClick={this._handleclick}>
                                                Open An Account 
                                            </span> 
                                        : 
                                            null
                                }

                                {
                                    this.state.name ?
                                        <span className='mr-3'>
                                            <span className="e-header-name">{this.state.name}</span>
                                            <span className="e-header-avatar" onClick={this.dDownToggle.bind(this)}>{this.state.placeholder}</span>

                                            {
                                                this.state.dDownToggle ?
                                                    <>
                                                        <div className="e-logged-dropdown-wrap">
                                             
                                                            <a href="/profile?favwebinar=true">
                                                                <div className="e-dropdown-item e-dropdown-item-order" onClick={this.dDownToggle.bind(this)}>Profile</div>
                                                            </a>
                                                      
                                                            <a href="/profile?notification=true">
                                                                <div className="e-dropdown-item e-dropdown-item-notify" onClick={this.dDownToggle.bind(this)}>Notifications</div>
                                                            </a>
                                                            <div className="e-dropdown-item e-dropdown-item-logout" onClick={this.logout.bind(this)}>Logout</div>
                                                        </div>
                                                        <div className="e-logged-dropdown-overlay" onClick={this.dDownToggle.bind(this)}></div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </span>
                                        :
                                        <span className="e-p-btn mr-3"
                                            data-toggle="modal"
                                            data-target="#loginModal" >
                                            Login
                                        </span>
                                }
                                <span className="e-google-login-wrap">
                                    <GoogleLogin clientId={devconfig.googleClientId} buttonText="LOGIN WITH GOOGLE" onSuccess={this.responseGoogle.bind(this)} onFailure={this.responseGoogle.bind(this)} />
                                </span>
                                <span className='position-relative e-aliceblue-logo '>
                                    <a href="https://aliceblueonline.com">
                                        <img draggable='false'
                                            src={Aliceblue}
                                            alt="Tradeschool logo" />
                                        <span className='position-absolute'>Powered by</span>

                                    </a>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div>

                        <div className="container e-header-mobile">
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-4">
                                    <Link to="/">
                                        <img draggable="false" className="mr-5 e-logo" src={logo} alt="Tradeschool logo" />
                                    </Link>
                                </div>
                                <div className="col-md-8 col-sm-8 col-8 text-right">
                                    {
                                        this.state.dDownToggleMob ?
                                            <img draggable="false" className="e-menu-icon e-close" src={close} onClick={this.dDownToggleMob.bind(this)} alt="Tradeschool menu close" />
                                            :
                                            <img draggable="false" className="e-menu-icon" src={menu} onClick={this.dDownToggleMob.bind(this)} alt="Tradeschool menu" />
                                    }
                                </div>
                            </div>

                            {
                                this.state.dDownToggleMob ?
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="e-mob-menu-wrap">

                                
                                                <a href="/all-webinars">
                                                    <div className="e-dropdown-item e-dropdown-item-allCourse" 
                                                        onClick={this.dDownToggle.bind(this)}>All Webinars</div>
                                                </a>

                                                {
                                                    this.state.name ?
                                                        <div className="pb-5">
                                                            {/* <a href="/profile">
                                                        <div className="e-dropdown-item e-dropdown-item-courses" onClick={this.dDownToggle.bind(this)}>Your Courses</div>
                                                        </a> */}
                                                            <a href="/profile?favwebinar=true">
                                                                <div className="e-dropdown-item e-dropdown-item-order" 
                                                                    onClick={this.dDownToggle.bind(this)}>Profile</div>
                                                            </a>
                                             
                                                            <a href="/profile?notification=true">
                                                                <div className="e-dropdown-item e-dropdown-item-notify" 
                                                                    onClick={this.dDownToggle.bind(this)}>Notifications</div>
                                                            </a>
                                                            <div className="e-dropdown-item e-dropdown-item-logout" 
                                                                onClick={this.logout.bind(this)}>Logout</div>
                                                        </div>
                                                        :
                                                        null
                                                }

                                                {/* <span className="e-s-btn e-header-open-ac text-center mr-4" data-toggle="modal" data-target="#openAccountModal">Open Trading Account</span> */}

                                                <div className="">
                                                    {
                                                        !this.state.name ?
                                                            <span className="e-p-btn e-header-login text-center mr-4" 
                                                                data-toggle="modal" 
                                                                data-target="#loginModal" >Login</span>
                                                            :
                                                            null
                                                    }
                                                 
                                                    {
                                                        this.state.profileData.alice_blue_id == null ?
                                                            <span className="e-s-btn e-header-open-ac text-center mr-4" 
                                                                onClick={this._handleclick}>Open An Account</span>
                                                        : 
                                                            null
                                                    }
                                                </div>

                                                <span className='position-relative1 e-aliceblue-logo-mob d-flex flex-row-reverse bd-highlight'>
                                                        <div className='pt-3'>
                                                        <span className=''>Powered by</span>
                                                            <a href="https://aliceblueonline.com">
                                                                <img draggable='false'
                                                                    src={Aliceblue}
                                                                    alt="Tradeschool logo" />
                                                            

                                                            </a>
                                                            
                                                        </div>

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                        {
                            this.state.dDownToggleMob ?
                                <div className="e-logged-dropdown-overlay" onClick={this.dDownToggleMob.bind(this)}></div>
                                :
                                null
                        }

                    </div>


                </header >
                {/* <OpenAccountModal profileData={this.state.profileData} /> */}

                {
                    this.state.showModal == true &&
                    <OpenAccountModal webinarId={this.state.id}
                        success={() => {
                            this.setState({
                                showModal: false
                            }, () => {
                                $('#openAccountModal').modal('hide');
                            })
                        }}
                        profileData={this.state.profileData} />
                }

            </>
        );
    }
}

export default HeaderOne;
