/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*Components*/
import UpcomingWebinarCard from '../../components/cards/upcomingWebinar.js';

class ThreeCardSlider extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }



  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
         breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            dots: false,
          }
        },
        {
        breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            dots: false,
          }
        }
      ]
    };

    return (
      <div>
        <Slider {...settings}>
        {
          this.props.data ?
          this.props.data.map((item, key)=>{
            return(
              <div className="e-slider-item" key={key}>
                <UpcomingWebinarCard data={item} reload={this.props.reload}/>
              </div>
            )
          })
          :
          null
        }
          
         
        </Slider>
      </div>
    );
  }
}

export default ThreeCardSlider;
