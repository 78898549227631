/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import OrderPlaceholder from '../../components/placeholder/order';
import OrderCard from '../../components/cards/order';
import orderHistory from './orderHistory';
import NoDataFound from '../../components/common/nothing';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';

/*Assets*/
import cover from '../../assets/images/home/cover.png';
import BannerPattern1 from '../../assets/images/all-courses/banner-pattern-1.svg';
import BannerPattern2 from '../../assets/images/all-courses/banner-pattern-2.svg';
import CourseBanner from '../../assets/images/profile/course.svg';

class OrderHistory extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      dataLoading: false,
      pagination: false,
      pageNum: 1,
      paginationLoading: false,
    }
  }

  componentDidMount() {
    new WOW.WOW().init();

    this.getData(this.state.pageNum);
  }

  getData(tempPageNum){
    this.setState({dataLoading: true});

    var url = devconfig.user+"/course/order-history";
    var data = JSON.stringify({
      "page_num": tempPageNum
    })

    APIPost(url, data).then(function(response){
      if(response.status){
        if(this.state.data){
          var tempArray = this.state.data;
          tempArray.push(response.data);
          this.setState({ data: tempArray, dataLoading: false, paginationLoading: false});
        }
        else{
          this.setState({ data: response.data, dataLoading: false, paginationLoading: false});
        }

        if(response.data.length >= 19){
          this.setState({ pagination: true});
        }
        else{
          this.setState({ pagination: false});
        }

        new WOW.WOW().init();
      }
      else{
        this.setState({paginationLoading: false, dataLoading: false});
      }
    }.bind(this))
  }

  loadMore(){
    this.getData(this.state.pageNum + 1);
    this.setState({ pageNum: this.state.pageNum + 1, paginationLoading: true});
  }

  render() {
    return (
      <div className="e-orders-wrap">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4 className="pb-4">Courses you bought</h4>
              </div>
              <div className="col-lg-6 text-right">
                <a className="e-phone" href="tel:09964945500">09964945500</a>
                <a className="e-mail" href="mailto:tradeschool@aliceblue.com">tradeschool@aliceblue.com</a>
              </div>
            </div>

            {
              this.state.dataLoading ?
                <div className="row e-min-height-50">
                  <div className="col-lg-6">
                    <OrderPlaceholder/>
                  </div>
                  <div className="col-lg-6">
                    <OrderPlaceholder/>
                  </div>
                </div>
              :
                <div>
                  {
                    this.state.data && this.state.data.length ?
                      <div>
                        <div className="row ">
                          { 
                            this.state.data.map( (data, index) => {
                              var tempDelay = "."+index+2+"s";
                              return(
                                <div className="col-lg-6" key={index}>
                                  <OrderCard data={data} delay={tempDelay}/>
                                </div>
                              );
                            })
                          }
                        </div>
                        {
                          this.state.pagination ?
                            <div className="row mt-3">
                              <div className="col-lg-12 text-center">
                                {
                                  this.state.paginationLoading ?
                                    <span className="e-pagination-loader"></span>
                                  :
                                    <span className="e-s-btn" onClick={this.loadMore.bind(this)}>Load more</span>
                                }
                              </div>
                            </div>
                          :
                            null
                        }
                      </div>
                    :
                      <div>
                        <NoDataFound head="Nothing Found" data="You have not bought any courses yet."/>
                      </div>
                  }
                </div>
            }
          </div>
        </section>
      </div>

      
    );
  }
}

export default OrderHistory;
