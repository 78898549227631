/*Package importing*/
import React, { Component } from 'react';

/*Import custom components and assets*/

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    componentDidMount() {
        if (this.props.defaultValue) {
            this.setState({ value: this.props.defaultValue });
        }
    }

    valueChange(e) {
        this.setState({ value: e.target.value });
        this.props.valueChange(e.target.value);
    }

    render() {
        return (
            <div className="e-input-wrap">
                <input id={this.props.id}
                    value={this.props.value}
                    type={this.props.type}
                    tabIndex={this.props.tabIndex}
                    placeholder={this.props.placeholder}
                    className={this.props.className}
                    onChange={this.valueChange.bind(this)} required />
                <label htmlFor={this.props.id} className="label">
                    {this.props.label}
                </label>
                <p className='e-error-text pt-1'>{this.props.error}</p>
            </div>
        );
    }
}

export default Input;
