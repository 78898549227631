/*Package importing*/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $, { data } from 'jquery';
import WOW from 'wowjs';

/*Components*/
import LatestWebinarCard from '../../components/cards/latestWebinar.js';
import SelectBox from '../../components/formElements/select-box.js';
import Nothing from '../../components/common/nothing';
import CardPlaceholder from '../../components/placeholder/card';
import WebinarCard from '../../components/cards/webinar.js';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';

/*Assets*/
import BannerPattern1 from '../../assets/images/all-webinar/pattern1.svg';
import BannerPattern2 from '../../assets/images/all-courses/banner-pattern-2.svg';
import CalenderInput from '../../components/formElements/calender.js';
import SearchInput from '../../components/formElements/search.js';
import CloseImg from '../../assets/images/close.png';
import SearchIcon from "../../assets/images/icons/search.svg";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';


const OPTIONS = [
    { value: 1, label: 'Popularity' },
    { value: 2, label: 'Latest First' }
];

class AllWebinarListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 1,
            langFilterActive: false,
            filterActive: false,
            selectedCategories: [],
            data: [],
            selectedLang: [],
            placeholder4: [1, 2, 3, 4],
            pageNum: 1,
            filterLoading: false,
            listData: [],
            filterChange: false,
            pagination: false,
            webinarId: '',
            searchKeyword: "",
            dataLoading: false,
            value: '',
            selectedDate: null,
            dataLang: [],
            dataCategory: [],
            select_allLanguages: true,
            select_allCategories: true,
            updateDate: new Date()
        }
    }


    componentDidMount = async () => {

        var query = this.getUrlVars();
        if (query.id) {
            await this.setState({
                selectedCategories: [parseInt(query.id)],
            });
        }
        new WOW.WOW().init();
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        this.getCategoryData();
        this.getLangData();
        await this.getData();
        // this._postSearchData();

    }

    getUrlVars() {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }


    getCategoryData() {
        var url = devconfig.user + "/category/get-all";

        APIGet(url).then(function (response) {
            if (response.status) {
                // let dataCategory = response.data
                // dataCategory.unshift({
                //     'id': 0,
                //     'name': 'All'
                // });
                this.setState({
                    dataCategory: response.data
                });

                var query = this.getUrlVars();
                if (!query.id) {

                    // var tempArry = [];
                    var selected_category = response.data.map(item => item.id)

                    this.setState({
                        selectedCategories: selected_category
                    });
                }

            }
            else {
                this.setState({
                    dataCategory: []
                });
            }
        }.bind(this))
    }


    getLangData() {
        var url = devconfig.user + "/language/get-all";

        APIGet(url).then(function (response) {
            if (response.status) {
                this.setState({
                    dataLang: response.data,
                });
                var selected_languages = response.data.map(item => (item.id))
                this.setState({
                    selectedLang: selected_languages

                });

            }
            else {
                this.setState({
                    dataLang: []
                });
            }
        }.bind(this))
    }

    //API for all webinar listing
    getData = (temp) => {
        this.setState({
            dataLoading: true
        });

        let formatted_date = null;
        let selected_date = this.state.selectedDate;

        if (selected_date) {
            formatted_date = selected_date.toLocaleDateString('en-GB').split("/").join("-");
        }

        console.log('formattedDate', formatted_date);
        console.log('selected_date', selected_date)

        var url = devconfig.userV2 + "/webinar/all-webinars";
        var data = JSON.stringify({
            "page_num": this.state.pageNum,
            "filter_tab": this.state.tabIndex,
            "category_id": this.state.selectedCategories,
            "language_ids": this.state.selectedLang,
            "sort_value": parseInt(this.state.selectedSort),
            "date": formatted_date
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                var temdta = [];

                if (this.state.listData.length &&
                    !this.state.filterLoading && !this.state.filterChange && !temp == 1) {
                    temdta = this.state.listData;
                    temdta = $.merge(temdta, response.data);
                }
                else {
                    temdta = response.data;
                }
                if (response.data.length >= 20) {
                    this.setState({
                        pagination: true
                    });
                }
                else {
                    this.setState({
                        pagination: false
                    });
                }

                this.setState({
                    listData: temdta,
                    dataLoading: false,
                    filterLoading: false,
                    filterChange: false,

                })
            }
            else {
                this.setState({
                    paginationLoading: false,
                    dataLoading: false,
                    filterLoading: false,
                    filterChange: false,
                    listData: [],
                    pagination: false
                });
            }

        }.bind(this));
    }

    async tabItemClick(tempItem) {
        await this.setState({
            tabIndex: tempItem,
            filterLoading: true,
            pageNum: 1
        });
        await this.getData();
    }

    filterClick() {
        this.setState({
            filterActive: !this.state.filterActive
        });
    }

    langFilterClick() {
        this.setState({
            langFilterActive: !this.state.langFilterActive
        });
    }

    async checkClick(tempItem) {
        var tempArry = this.state.selectedCategories;

        if (tempArry.indexOf(tempItem) == -1) {
            tempArry.push(tempItem);
        }
        else {
            var index = tempArry.indexOf(tempItem);
            tempArry.splice(index, 1)
        }
        await this.setState({
            selectedCategories: tempArry
        });

    }


    async checkLangClick(tempItem) {
        var tempArry = this.state.selectedLang;

        if (tempArry.indexOf(tempItem) == -1) {
            tempArry.push(tempItem);
        }
        else {
            var index = tempArry.indexOf(tempItem);
            tempArry.splice(index, 1)
        }
        await this.setState({
            selectedLang: tempArry
        });
    }

    langFilterApply = () => {
        this.setState({

            langFilterActive: !this.state.langFilterActive,
            filterChange: true
        });
        this.getData();
    }

    filterApply = () => {
        this.setState({
            filterActive: !this.state.filterActive,
            filterChange: true
        });
        this.getData();
    }

    async loadMore(temp) {
        await this.setState({
            pageNum: temp
        });
        await this.getData();
    }
    selectChange = (temp) => {
        this.setState({
            selectedSort: temp.value
        });
    }

    //API - Upcoming Webinar searching
    _postSearchData() {
        this.setState({
            dataLoading: true
        });

        var url = devconfig.user + "/webinar/search-upcoming";
        var data = JSON.stringify({
            "query": this.state.searchKeyword
        })

        APIPost(url, data).then(function (response) {

            if (response.status) {
                this.setState({
                    listData: response.data,
                    dataLoading: false,
                });
            }
            else {
                this.setState({
                    listData: [],
                    dataLoading: false,
                });
            }
        }.bind(this));
    }

    // Handle webinar navigation to detail screen
    _handleChangeNavigation = (type, webinar_id) => {
        this.props.history.push(`/webinar-details?id=${webinar_id}`, {
            type: type,
            webinarId: webinar_id
        });
    }

    //Handle upcoming webinar search
    _handleSearchSubmit = () => {
        if (this.state.searchKeyword) {
            // getting all default language ids 
            var selected_languages = this.state.dataLang.map(item => (item.id))
            var selected_category = this.state.dataCategory.map(item => (item.id))
            this.setState({
                selectedDate: null,
                selectedLang: selected_languages,
                selectedCategories: selected_category
            }, () => {
                this._postSearchData();
            });
        }
    }


    _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this._handleSearchSubmit();
        }
    };

    // _checkLangClick = (langId) => {
    //     this.setState(prevState => ({
    //         selectedLang: prevState.selectedLang.includes(langId)
    //             ? prevState.selectedLang.filter(id => id !== langId)
    //             : [...prevState.selectedLang, langId]
    //     }));
    // }

    //handle check cetogory input 
    _checkCategoryClick = (tempItem) => {
        this.setState(prevState => {
            const selectedCategories = prevState.selectedCategories.includes(tempItem)
            const updated_selected_category = selectedCategories
                ? prevState.selectedCategories.filter(id => id !== tempItem)
                : [...prevState.selectedCategories, tempItem]

            const all_category_selected = updated_selected_category.length === this.state.dataCategory.length;
            return {
                selectedCategories: updated_selected_category,
                select_allCategories: all_category_selected || false,
            };
        });
    }

    //handle check language input
    _checkLangClick = (langId) => {
        this.setState(prevState => {
            const selectedLang = prevState.selectedLang.includes(langId);
            const updated_selected_lang = selectedLang
                ? prevState.selectedLang.filter(id => id !== langId)
                : [...prevState.selectedLang, langId];

            // Check if any individual languages are selected
            const all_languages_selected = updated_selected_lang.length === this.state.dataLang.length;
            return {
                selectedLang: updated_selected_lang,
                select_allLanguages: all_languages_selected || false,
            };
        });
    }


    //Handle upcoming webinar search clear
    _handleSearchClear = () => {
        this.setState({
            searchKeyword: "",
            listData: []
        }, () => {
            this.getData();
        })
        // window.location.reload();
    }


    //Handle webinar date filter
    _handleDateChange = (selectedDate) => {
        this.setState({
            listData: [],
            selectedDate: selectedDate != null ? selectedDate : null,
        }, () => {
            this.getData();
        });
    };


    // _checkAllLanguages() {
    //     this.setState((prevState) => ({
    //         selectAllLanguages: !prevState.selectAllLanguages, // Toggle selectAllLanguages
    //         selectedLang: !prevState.selectAllLanguages // Update selectedLang based on the new state
    //             ? this.state.dataLang.map((data) => data.id) // Select all languages
    //             : [], // Clear selection if deselecting "All"
    //     }));
    // }


    //handle select all languages
    _handleSelectAllLanguages = () => {
        this.setState((prevState) => {
            const select_allLanguages = !prevState.select_allLanguages;
            const selectedLang = select_allLanguages
                ? this.state.dataLang.map((data) => data.id)
                : [];

            return {
                select_allLanguages,
                selectedLang,
            };
        });
    }

    //handle select all Categories
    _handleSelectAllCategories = () => {
        this.setState((prevState) => {
            const select_allCategories = !prevState.select_allCategories;
            const selectedCategories = select_allCategories
                ? this.state.dataCategory.map((data) => data.id)
                : [];

            return {
                select_allCategories,
                selectedCategories,
            };
        });
    }

    render() {
        var tempIndex = 1;
        return (
            <div className="e-home e-all-course-wrap e-all-webinar-wrap pb-5">
                <section className="e-banner-section e-webinar-banner-section position-relative">
                    <div className="container position-relative">
                        <img className="e-banner-pattern-1 wow fadeIn"
                            draggable="false"
                            data-wow-duration="4s"
                            src={BannerPattern1}
                            alt="Tradeschool courses listing" />
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 className="">All Webinars</h3>
                            </div>
                        </div>
                        <img className="e-banner-pattern-2 wow fadeIn"
                            draggable="false"
                            data-wow-duration="4s"
                            src={BannerPattern2}
                            alt="Tradeschool courses listing" />
                    </div>
                </section>

                <section className="my-4">
                    <div className="container">
                        <div className="row mb-4 position-relative">
                            <div className="col-lg-2 col-md-3 col-sm-5 col-6">
                                <div className="e-filter-item" onClick={this.filterClick.bind(this)}>
                                    Filter
                                    {
                                        this.state.selectedCategories.length ?
                                            <span>{this.state.selectedCategories.length}</span>
                                            :
                                            <span className="e-green-dot"></span>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-5 col-6">
                                <div className="e-filter-item e-filter-item-lang"
                                    onClick={this.langFilterClick.bind(this)}>
                                    Language
                                    {
                                        this.state.selectedLang.length ?
                                            <span>{this.state.selectedLang.length}</span>
                                            :
                                            <span className="e-green-dot"></span>
                                    }
                                </div>
                                {
                                    this.state.langFilterActive ?

                                        <div className="e-filter-wrap e-lang-filter-wrap">
                                            <h6 className="mb-3">Languages</h6>
                                            <div className="mb-4">
                                                <label className="e-checkbox-wrap">
                                                    All
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.select_allLanguages}
                                                        onClick={this._handleSelectAllLanguages}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                            {
                                                this.state.dataLang.length != 0 ?
                                                    <div className="row">
                                                        {
                                                            this.state.dataLang.map((data, index) => {
                                                                let is_selected = this.state.selectedLang.includes(data.id);
                                                                return (
                                                                    <div className="col-lg-12 mb-3" key={index}>
                                                                        <label className="e-checkbox-wrap">
                                                                            {data.name} {<span className="e-videos-count-tag">{data.count} videos</span>}
                                                                            <input type="checkbox"
                                                                                checked={is_selected}
                                                                                onClick={() => this._checkLangClick(data.id)} />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                );
                                                            })
                                                        }


                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className="row">
                                                <div className="col-lg-12 text-right">
                                                    {this.state.selectedLang.length ?
                                                        <span className="e-p-btn"
                                                            onClick={this.langFilterApply}>
                                                            Apply Filter
                                                        </span> :
                                                        <span className="e-p-btn e-inactive" >
                                                            Apply Filter
                                                        </span>}
                                                </div>
                                            </div>
                                        </div>

                                        :
                                        null
                                }

                            </div>

                            {
                                this.state.filterActive ?
                                    <div className="e-filter-wrap e-webinar-filter">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <h6 className="mb-3 fontQuicksand">Sort by</h6>
                                                <SelectBox
                                                    options={OPTIONS}
                                                    selectchange={this.selectChange.bind(this)}
                                                    defaultValue={OPTIONS[0]} />
                                            </div>
                                        </div>

                                        <h6 className="mb-3 mt-4">Categories</h6>
                                        <div className="mb-4">
                                            <label className="e-checkbox-wrap">
                                                All
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.select_allCategories}
                                                    onClick={this._handleSelectAllCategories}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        {
                                            this.state.dataCategory ?
                                                <div className="row">

                                                    {
                                                        this.state.dataCategory.map((data, index) => {
                                                            var tempArry = this.state.selectedCategories;
                                                            console.log("tempItem");
                                                            let is_selected = this.state.selectedCategories.includes(data.id);
                                                            return (
                                                                <div className="col-lg-4 mb-3" key={index}>
                                                                    <label className="e-checkbox-wrap">
                                                                        {data.name}
                                                                        <input type="checkbox"
                                                                            onClick={() => this._checkCategoryClick(data.id)}
                                                                            checked={is_selected} />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                                :
                                                null
                                        }
                                        <div className="row">
                                            <div className="col-lg-12 text-right">
                                                {this.state.selectedCategories &&
                                                    this.state.selectedCategories.length ?
                                                    <span className="e-p-btn"
                                                        onClick={this.filterApply}>
                                                        Apply Filter
                                                    </span>
                                                    :
                                                    <span className="e-p-btn e-inactive" >
                                                        Apply Filter
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            <div className={this.state.tabIndex == 1 ?
                                'col-lg-2 col-md-3 col-sm-5 pt-lg-0 pt-md-0 pt-3'
                                :
                                ' e-visibility-hidded'}>
                                <CalenderInput
                                    clearIcon={
                                        // this._handleDateClear &&
                                        this.state.selectedDate != null &&
                                        <img className="e-clear-button"
                                            src={CloseImg}
                                            alt="Clear" />
                                    }
                                    value={this.state.selectedDate}
                                    onChange={this._handleDateChange} />
                            </div>

                        </div>
                        {
                            this.state.filterActive ?
                                <div className="e-filter-overlay"
                                // onClick={this.filterClick.bind(this)}
                                >
                                </div>
                                :
                                null
                        }
                        {
                            this.state.langFilterActive ?
                                <div className="e-filter-overlay"
                                // onClick={this.langFilterClick.bind(this)}
                                >
                                </div>
                                :
                                null
                        }
                    </div>
                </section>
                <section className="my-4 pt-3">
                    <div className="container">
                        <div className="row mb-2 position-relative">
                            <div className="col-lg-6 col-md-7 e-webinar-tab-wrap e-tab-items py-3">
                                {
                                    this.state.tabIndex === 1 ?
                                        <span className="e-tabs-item e-active mr-5">Upcoming</span>
                                        :
                                        <span onClick={this.tabItemClick.bind(this, 1)}
                                            className="e-color-inactive mr-5">Upcoming</span>
                                }
                                {
                                    this.state.tabIndex === 2 ?
                                        <span className="e-tabs-item e-active mr-5 ">Completed</span>
                                        :
                                        <span onClick={this.tabItemClick.bind(this, 2)}
                                            className="e-color-inactive mr-5">Completed</span>
                                }


                                {
                                    this.state.tabIndex === 3 ?
                                        <span className="e-tabs-item e-active mr-5">Ongoing</span>
                                        :
                                        <span onClick={this.tabItemClick.bind(this, 3)}
                                            className="e-color-inactive">Ongoing</span>
                                }
                            </div>
                            {
                                this.state.tabIndex == 1 ?
                                    <div className='col-lg-6 col-md-5 
                                    mt-lg-0 mt-md-0 mt-4 position-relative'>
                                        <SearchInput
                                            placeholder="What do you want to learn?"
                                            className="e-search e-webinar-search border-0"
                                            value={this.state.searchKeyword}
                                            onKeyPress={this._handleKeyPress}
                                            searchChange={(value) => {
                                                this.setState({
                                                    searchKeyword: value
                                                })
                                            }} />
                                        {this.state.searchKeyword ?
                                            <div onClick={this._handleSearchClear}>
                                                <img className='upcoming-search-close'
                                                    draggable="false"
                                                    src={CloseImg}
                                                    alt='close-img'
                                                />
                                            </div>

                                            :
                                            <img className='upcoming-search-icon'
                                                draggable="false"
                                                src={SearchIcon}
                                                alt='search-icon' />
                                        }
                                        <span className="e-search-submit e-web-search-submit"
                                            onClick={this._handleSearchSubmit}>
                                        </span>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div>
                            <div className="row mt-5">
                                {
                                    this.state.listData ?
                                        this.state.listData.map((item, key) => {
                                            if (tempIndex > 9) {
                                                tempIndex = 1;
                                            }
                                            tempIndex = parseInt(tempIndex) + 1;

                                            var tempDelay = "." + tempIndex + "s";
                                            return (
                                                <React.Fragment key={key}>

                                                    {
                                                        this.state.tabIndex == 1 ?
                                                            <>
                                                                {this.state.dataLoading ?
                                                                    <div className="col-lg-3 
                                                                    col-md-3 col-sm-6 mb-4" >
                                                                        <CardPlaceholder />
                                                                    </div>
                                                                    :
                                                                    <div className="col-lg-4 
                                                                    col-md-4 col-sm-6 mb-4"
                                                                        key={key} >
                                                                        <WebinarCard
                                                                            type={1}
                                                                            key={this.state.tabIndex}
                                                                            data={item}
                                                                            onNavigation={() => this._handleChangeNavigation(1, item.id)} />
                                                                    </div>
                                                                }
                                                            </>

                                                            :
                                                            this.state.tabIndex == 2 ?
                                                                <>
                                                                    {this.state.dataLoading ?
                                                                        <div className="col-lg-3 
                                                                        col-md-3 col-sm-6 mb-4" >
                                                                            <CardPlaceholder />
                                                                        </div> :
                                                                        <div className="col-lg-3 
                                                                        col-md-3 col-sm-6 mb-4" >
                                                                            <LatestWebinarCard
                                                                                type={2}
                                                                                data={item}
                                                                                key={key}
                                                                                onNavigation={() => this._handleChangeNavigation(2, item.id)}
                                                                            />
                                                                        </div>}
                                                                </>

                                                                :

                                                                this.state.tabIndex == 3 ?
                                                                    <>
                                                                        {this.state.dataLoading ?
                                                                            <div className="col-lg-3 
                                                                            col-md-3 col-sm-6 mb-4" >
                                                                                <CardPlaceholder />
                                                                            </div> :
                                                                            <div className="col-lg-4
                                                                                col-md-4 col-sm-6 mb-4"
                                                                                key={key}  >
                                                                                <WebinarCard
                                                                                    type={3}
                                                                                    key={this.state.tabIndex}
                                                                                    data={item}
                                                                                    onNavigation={() => this._handleChangeNavigation(3, item.id)} />

                                                                            </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        null
                                }
                                {
                                    this.state.dataLoading ?
                                        <div className="col-lg-12 text-center">
                                            <div className="row">
                                                {
                                                    this.state.placeholder4.map((data, index) => {
                                                        return (
                                                            <div className="col-lg-3 col-md-6 col-6" key={index}>
                                                                <CardPlaceholder />
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    !this.state.dataLoading && !this.state.filterLoading && this.state.listData && !this.state.listData.length ?
                                        <Nothing data={this.state.tabIndex == 2 ? "No Completed Webinar Found " : this.state.tabIndex == 1 ? "No Upcoming Webinar Found " :
                                            this.state.tabIndex == 3 ? "No Live Webinar Found" : null} />
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <div className="row mt-5 pt-5">
                    {
                        this.state.pagination ?
                            <div className="col-lg-12 text-center">
                                <span className="e-s-btn"
                                    onClick={() => { this.loadMore(this.state.pageNum + 1) }}>
                                    Load more
                                </span>
                            </div>
                            :
                            null
                    }

                </div>

            </div>

        );
    }
}
export default withRouter(AllWebinarListing);