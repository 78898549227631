/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';
import { Modal } from 'bootstrap';

/*Components*/
import OpenAccountModal from '../../components/modals/open-account';
import CardPlaceholder from '../../components/placeholder/card';
import CourseCard from '../../components/cards/card';
import FourCardSlider from '../../components/slider/four-card-slider';
import FeatureSlider from '../../components/slider/feature-slider.js';
import HomeSlider from '../../components/slider/home-slider.js';
import PopUp from '../../components/popups/homePopUp';
import Input from '../../components/formElements/input';
import OfferModal from '../../components/modals/offer';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';

/*Assets*/
import CategoryPattern1 from '../../assets/images/home/category-pattern-1.svg';
import CategoryPattern2 from '../../assets/images/home/category-pattern-2.svg';

import Appstore from '../../assets/images/home/app-store.svg';
import Playstore from '../../assets/images/home/play-store1.svg';

import CtaBanner from '../../assets/images/home/cta-banner.png';
import MobileMask from '../../assets/images/home/mobile-mask.png'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            dataCourse: null,
            topSectionDataLoading: false,
            courseSectionDataLoading: false,
            placeholder4: [1, 2, 3, 4],
            placeholder6: [1, 2, 3, 4, 5, 6]
        }
    }

    componentDidMount() {
        // let modal = new Modal(document.getElementById('offerModal'),{});
        // modal.show();
        
        new WOW.WOW().init();
        $('html,body').animate({ scrollTop: 0 }, 'slow');

        this.getData();
        this.getCourseData();
        this.getPopUp();

        if (localStorage.getItem("tradeschool_access_token")) {
            this.getOpenData();
        }
        
    }

    getData() {
        this.setState({ 
            topSectionDataLoading: true 
        });
        var url = devconfig.user + "/course/home";
        var data = JSON.stringify({
            "language_ids": []
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({ 
                    data: response.data, 
                    topSectionDataLoading: false 
                });
                new WOW.WOW().init();
            }
            else {
                this.setState({ data: [] });
            }
        }.bind(this))
    }

    getCourseData() {
        this.setState({ 
            courseSectionDataLoading: true 
        });

        var url = devconfig.user + "/course/category-courses";
        var data = JSON.stringify({
            "page_num": 1
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({ 
                    dataCourse: response.data, 
                    courseSectionDataLoading: false 
                });
                new WOW.WOW().init();
            }
            else {
                this.setState({ data: [] });
            }
        }.bind(this));
    }

    responseGoogle(response) {
        if (!response.error) {
            this.apiSocialLogin(response.profileObj.name, response.profileObj.email, response.profileObj.imageUrl, response.googleId);
        } else {
            this.setState({ 
                triggerGoogleLogin: false,
                googleLoginLoading: false 
            });
        }
    }

    googleLoginClick() {
        this.setState({ 
            triggerGoogleLogin: true, 
            googleLoginLoading: true 
        });
        $(".e-google-login-wrap button").trigger("click");
    }

    apiSocialLogin(tempName, tempEmail, tempURL, tempID) {
        var url = devconfig.user + "/auth/login";
        var data = JSON.stringify({
            "email": tempEmail,
            "name": tempName,
            "social_user_id": tempID,
            "image": tempURL,
        });
        APIPost(url, data).then(function (response) {
            if (response.status) {
                localStorage.setItem("tradeschool_access_token", response.access_token);
                localStorage.setItem("userData", JSON.stringify(response.data));
                localStorage.setItem("tradeschool_token", true);
                window.location.reload();
            }
            else {
            }
        }.bind(this));
    }

    getOpenData() {
        this.setState({ 
            openDataLoading: true 
        });
        var url = devconfig.user + "/course/opened-courses";
        APIGet(url).then(function (response) {
            if (response.status) {
                this.setState({ 
                    openData: response.data, 
                    openDataLoading: false 
                });
                new WOW.WOW().init();
            } else {
                this.setState({ 
                    openData: null, 
                    openDataLoading: false 
                });
            }
        }.bind(this));
    }

    getPopUp() {
        var url = devconfig.user + "/notification/get-banner";
        APIGet(url).then(function (response) {
            console.log(response);
            if (response.status === "ok") {
                if (localStorage.getItem('popup_id') != response.data.id) {
                    localStorage.setItem('popup_id', response.data.id);
                    localStorage.setItem('popup_close', false)
                }
                this.setState({ 
                    notification: response.data 
                });
                localStorage.setItem('popup_id', response.data.id);
            }
        }.bind(this))
    }

    closePopUp = () => {
        this.setState({ close: true });
        localStorage.setItem('popup_close', true);
    }

    render() {
        var tempClass = "e-section pb-0";
        if (localStorage.getItem("tradeschool_access_token") && !this.state.openData) {
            tempClass = tempClass + " mt-5"
        }

        var tempLink1 = "/all-courses";
        var tempLink2 = "/all-courses";
        var tempLink3 = "/all-courses";
        var tempOfferLink = "/all-courses?offer=true";
        if (this.state.dataCourse) {
            if (this.state.dataCourse[0]) {
                tempLink1 = tempLink1 + "?id=" + this.state.dataCourse[0].category_id;
            }

            if (this.state.dataCourse[1]) {
                tempLink2 = tempLink2 + "?id=" + this.state.dataCourse[1].category_id;
            }

            if (this.state.dataCourse[2]) {
                tempLink3 = tempLink3 + "?id=" + this.state.dataCourse[2].category_id;
            }
        }

        return (
            <div className="e-home">
             
                {
                    // if the use has logged in, then checking for any courses which are started and not finished (Open)
                    // This section will cover the home slide and replace it with Course card.
                    localStorage.getItem("tradeschool_access_token") ?
                        <div>
                        {
                            this.state.openDataLoading ?
                                <section className="e-profile-open-course-wrap">
                                    <div className="container">
                                        <div className="row mb-4">
                                            {
                                                this.state.placeholder4.map((data, index) => {
                                                    return (
                                                        <div className="col-lg-3 col-md-6" key={index}>
                                                            <CardPlaceholder />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </section>
                            :
                                <div>
                                    {
                                        this.state.openData ?
                                            <section className="e-profile-open-course-wrap">
                                                <div className="container">
                                                    <div className="row mb-2">
                                                        <div className="col-lg-6">
                                                            <span className="e-head-secondary">Continue</span>
                                                            <h4>Your Open Courses</h4>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <FourCardSlider data={this.state.openData} />
                                                    </div>
                                                </div>
                                            </section>
                                        :
                                            null
                                    }
                                </div>
                        }
                        </div>
                    :
                        <>
                            <div className='row e-home-slider'>
                                <div className='col-lg-12'>
                                    <HomeSlider />
                                </div>
                            </div>
                        </>
                }

                <section className={tempClass}>
                <div className="container">
                    <div className="row mb-4">
                    <div className="col-lg-6 col-md-12">
                        <h4>Featured Courses</h4>
                    </div>
                    <div className="col-lg-12 col-md-12 e-feature-slider mb-3 mt-4">
                        <FeatureSlider />
                        {/* <Link className="e-view-all mt-3" to="/all-courses">View all</Link> */}
                    </div>
                    </div>

                    {
                    this.state.topSectionDataLoading ?
                        <div className="row">
                        {
                            this.state.placeholder4.map((data, index) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-6 mb-4" key={index}>
                                <CardPlaceholder />
                                </div>
                            );
                            })
                        }
                        </div>
                        :
                        <div>
                        {
                            this.state.data ?
                            <>
                                <div className="row mb-4">
                                {
                                    this.state.data.featured_courses.map((data, index) => {
                                    var tempDelay = "." + index + 2 + "s";
                                    var tempURL = "/course-details?id=" + data.id;
                                    return (
                                        <div className="col-lg-3 col-md-6" key={index}>
                                        <Link data-wow-delay={tempDelay} to={tempURL}>
                                            <CourseCard data={data} />
                                        </Link>
                                        </div>
                                    );
                                    })
                                }
                                </div>
                                <div className="row">
                                {
                                    this.state.data.featured_courses.map((data, index) => {
                                    var tempDelay = "." + index + 2 + "s";
                                    var tempURL = "/course-details?id=" + data.id;
                                    return (
                                        <div className="col-lg-3 col-md-6" key={index}>
                                        <Link data-wow-delay={tempDelay} to={tempURL}>
                                            <CourseCard data={data} />
                                        </Link>
                                        </div>
                                    );
                                    })
                                }
                                </div>
                                <div className='row  mt-4'>
                                <div className='col-lg-12 text-center'>
                                    <Link to="/all-courses">
                                    <span className="e-s-btn e-veiw-all-btn text-center mt-3">View all</span>
                                    </Link>
                                </div>
                                </div>
                            </>


                            :
                            null
                        }
                        </div>
                    }
                </div>
                </section>

                <section className="e-section">
                <div className="container">
                    <div className="row mb-4">
                    <div className="col-lg-6 col-md-8">
                        <h4>Courses with offers</h4>
                    </div>
                    <div className="col-lg-6 col-md-4 text-right">
                        <Link className="e-view-all mt-3" to={tempOfferLink}>View all</Link>
                    </div>
                    </div>

                    {
                    this.state.topSectionDataLoading ?
                        <div className="row">
                        {
                            this.state.placeholder4.map((data, index) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-6" key={index}>
                                <CardPlaceholder />
                                </div>
                            );
                            })
                        }
                        </div>
                        :
                        <div>
                        {
                            this.state.data ?
                            <div className="row">
                                {
                                this.state.data.course_with_offers.map((data, index) => {
                                    var tempDelay = "." + index + 2 + "s";
                                    var tempURL = "/course-details?id=" + data.id;
                                    return (
                                    <div className="col-lg-3 col-md-6" key={index}>
                                        <Link to={tempURL}>
                                        <CourseCard data={data} delay={tempDelay} />
                                        </Link>
                                    </div>
                                    );
                                })
                                }
                            </div>
                            :
                            null
                        }
                        </div>
                    }

                </div>
                </section>
                <section className="pb-5 mb-5">
                <div className="container">
                    <div className="row mb-4">
                    <div className="col-lg-6 col-md-8">
                        <h4>Webinar with offers</h4>
                    </div>
                    <div className="col-lg-6 col-md-4 text-right">
                        <Link className="e-view-all mt-3" to="/webinar">View all</Link>
                    </div>
                    </div>
                    {/* <div className='row'>
                    <div className="col-lg-3 col-md-6">
                        <Link to="/webinar">
                        <WebinarCard />
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <Link to="/webinar">
                        <WebinarCard />
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <Link to="/webinar">
                        <WebinarCard />
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <Link to="/webinar">
                        <WebinarCard />
                        </Link>
                    </div>
                    </div> */}
                </div>
                </section>

                <section className="e-category-section position-relative">
                <img className="e-pattern-1 wow fadeInLeft" data-wow-delay=".4s" src={CategoryPattern1} alt="Tradeschool courses categories" />
                <div className="container">
                    <div className="row">
                    <div className="col-lg-7 col-md-7">
                        <div className='e-section e-join-platform'>
                        <h5 className="text-light">Join our platform now to enjoy amazing offers</h5>
                        <p className="pb-4 pt-4">Download our mobile app for more personalised experience.</p>
                        <p className='e-availble-on'>Available on</p>

                        <Link to="/">
                            <img className="e-card-transition mr-4" src={Appstore} alt="Tradeschool app store" />
                        </Link>
                        <a className="" target="_blank" href="https://play.google.com/store/apps/details?id=com.tradeschool&hl=en_IN">
                            <img className="e-card-transition" src={Playstore} alt="Tradeschool play store" />
                        </a>

                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 text-right">
                        <img src={MobileMask} alt="" className='e-mob-mask' />
                    </div>
                    </div>
                </div>
                {/* <img className="e-pattern-2 wow fadeInRight" data-wow-delay=".4s" src={CategoryPattern2} alt="Tradeschool courses categories" /> */}
                </section>

                <section className="e-section">
                <div className="container">
                    <div className="row mb-4">
                    <div className="col-lg-6 col-md-8">
                        {/* <span className="e-head-secondary">Courses from</span> */}
                        {this.state.dataCourse && this.state.dataCourse[1] ? <h4>Courses from {this.state.dataCourse[1].category}</h4> : null}
                    </div>
                    <div className="col-lg-6 col-md-4 text-right">
                        <Link className="e-view-all mt-3" to={tempLink2}>View all</Link>
                    </div>
                    </div>

                    {
                    this.state.courseSectionDataLoading ?
                        <div className="row">
                        {
                            this.state.placeholder4.map((data, index) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-6" key={index}>
                                <CardPlaceholder />
                                </div>
                            );
                            })
                        }
                        </div>
                        :
                        <div>
                        {
                            this.state.dataCourse && this.state.dataCourse[1] ?
                            <div className="row">
                                {
                                this.state.dataCourse[1].courses.map((data, index) => {
                                    var tempDelay = "." + index + 2 + "s";
                                    var tempURL = "/course-details?id=" + data.id;
                                    return (
                                    <div className="col-lg-3 col-md-6" key={index}>
                                        <Link to={tempURL}>
                                        <CourseCard data={data} delay={tempDelay} />
                                        </Link>
                                    </div>
                                    );
                                })
                                }
                            </div>
                            :
                            null
                        }
                        </div>
                    }
                </div>
                </section>

                {
                    this.state.dataCourse && this.state.dataCourse[2] && this.state.dataCourse[2].courses.length ?
                        <section className="e-section e-green-bg">
                        <div className="container">
                            <div className="row mb-4">
                                <div className="col-lg-6 col-md-8">
                                    {this.state.dataCourse && this.state.dataCourse[2] ? <h4>Courses from {this.state.dataCourse[2].category}</h4> : null}
                                </div>

                                <div className="col-lg-6 col-md-4 text-right">
                                    <Link className="e-view-all mt-3" 
                                        to={tempLink3}>View all</Link>
                                </div>
                            </div>
                            
                            {
                                this.state.courseSectionDataLoading ?
                                    <div className="row">
                                        {
                                            this.state.placeholder4.map((data, index) => {
                                                return (
                                                    <div className="col-lg-3 col-md-6 col-6" key={index}>
                                                        <CardPlaceholder />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                :
                                    <div>
                                        {
                                            this.state.dataCourse && this.state.dataCourse[2] ?
                                                <div className="row">
                                                    {
                                                        this.state.dataCourse[2].courses.map((data, index) => {
                                                            var tempDelay = "." + index + 2 + "s";
                                                            var tempURL = "/course-details?id=" + data.id;
                                                            return (
                                                                <div className="col-lg-3 col-md-6" key={index}>
                                                                    <Link to={tempURL}>
                                                                        <CourseCard data={data} 
                                                                            delay={tempDelay} />
                                                                    </Link>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            :
                                                null
                                        }
                                    </div>
                            }

                        </div>
                        </section>
                    :
                        null
                }

                {/* Subscribe section */}
                <section className='e-cta-sections position-relative'>
                    <div className='container-fluid'>
                        <div className='row'>
                        <div className='col-lg-6'>
                            <img src={CtaBanner} alt="Trade school trading" 
                                className='e-cta-banners' />
                        </div>

                        <div className='col-lg-5 offset-lg-0 offset-md-2 col-md-8'>
                            <div className='e-cta-content-wrapper'>
                                <h5 className="">Get notified instantly</h5>
                                <p className="py-4">Subscribe our newsletter to get instant updates regrading our courses and offers</p>
                                <div className='row'>
                                    {/* email input */}
                                    <div className='col-lg-7'>
                                        <Input placeholder="Enter your mail ID" 
                                            type="email" 
                                            value={this.state.email} 
                                            valueChange={(tempVal) => this.setState({ email: tempVal })} />
                                    </div>
                                    
                                    {/* Subscribe button */}
                                    <div className='col-lg-4 col-sm-5 col-6'>
                                    <span className="e-p-btn e-header-login text-center">Subscribe now</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

                {/* Modals */}
                <OfferModal/>
                <OpenAccountModal />
                {
                    this.state.notification && !JSON.parse(localStorage.getItem('popup_close')) ?
                        <PopUp data={this.state.notification} close={() => { this.closePopUp() }} />
                    :
                        null
                }

            </div>
        );
  }
}

export default Home;
