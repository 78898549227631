/*Import Packages*/
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import $ from 'jquery';

/*Import pages*/
import Home from './container/home/main.js';
import AllCourseListing from './container/all-courses/listing.js';
import AllWebinarListing from './container/webinar/listing.js';
import Webinar from './container/webinar/main.js';
import Search from './container/all-courses/search.js';
import Profile from './container/profile/main.js';
import WalletHistory from './container/profile/walletHistory.js';
import Tutor from './container/tutor/main.js';
import CourseDetail from './container/course-detail/main.js';
import WebinarDetail from './container/webinar-detail/main.js';
import Terms from './container/other/terms.js';
import Privacy from './container/other/privacy.js';
import Callback from './container/other/callback.js';
import FavWebinar from './container/profile/webinar';
import NotFound from './container/404.js';
import Disclaimer from './container/other/disclaimer.js';

/*Import global components like header, footers*/
import HeaderOne from './components/navigation/header.js';
import Footer from './components/navigation/footer.js';
import JoinTutorModal from './components/modals/join-tutor';
import OpenAccountModal from './components/modals/open-account';
import MobSearch from './components/modals/search';
import LoginModal from './components/modals/login';




class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getItem: (localStorage.getItem("tradeschool_access_token"))
        }
    }

    componentDidMount() {
        
        $(document).keydown(function (e) {
            if (e.keyCode == 18) {
                e.preventDefault();
                return false;
            }
            if (e.keyCode == 123) {
                e.preventDefault();
            }
            if (e.keyCode == 18 && e.keyCode == 91 && e.keyCode == 0) {
                e.preventDefault();
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                e.preventDefault();
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
                e.preventDefault();
            }
            if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                e.preventDefault();
            }
            if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                e.preventDefault();
            }
        });

    }

    render() {
        return (
            <>
                {
                    this.state.getItem ?
                        <BrowserRouter>
                            <HeaderOne />
                            <Switch>
                                <Route path="/" exact component={AllWebinarListing} />
                                <Route path="/home" exact component={AllWebinarListing} />
                                {/* <Route path="/all-courses" component={AllCourseListing} /> */}
                                <Route path="/all-webinars" component={AllWebinarListing} />
                                <Route path="/webinar" component={AllWebinarListing} />
                                <Route path="/profile" component={Profile} />
                                {/* <Route path="/wallet-history" component={WalletHistory} /> */}
                                <Route path="/tutor" component={Tutor} />
                                {/* <Route path="/course-details" component={CourseDetail} /> */}
                                <Route path="/webinar-details" component={WebinarDetail} />
                                <Route path="/search" component={Search} />
                                {/* <Route path="/terms-conditions" component={Terms} /> */}
                                <Route path="/privacy-policy" component={Privacy} />
                                <Route path="/disclaimer" component={Disclaimer}/>
                                <Route path="/callback" component={Callback} />
                                <Route path="/saved-webinar" component={FavWebinar} />
                                <Route path='*' component={NotFound} />
                            </Switch>

                            {/* <Redirect from='*' to='/404' /> */}

                            <JoinTutorModal />
                            {/* <OpenAccountModal /> */}
                            <MobSearch />
                            <LoginModal />
                            <Footer />
                        </BrowserRouter>
                        :
                        <BrowserRouter>
                            <HeaderOne />
                            <Switch>
                                <Route path="/" exact component={AllWebinarListing} />
                                <Route path="/home" exact component={AllWebinarListing} />
                                {/* <Route path="/all-courses" component={AllCourseListing} /> */}
                                <Route path="/all-webinars" component={AllWebinarListing} />
                                <Route path="/webinar" component={AllWebinarListing} />
                                <Route path="/profile" component={AllWebinarListing} />
                                {/* <Route path="/profile" component={Home} /> */}
                                {/* <Route path="/wallet-history" component={WalletHistory} /> */}
                                <Route path="/tutor" component={Tutor} />
                                {/* <Route path="/course-details" component={CourseDetail} /> */}
                                <Route path="/webinar-details" component={WebinarDetail} />
                                <Route path="/search" component={Search} />
                                {/* <Route path="/terms-conditions" component={Terms} /> */}
                                <Route path="/privacy-policy" component={Privacy} />
                                <Route path="/disclaimer" component={Disclaimer}/>
                                <Route path="/callback" component={Callback} />
                                <Route path="/saved-webinar" component={FavWebinar} />
                                <Route path='/404' component={NotFound} />
                                <Route path='*' component={NotFound} />
                            </Switch>
                            {/* <Redirect from='*' to='/404' /> */}
                            <JoinTutorModal />
                            {/* <OpenAccountModal /> */}
                            <MobSearch />
                            <LoginModal />
                            <Footer />
                        </BrowserRouter>
                }
            </>
        )

    }
}

export default App;