import React from 'react';
import $ from 'jquery'; 

// import components

// import assets
import closebtn from '../../assets/images/close.png';

/*Import Services*/
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import devconfig from '../../config/config.js';


export default class HomePopUp extends React.Component{
	constructor(props){
		super(props);
		this.state={

		};
	}

	componentDidMount(){
	}


  close=()=>{
  	this.props.close();
  }


	render(){
		return(
				<React.Fragment>
				{
					this.props.data ?
					<div className="card border-0 e-notification-popup ">
						<button className="e-login-closebtn e-popup-close text-right" onClick={this.close}><img src={closebtn} alt="close button" className="e-toggle"/></button>
						{
							this.props.data.redirection_url ?
							<a href={this.props.data.redirection_url} target="_blank">
								<img src={this.props.data.image} className="e-popup-img"/>
							</a>
							:
							<img src={this.props.data.image} className="e-popup-img"/>
						}
 					</div>
 					:
 					null
 				}
 				</React.Fragment>

		)
	}
}