/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pattern1 from '../../assets/images/home/banner-pattern-1.svg';
import pattern11 from '../../assets/images/home/banner-pattern-11.svg';
import pattern2 from '../../assets/images/home/banner-pattern-2.svg';
import pattern3 from '../../assets/images/home/banner-pattern-3.svg';
import pattern4 from '../../assets/images/home/banner-pattern-4.svg';
import banner from '../../assets/images/home/home-banner.png';
import banner3 from '../../assets/images/home/banner3.png';
import banner4 from '../../assets/images/home/banner4.png';
import rarrow from '../../assets/images/icons/arrow-next.svg';

/*Components*/
import CourseCard from '../cards/card';


// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block",backgroundImage:`url(${rarrow})`, backgroundPosition: "50% 50%",right: "20px", borderRadius: "50px", width: "24px", height: "25px" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }


class HomeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }




  render() {
    var settings = {
     
      dots: true,
      arrows: true,
      infinite: false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            autoplay: true,
            dots: false,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            autoplay: true,
            dots: false,
          }
        }
      ]
    };

    return (
      <div>
        <Slider {...settings}>
          <section className="e-banner-section e-banner-slider position-relative">
            <img className="e-banner-pattern-1 wow fadeIn" data-wow-duration="4s" src={pattern1} alt="Tradeschool courses listing" />
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-7">
                  <h1>Learn Stock Market the Easy Way.</h1>
                  <h2 className="pb-4">Get Investing & Trading Courses from Industry Experts.</h2>
                  <span className="e-p-btn e-login-with-gmail" data-toggle="modal" data-target="#loginModal" >Login Now</span>
                  <span className="e-google-login-wrap">
                    {/* <GoogleLogin clientId={devconfig.googleClientId} buttonText="LOGIN WITH GOOGLE" onSuccess={this.responseGoogle.bind(this)} onFailure={this.responseGoogle.bind(this)} /> */}
                  </span>
                </div>
              </div>
            </div>
            <img className="e-banner-pattern-2 wow fadeIn" data-wow-duration="1s" src={pattern2} alt="Tradeschool courses listing" />
            <img className="e-banner-pattern-3 wow fadeIn" data-wow-duration="3s" src={pattern3} alt="Tradeschool courses listing" />
            <img className="e-home-banner" src={banner} alt="best online stock trading courses listing platform" />
          </section>

          <section className="e-banner-section e-banner-section-webinar e-banner-slider position-relative">
            <img className="e-banner-pattern-1 wow fadeIn" data-wow-duration="4s" src={pattern11} alt="Tradeschool courses listing" />
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-6">
                  <h1>Watch & Learn from Experts.</h1>
                  <h2 className="pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Avitae, quis arcu donec risus vestibulum.</h2>
                  <span className="e-p-btn e-login-with-gmail1" data-toggle="modal" data-target="#loginModal" >Login Now</span>
                  <span className="e-google-login-wrap">
                    {/* <GoogleLogin clientId={devconfig.googleClientId} buttonText="LOGIN WITH GOOGLE" onSuccess={this.responseGoogle.bind(this)} onFailure={this.responseGoogle.bind(this)} /> */}
                  </span>
                </div>
              </div>
            </div>
           
            <img className="e-home-banner-pattern3" src={banner3} alt="best online stock trading courses listing platform"/>
          </section>

          <section className="e-banner-section e-banner-section3 e-banner-slider position-relative">
            <img className="e-banner-pattern-1 wow fadeIn" data-wow-duration="4s" src={pattern11} alt="Tradeschool courses listing" />
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-6">
                  <h1>Watch & Learn from Experts.</h1>
                  <h2 className="pb-4">Get Investing & Trading Courses from Industry Experts.</h2>
                  <span className="e-p-btn e-login-with-gmail1" data-toggle="modal" data-target="#loginModal" >Login Now</span>
                  <span className="e-google-login-wrap">
                    {/* <GoogleLogin clientId={devconfig.googleClientId} buttonText="LOGIN WITH GOOGLE" onSuccess={this.responseGoogle.bind(this)} onFailure={this.responseGoogle.bind(this)} /> */}
                  </span>
                </div>
              </div>
            </div>
            {/* <img className="e-banner-pattern-2 wow fadeIn" data-wow-duration="1s" src={pattern2} alt="Tradeschool courses listing" />
            <img className="e-banner-pattern-3 wow fadeIn" data-wow-duration="3s" src={pattern3} alt="Tradeschool courses listing" /> */}
            <img className="e-home-banner-pattern4" src={banner4} alt="best online stock trading courses listing platform" />
          </section>


        </Slider>
      </div>
    );


  }
}

export default HomeSlider;
