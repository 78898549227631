import React from 'react';
import { Link } from 'react-router-dom';

/*import assets*/
import NotFoundPage from '../assets/images/404.png';

export default class NotFound extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  // componentWillMount() {

  // }
  render() {

    return (
      <React.Fragment>
        {/* <div className="container"> */}
          <div className='row'>
            <div className='col-lg-12 e-page-not-found text-center pb-0'>
              <img src={NotFoundPage} alt="" className='' />
              <h6 className='pt-5'>Oops! something went wrong.</h6>
              <p className='pt-3'>Something went wrong. Please try sometime later, we will into the cause.</p>
            </div>
          </div>
        {/* </div> */}

      </React.Fragment>
    )
  }
}
