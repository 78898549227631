/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/

/*Services*/




/*Assets*/

import PlayBtn from '../../assets/images/webinar/play-btn.svg';
import Img1 from '../../assets/images/webinar/live1.svg';

class LiveWebinarCard extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    new WOW.WOW().init();
    // $('html,body').animate({ scrollTop: 0 }, 'slow');
    console.log(this.props.data);
  }
  routeChange(temp) {
    // if(localStorage.getItem("tradeschool_token")){
    //   window.location.href="/webinar-details?id="+temp;
    // }
    // else{
    //     $('#loginModal').modal('toggle');
    //   }

    this.props.onNavigation(this.props.type, this.props.data.id)
  }

  render() {
    return (
      <React.Fragment>
        {
          this.props.data ?
            <div className="card e-course-card mb-4 e-card-transition card3 wow fadeInUp e-live-webinar-card e-live-overlay position-relative e-live" data-wow-delay="" onClick={() => { this.routeChange(this.props.data.id) }}>

              <img className="card-img-top e-course-cover" src={this.props.data.thumbnail} alt="" />
              <span class="e-course-header e-ellipse ">7 {this.props.data.name}</span>
              <span className="e-course-live">Live</span>
              <span className="e-course-presented">Presented by</span>

              <span className="e-course-by">{this.props.data.creator}</span>


            </div>
            :
            null
        }
      </React.Fragment>

    );
  }
}

export default LiveWebinarCard;
// <span className="e-course-author">iii</span>
