/*Package importing*/
import React, { Component } from 'react';
import Select from 'react-select';

/*Import custom components and assets*/

class SelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false
    }
  }


  componentDidMount() {
    this.setState({ selectedValue: this.props.defaultValue });
    if (this.props.search) {
      this.setState({ search: true });
    }
  }

  selectChange = selectedValue => {
    this.setState({ selectedValue });
    this.props.selectchange(selectedValue)

  };



  render() {
    const { selectedValue } = this.state;
    return (
      <div className={this.props.className}>
        <Select
          isSearchable={this.state.search}
          options={this.props.options}
          value={selectedValue}
          onChange={this.selectChange}
          isMulti={this.props.selectType}
          placeholder={this.props.placeholder}
          defaultValue={this.props.defaultValue}
        // menuIsOpen={true}
        />
      </div>


    );
  }
}

export default SelectBox;
