import React from 'react';

// import services
import APIPost from '../../services/apiCall/post';
import devconfig from '../../config/config.js';
import GetURLVar from '../../services/getParams/get-url.js';

// import assets
import facebook from '../../assets/images/social/facebook.svg';
import twitter from '../../assets/images/social/twitter.svg';
import linkedin from '../../assets/images/social/linkdin.svg';
import whatsapp from '../../assets/images/social/whatapp.svg';
import LinkIcon from "../../assets/images/social/link.png";
import Toast from '../popups/toast';

export default class ReferEarn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ABid: this.props.partner_id ? this.props.partner_id : null,
			generated: false,
			refcode: null,
			prname: null,
			clear: false,
			linkcopy: false,
			link: ""
		}
	}
	componentDidMount() {
		GetURLVar().then(function (query) {
			this.setState({ id: query.id });
			this.setState({ prname: query.product })
		}.bind(this));

		this.refferal();

		console.log("web name::", this.props.webinarName);
	}


	_copyNavigationURL = () => {

		const currentURL = window.location.href;
		navigator.clipboard.writeText(currentURL);

		this.setState({
			toast: "Copied",
			toastType: "success"
		});
		setTimeout(function () {
			this.setState({
				toast: null
			});
		}.bind(this), 3000);

	}

	refferal = () => {
		this.setState({ generated: false });
		this.setState({ clear: true });
		var base_url = devconfig.user;
        let partner_token = localStorage.getItem("partner_token") ;
        // # checking if partner 
        if(partner_token==="true"){
            base_url = devconfig.partnerV1;
        }
		var url = base_url + "/webinar/referralcode";
		var data = JSON.stringify({
			"id": Number(this.props.productId),
			"alice_blue_id": this.state.ABid
		})

		APIPost(url, data).then(function (response) {
			if (response.status === "ok") {
			}
			else {
				this.setState({ dataLoading: false });
			}
			this.setState({ refcode: response.referral_code });
			localStorage.setItem("ts_ref", response.referral_code);
			localStorage.setItem("ts_id", Number(this.props.productId));
			this.generate(this.state.refcode);
		}.bind(this));
	}

	generate = (tempId) => {
		if (devconfig.isDev == true) {
			var copylink = 'http://evo-tradeschool-dev.netlify.app/webinar-details?ts_refcode=' + tempId + '&id=' + this.props.productId
			var temoLink = encodeURIComponent('http://evo-tradeschool-dev.netlify.app/webinar-details?ts_refcode=' + tempId + '&id=' + this.props.productId)
		} else {
			var copylink = 'http://tradeschool.aliceblueonline.com/webinar-details?ts_refcode=' + tempId + '&id=' + this.props.productId
			var temoLink = encodeURIComponent('http://tradeschool.aliceblueonline.com/webinar-details?ts_refcode=' + tempId + '&id=' + this.props.productId)
		}

		this.setState({
			link: temoLink,
			clink: copylink,
			generated: true,
		});
	}

	clear = () => {
		this.setState({
			generated: false,
			ABid: "",
			link: ""
		});
	}

	renderLink = () => {
		if (this.state.generated)
			return <h5 className="e-generated-head pl-3">Referral url</h5>
	}
	renderButton = () => {
		if (this.state.generated) {
			const REFERRAL_MESSAGE = `Hey, I would recommend you to register for this exclusive Tradeschool Webinar ${this.props.webinarName} which gives you valuable Knowledge and Learnings. Join through my referral Link. `;
			return (
				<div className="row e-social-icon mt-4 mx-1">
					<div className="col-lg-12">

						{/* facebook */}
						<a className="mr-5 e-ftricon-link mt-1"
							href={"https://www.facebook.com/sharer/sharer.php?u=" + this.state.link} target="blank">
							<img draggable="false" src={facebook} alt="icon" className="e-footer-icon" />
						</a>

						{/* twitter */}
						<a className="mr-5 mt-1 e-ftricon-link"
							href={"https://twitter.com/intent/tweet?url=" + REFERRAL_MESSAGE + this.state.link} target="blank">
							<img draggable="false" src={twitter} alt="icon" className="e-footer-icon" />
						</a>

						{/* linkedin */}
						<a className="mr-5 mt-1 e-ftricon-link"
							href={"http://www.linkedin.com/shareArticle?mini=true&url=" + this.state.link} target="blank">
							<img draggable="false" src={linkedin} alt="icon" className="e-footer-icon" />
						</a>

						{/* whatsapp */}
						<a className="mr-5 e-ftricon-link"
							href={"https://api.whatsapp.com/send?text=" + REFERRAL_MESSAGE + this.state.link} target="blank">
							<img draggable="false" src={whatsapp} alt="icon" className="e-footer-icon" />
						</a>

						{/* copy */}
						<a>
							<img draggable="false"
								className='e-social-icon-img'
								src={LinkIcon}
								alt='link' onClick={() => {
									navigator.clipboard.writeText(this.state.clink);
									this.setState({
										toast: "Copied",
										toastType: "success"
									});
									setTimeout(function () {
										this.setState({
											toast: null
										});
									}.bind(this), 3000);
								}} />
						</a>
					</div>
				</div>
			)
		}
	}
	render() {
		return (
			<div className="card border-0 e-refer-card mt-4">
				<div className='e-ref-and-earn pb-0'>
					<h5 className="e-prdtdtls-smallcase-h5">Refer & Learn</h5>
					<p className="e-earn-p">
						Start referring this webinar to your family and friends and get access to free courses today
					</p>
				</div>
				{/* <div className='e-generate-link'>
					<h4>Generate link</h4>
					<div className="position-relative e-refer-input-wrap d-flex ">
						<input type="text" className="e-text border-0 " placeholder="Enter Aliceblue Client ID*" readOnly={true} value={this.state.ABid} onChange={(e) => this.setState({ ABid: e.target.value })} />
						<div className="text-left" style={{ color: "red" }}>{this.state.nameError}</div>
						{
							!this.state.generated ?
								<React.Fragment>
									{
										this.state.ABid ?
											<span className='e-id-arrow ml-3' onClick={this.refferal}>
												<img draggable="false"
													src={RightArrow} alt="" />
											</span>

											:
											<span className='e-id-arrow ml-3' onClick={this.refferal}>
												<img draggable="false"
													src={RightArrow} alt="" />
											</span>
									}
								</React.Fragment>
								:null
								// <button className=" e-p-btn" onClick={this.clear}>clear</button>
						}

					</div>
				</div> */}
				{this.renderLink()}
				{
					this.state.link.length ?
						<p className="e-earn-link pl-3 mb-0" id="earn-link">{this.state.clink}</p>
						:
						null
				}

				{this.renderButton()}

				{
					this.state.linkcopy ?
						<span className="e-link-copied e-link-copied-s">Link copied</span>
						:
						null
				}
				{
					this.state.toast ?
						<Toast data={this.state.toast} type={this.state.toastType} />
						:
						null
				}
			</div>
		)
	}

}