/*Package importing*/
import React, { Component } from 'react';

/*import assets */
import Offer from '../../assets/images/home/offer-header.png';
import Close from '../../assets/images/modals/close.svg';



class OfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }



  render() {

    return (
      <div className="modal fade e-modal-wrap e-offer-modal " id="offerModal" tabIndex="-1" role="dialog" aria-labelledby="offerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content p-0">
            <div className="modal-header p-0">
              <img src={Offer} alt="" />
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                {/* <span aria-hidden="true">&times;</span> */}
                <img src={Close} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-9'>
                    <h3 className='pt-2'>Checkout the offers on the paid course</h3>
                  </div>
                  <div className='col-lg-12 py-3'>
                    <p>Up to 30% offer available on paid courses check the offers now, buy courses you want before the offers expires.</p>
                  </div>
                  <div className='mt-3 px-3 mb-3'>

                    <span className="e-p-btn e-login-with-gmail" data-dismiss="modal">View course</span>

                  </div>
                </div>
              </div>


            </div>
            <div className="modal-footer">

            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default OfferModal;
