// const dev = "http://localhost:5000/api/v1"
// const devV2 = "http://localhost:5000/api/v2"

// // dev url
// const dev = "https://j4b1yj8zg7.execute-api.ap-south-1.amazonaws.com/dev/api/v1";
// const devV2 = "https://j4b1yj8zg7.execute-api.ap-south-1.amazonaws.com/dev/api/v2"
// // staging url
// const staging = "https://8bugtbiaji.execute-api.ap-south-1.amazonaws.com/staging/api/v1";

// prod url
const prod = "https://k2p9t0nl00.execute-api.ap-south-1.amazonaws.com/prod/api/v1";
const prodV2 = "https://k2p9t0nl00.execute-api.ap-south-1.amazonaws.com/prod/api/v2";

var devconfig = {

	url: prod,
	user: prod + "/user",
	userV2 : prodV2 + "/user",
	creator: prod + "/creator",
	url: prod,
	partnerV1: prod + "/partner",

	aliceBlueBaseURL: "https://ant.aliceblueonline.com/",
	aliceBlueAuthURL: "https://ant.aliceblueonline.com/",
	clientID: "TRADESCHOOLLIVE",
	redirectURI: "https://tradeschool.aliceblueonline.com/callback",
	appCode: "ZMgauigPAcSbefD",
	googleClientId: "24162734618-7c9rrco0k2ptebsc7lpphsn2blglps4f.apps.googleusercontent.com",
	isDev: false
}


// #TRADESCHOOLDEV	KKWfxXAIZqBHJrD		NA	https://evo-tradeschool-dev.netlify.app/callback
// #TRADESCHOOLLIVE ZMgauigPAcSbefD	NA	https://tradeschool.aliceblueonline.com/callback


// aliceBlueBaseURL: "https://ant.aliceblueonline.com/",
// aliceBlueAuthURL: "https://ant.aliceblueonline.com/oauth2/",
// clientID: "TRADESCHOOLDEV",
// redirectURI: "https://evoque-tradeschool-user.netlify.app/callback",
// responseType: "code",
// state: "dev_state",
// authString: "Basic VFJBREVTQ0hPT0xERVY6YUljWFZMb0F5NGs5cEp5Y0lweEU1M2Z2S2hCNFhFdGJ3ck1aa1BLSWlhYVZvMFZzTjZhYjVEaUptV2ZxaFQ1dA",
// googleClientId: "24162734618-7c9rrco0k2ptebsc7lpphsn2blglps4f.apps.googleusercontent.com",
	// aliceBlueBaseURL: "https://ant.aliceblueonline.com/",
	// aliceBlueAuthURL: "https://ant.aliceblueonline.com/oauth2/",
	// clientID: "TRADESCHOOLDEV",
	// redirectURI: "https://evoque-tradeschool-user.netlify.app/callback",
	// responseType: "code",
	// state: "dev_state",
	// authString: "Basic VFJBREVTQ0hPT0xERVY6YUljWFZMb0F5NGs5cEp5Y0lweEU1M2Z2S2hCNFhFdGJ3ck1aa1BLSWlhYVZvMFZzTjZhYjVEaUptV2ZxaFQ1dA",
	// googleClientId: "24162734618-7c9rrco0k2ptebsc7lpphsn2blglps4f.apps.googleusercontent.com",


// url: prod,
// user: prod + "/user",
// userV2 : prodV2 + "/user",
// creator: prod + "/creator",
// url: prod,
// googleClientId: "24162734618-7c9rrco0k2ptebsc7lpphsn2blglps4f.apps.googleusercontent.com",

// url: dev,
// user: dev + "/user",
// userV2 : devV2 + "/user",
// creator: dev + "/creator",
// url: dev,
// googleClientId: "24162734618-7c9rrco0k2ptebsc7lpphsn2blglps4f.apps.googleusercontent.com",
export default devconfig;