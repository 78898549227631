/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Components*/
import WebinarBanner from '../../components/banner/webinar-banner.js';
import AllCategory from '../../components/webinarCategory/allWebinars.js';
import LiveWebinarCard from '../../components/cards/liveWebinar.js';
import UpcomingWebinarCard from '../../components/cards/upcomingWebinar.js';
import LatestWebinarCard from '../../components/cards/latestWebinar.js';
import CardPlaceholder from '../../components/placeholder/card';


/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';



/*Assets*/
import Appstore from '../../assets/images/home/app-store.svg';
import Playstore from '../../assets/images/home/play-store.svg';
import CTABg from '../../assets/images/home/cta-bg.svg';

// import  Lazyload from '../../assets/images/loader/lazyload.gif';

import CTAPattern1 from '../../assets/images/home/cta-pattern-1.svg';
import CTAPattern2 from '../../assets/images/home/cta-pattern-2.svg';
import CTAPattern3 from '../../assets/images/home/cta-pattern-3.svg';
import CTAPattern4 from '../../assets/images/home/cta-pattern-4.svg';

class Webinar extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            lang: null,
            liveLoading: true,
            upcomingLoading: true,
            latestLoading: true,
            placeholder4: [1, 2, 3, 4],
        }
    }

    componentDidMount() {
        this.myRef.current.scrollTo(0, 0);
        this.getLan();
    }
    getLan = () => {
        var tempD = "";
        var url = devconfig.user + "/language/get-all";

        APIGet(url).then(function (response) {
            if (response.status) {
                response.data.map((item, key) => {
                    return (
                        item.name == "English" ?
                            tempD = item.id
                            :
                            null
                    )
                })
                this.setState({ lang: tempD });
                this.getLive()
                this.getUpcoming();
                this.getPopular();
            }
        }.bind(this))

    }


    getLive = () => {
        this.setState({ liveLoading: true });
        var temp = [];
        temp.push(this.state.lang);
        var url = devconfig.userV2 + "/home/live-now";
        var data = JSON.stringify({
            "language_ids": temp,
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({ live: response.data, liveLoading: false });
            }
            else {
                this.setState({ live: null, liveLoading: false });
            }
        }.bind(this))

    }

    getUpcoming = () => {
        this.setState({ upcomingLoading: true });
        var temp = [];
        temp.push(this.state.lang);
        var url = devconfig.userV2 + "/home/upcoming-webinars";
        var data = JSON.stringify({
            "language_ids": temp,
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({ upcoming: response.data, upcomingLoading: false });
                console.log(this.state.upcoming)
                // new WOW.WOW().init();
            }
            else {
                this.setState({ upcoming: null, upcomingLoading: false });
            }
        }.bind(this))

    }

    getPopular = () => {
        this.setState({ latestLoading: true });
        var tempArry = [];
        var url = devconfig.userV2 + "/webinar/latest-webinars";
        var data = JSON.stringify({
            "page_num": 1,
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                response.data.slice(0, 4).map((item, key) => {
                    return (
                        tempArry.push(item)
                    )
                })
                this.setState({ latest: tempArry, latestLoading: false });
            }

            else {
                this.setState({ latest: null, upcomingLoading: false });
            }
        }.bind(this))

    }

    render() {
        return (
            <div className="e-home" ref={this.myRef}>
                <React.Fragment>
                    {
                        !localStorage.getItem("tradeschool_token") ?
                            <WebinarBanner />
                            :
                            null
                    }
                </React.Fragment>
                
                <React.Fragment>

                    {
                        this.state.liveLoading ?
                            <div className="container e-live-padding-top">
                                <div className="col-lg-12 text-center">
                                    <div className="row">
                                        {
                                            this.state.placeholder4.map((data, index) => {
                                                return (
                                                    <div className="col-lg-3 col-md-6 col-6 " key={index}>
                                                        <CardPlaceholder />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        this.state.live && this.state.live.length ?
                            <section className="e-section e-grey-bg">
                                <div className="container">
                                    <div className="row mb-4">
                                        <div className="col-lg-12 col-md-10">
                                            <h4 className="pb-2">Live Now</h4>
                                        </div>

                                    </div>
                                    <div className="row">

                                        {
                                            this.state.live && this.state.live.map((item, key) => {
                                                return (
                                                    <div className="col-lg-4 col-md-6" key={key}>
                                                        <LiveWebinarCard data={item} />
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </section>
                            :
                            null
                    }
                </React.Fragment>

                <React.Fragment>

                    {
                        this.state.latestLoading ?
                            <div className="container e-section ">
                                <div className="col-lg-12 text-center ">
                                    <div className="row">
                                        {
                                            this.state.placeholder4.map((data, index) => {
                                                return (
                                                    <div className="col-lg-3 col-md-6 col-6" key={index}>
                                                        <CardPlaceholder />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        this.state.latest && this.state.latest.length ?
                            <section className="e-section">
                                <div className="container">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 col-md-8 col-sm-6">
                                            <h4 className="pb-2">Latest Webinars</h4>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-6 text-right ">
                                            <Link className="e-view-all mt-3" to="/all-webinars">View all Webinars</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {
                                                this.state.latestLoading ?
                                                    <div className="row">
                                                        {
                                                            this.state.placeholder4.map((data, index) => {
                                                                return (
                                                                    <div className="col-lg-3 col-md-6 col-6" key={index}>
                                                                        <CardPlaceholder />
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            this.state.latest && this.state.latest.map((item, key) => {
                                                return (
                                                    <div className="col-lg-3 col-md-4" key={key}>
                                                        <LatestWebinarCard data={item} reload={this.getPopular} />
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </section>
                            :
                            null
                    }
                </React.Fragment>
                <AllCategory />
                <React.Fragment>

                    {
                        this.state.upcomingLoading ?
                            <div className="container e-section">
                                <div className="col-lg-12 text-center">
                                    <div className="row">
                                        {
                                            this.state.placeholder4.map((data, index) => {
                                                return (
                                                    <div className="col-lg-3 col-md-6 col-6" key={index}>
                                                        <CardPlaceholder />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        this.state.upcoming && this.state.upcoming.length ?
                            <section className="e-section">
                                <div className="container">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 col-md-8 col-sm-8 col-7">
                                            <h4 className="pb-2">Upcoming Webinars</h4>
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-4 col-5 text-right">
                                            <Link className="e-view-all mt-3" to="/all-webinars">View all</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            {
                                                this.state.upcomingLoading ?
                                                    <div className="row">
                                                        {
                                                            this.state.placeholder4.map((data, index) => {
                                                                return (
                                                                    <div className="col-lg-3 col-md-6 col-6" key={index}>
                                                                        <CardPlaceholder />
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            this.state.upcoming && this.state.upcoming.map((item, key) => {
                                                return (
                                                    <div className="col-lg-4 col-md-6" key={key}>
                                                        <UpcomingWebinarCard data={item} reload={this.getUpcoming} />
                                                    </div>
                                                )
                                            })

                                        }

                                    </div>
                                </div>
                            </section>
                            :
                            null
                    }
                </React.Fragment>
                <section className="e-section e-cta-section position-relative">
                    <img className="e-pattern-1 wow fadeInLeft"
                        data-wow-delay=".1s"
                        src={CTAPattern1}
                        alt="Tradeschool trading course listing" />
                    <img className="e-pattern-2 wow fadeInLeft"
                        data-wow-delay=".3s"
                        src={CTAPattern2}
                        alt="Tradeschool trading course listing" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h5 className="text-white">Get our mobile app</h5>
                                <p className="pb-4">Download our mobile app for more personalised experience.</p>
                                <p>Available on</p>

                                <a className="mr-4" target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.tradeschool&hl=en_IN">
                                    <img className="e-card-transition"
                                        src={Playstore}
                                        alt="Tradeschool play store" />
                                </a>
                                <Link to="/">
                                    <img className="e-card-transition" src={Appstore} alt="Tradeschool app store" />
                                </Link>
                            </div>
                            <div className="col-lg-6 text-right">
                                <img className="e-cta-banner" src={CTABg} alt="Tradeschool trading course listing" />
                            </div>
                        </div>
                    </div>
                    <img className="e-pattern-3 wow fadeInRight"
                        data-wow-delay=".1s" src={CTAPattern3}
                        alt="Tradeschool trading course listing" />
                    <img className="e-pattern-4 wow fadeInRight"
                        data-wow-delay=".3s" src={CTAPattern4}
                        alt="Tradeschool trading course listing" />
                </section>
            </div>


        );
    }
}

export default Webinar;
