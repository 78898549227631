/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
/*Services*/
import devconfig from '../../config/config';
import APIPost from '../../services/apiCall/post';
import Input from '../formElements/input';

class LoginModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            starStatus: 0,
            comment: "",
            partnerIdError: "",
            partnerId: "",
            password: "",
            passwordError: "",
        }
    }

    antLogin() {
        sessionStorage.setItem("ts_login_redirect", window.location.href);
        const getLoginUrl = devconfig.aliceBlueAuthURL +
            "?appcode=" + devconfig.appCode;
        window.location.href = getLoginUrl;

    }

    responseGoogle(response) {
        if (!response.error) {
            this.apiSocialLogin(response.profileObj.name, response.profileObj.email, response.profileObj.imageUrl, response.googleId);
        }
        else {
            this.setState({ triggerGoogleLogin: false, googleLoginLoading: false });
        }
    }

    googleLoginClick() {
        this.setState({ triggerGoogleLogin: true, googleLoginLoading: true });
        $(".e-google-login-wrap button").trigger("click");
        $('#loginModal').modal('toggle');
    }

    _handlePartnerModal = () => {
        $('#partnerLoginModal').modal('toggle');
        $('#loginModal').modal('hide');
    }

    _handleParnerLogin = () => {
        if (!this.state.partnerId) {
            this.setState({
                partnerIdError: 'Please Enter Valid ID'
            });
            return false
        }
        if (!this.state.password) {
            this.setState({
                passwordError: 'Please Enter Valid Password'
            });
            return false
        }

        var tempEncodeData = this.state.partnerId.concat(":").concat(this.state.password);
		var encodedData = btoa(tempEncodeData);

		$.ajax({
			type: "GET",
			url: devconfig.partnerV1 + '/auth/login',
			dataType: 'json',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				"Authorization": "Basic " + encodedData,
			}
		}).then(function (response) {
			console.log(response)

			if (response.status == "ok") {
				localStorage.setItem("tradeschool_access_token", response.access_token);
				localStorage.setItem("partner_name", response.data.name);
				localStorage.setItem("partner_mail", response.data.email);
				localStorage.setItem("alice_blue_id", response.data.alice_blue_id);
				localStorage.setItem("partner_id", response.data.id);
				localStorage.setItem("userData", JSON.stringify(response.data));

				localStorage.setItem("partner_token", true);
				window.location.href = '/';
			}
			else {
				this.setState({ passwordError: response.message });
			}
		}.bind(this))
			.catch(function (response) {
				this.setState({ passwordError: response.responseJSON.message });
			}.bind(this))

    }

    apiSocialLogin(tempName, tempEmail, tempURL, tempID) {
        var url = devconfig.user + "/auth/login";
        var data = JSON.stringify({
            "email": tempEmail,
            "name": tempName,
            "social_user_id": tempID,
            "image": tempURL,
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                localStorage.setItem("tradeschool_access_token", response.access_token);

                localStorage.setItem("userData", JSON.stringify(response.data));
                localStorage.setItem("tradeschool_token", true);
                window.location.reload();
            }
            else {
            }
        }.bind(this))
    }


    render() {

        return (
            <>
                <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="addReviewModalLabel" aria-hidden="true">
                    <div className="modal-dialog e-rate-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header pb-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <h5 className="e-login-head">Login</h5>
                                    <p className="e-login-p">Login with your Aliceblue account or use your gmail.</p>
                                    <span className="e-p-btn w-100 text-center mt-2" onClick={this.antLogin} >Login with Aliceblue ANT</span>
                                    <span className="e-s-btn w-100 text-center mt-4" onClick={this.googleLoginClick.bind(this)} >Login with Google</span>
                                </div>

                                <div className='mt-4 pt-3 e-partner-login'>
                                    <h6>Partner Login</h6>
                                    <span className="e-s-btn w-100 text-center "
                                        onClick={this._handlePartnerModal}>
                                        Login as Aliceblue Partner
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">



                            </div>
                        </div>
                    </div>
                </div>

                {/* partner login modal */}

                <div className="modal fade" id="partnerLoginModal" tabIndex="-1" role="dialog" aria-labelledby="addReviewModalLabel" aria-hidden="true">
                    <div className="modal-dialog e-rate-modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header pb-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <h5 className="e-login-head">Parnter Login</h5>
                                    <p className="e-login-p">Login with Aliceblue parnter id</p>

                                    {/* id number */}
                                    <div className='e-parnerid_wrapper'>
                                        <Input label="Partner ID"
                                            id="u-id"
                                            type="text"
                                            error={this.state.partnerIdError}
                                            value={this.state.partnerId}
                                            valueChange={(tempVal) => {
                                                this.setState({
                                                    partnerId: tempVal,
                                                    partnerIdError: ''

                                                })
                                            }} />
                                    </div>

                                    {/* id number */}
                                    <div className='e-parnerid_wrapper'>
                                        <Input label="Password"
                                            id="u-password"
                                            type="password"
                                            error={this.state.passwordError}
                                            value={this.state.password}
                                            valueChange={(tempVal) => {
                                                this.setState({
                                                    password: tempVal,
                                                    passwordError: ""
                                                })
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">

                                <span className="e-p-btn w-100 text-center"
                                    onClick={this._handleParnerLogin} >
                                    Login
                                </span>


                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LoginModal;
