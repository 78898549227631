// import React from 'react';
// import ReactDOM from 'react-dom';

// /*Style Import*/
// import 'normalize.css/normalize.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
// import './assets/css/global.css';
// import './assets/css/style.css';
// import './assets/css/responsive.css';



// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();



import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

/*Style Import*/
import 'normalize.css/normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/global.css';
import './assets/css/style.css';
import './assets/css/responsive.css';



import App from './App';
import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

// import React from "react";
// import ReactDOM from "react-dom";

// import App from "./App";

// const rootElement = document.getElementById("root");
// const root = ReactDOM.createRoot(
//     document.getElementById('root')
//   );

//   ReactDOM.render(
//     <BrowserRouter >
//       <App />
//     </BrowserRouter>,
//     document.getElementById('root')
//   );

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   rootElement
// );
