/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';




/*Assets*/

import CategoryPattern1 from '../../assets/images/home/category-pattern-1.svg';
import CategoryPattern2 from '../../assets/images/home/category-pattern-2.svg';

class AllCategory extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    new WOW.WOW().init();
    $('html,body').animate({ scrollTop: 0 }, 'slow');
    this.getCategory();
  }

  getCategory=()=>{
     var url = devconfig.user+"/category/get-all";
    
    APIGet(url).then(function(response){
      if(response.status){
        this.setState({data: response.data});
      }
    }.bind(this))
  }

  render() {
    return (
      <React.Fragment>
      {
        this.state.data ?
      <section className="e-section e-category-section position-relative">
        <img className="e-pattern-1 wow fadeInLeft" data-wow-delay=".4s" src={CategoryPattern1} alt="Tradeschool courses categories"/>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <h4 className="text-white">Webinar Categories</h4>
            </div>
            <div className="col-lg-6 col-md-4 text-right">
              <Link className="e-view-all mt-3 text-white" to="/all-webinars">View all Webinars</Link>
            </div>
          </div>
          <div className="row mt-4">
          {
              this.state.data && this.state.data.map((item, key)=>{
                var tempURL = "/all-webinars?id="+item.id;
                return(
            <div className="col-lg-3 col-md-6 col-6 ">
            
            <Link to={tempURL}>
                  <div className="e-category-item text-center wow fadeIn" data-wow-delay="" key={key}>
                    {item.name}
              </div>
              </Link>
              </div>
                );
              })
            }
          </div> 
             {/* <div className="e-category-item text-center wow fadeIn" data-wow-delay="">
                             Trading Fundamentals
                           </div>
                         </div>
                         <div className="col-lg-3 col-md-6">
                           <div className="e-category-item text-center wow fadeIn" data-wow-delay="">
                             Trading Basics
                           </div>
                           <div className="e-category-item text-center wow fadeIn" data-wow-delay="">
                             Technical Analysis
                           </div>
                         </div>
                         <div className="col-lg-3 col-md-6">
                           <div className="e-category-item text-center wow fadeIn" data-wow-delay="">
                             Business Fundamentals
                           </div>
                           <div className="e-category-item text-center wow fadeIn" data-wow-delay="">
                             Stock Market Basics
                           </div>
                         </div>
                          <div className="col-lg-3 col-md-6">
                           <div className="e-category-item text-center wow fadeIn" data-wow-delay="">
                             Business Fundamentals
                           </div>
                           <div className="e-category-item text-center wow fadeIn" data-wow-delay="">
                             Market Watch
                           </div>*/}
            
        </div>


        <img className="e-pattern-2 wow fadeInRight" data-wow-delay=".4s" src={CategoryPattern2} alt="Tradeschool courses categories"/>
      </section>
      :
      null
      }
    </React.Fragment>



    );
  }
}

export default AllCategory;
