/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
/*Components*/


/*Assets*/
import Webinar from '../../assets/images/webinar/webinar.png';
import Live from '../../assets/images/webinar/live.svg';

class WebinarCard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    fun1 = () => {
        this.setState({ click: 1 })
    }

    routeChange = () => {
        // if (localStorage.getItem("tradeschool_token")) {
        // window.location.href = `/webinar-details?id=${this.props.data.id}&key=${2}`;
        // window.location.href = `/webinar-details?id=83&key=2`;
        // }
        // else {
        //     $('#loginModal').modal('toggle');
        // }

        // alert('navigation !!!')
        // console.log('this.props.history :: ',this.props)

        this.props.onNavigation(this.props.type, this.props.data.id)

    }


    render() {
        console.log('language', this.props.data.language
        )
        return (
            <>
                {
                    this.props.key === 3 ?
                        <>
                            <div className="card e-course-card e-card-transition card3 wow fadeInUp e-webinar-card" data-wow-delay=".4s">
                                <div className="position-relative">
                                    <img className="card-img-top e-course-cover" src={Webinar} alt="" />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title e-p-ellipsis-2">Actual Trends in Stock Market Study</h5>
                                    <p className="card-text e-p-ellipsis-1 e-web-tag">
                                        <span className=''>Host by: John doe, Louis mathew</span>
                                    </p>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <span className="e-webinar-date-and-time">Webinar Status:</span>
                                        </div>

                                    </div>
                                    <div className='row pt-1'>
                                        <div className='col-lg-9 pr-0'>
                                            <span className="e-webinar-date-and-time e-webinar-date">On going live<img src={Live} alt="" className='ml-2' /> </span>
                                        </div>
                                        <div className='col-lg-3 pl-0 text-right'>
                                            <span className="e-price-wrap">
                                                <span className="e-price-offer">
                                                    Free
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <Link to={`/webinar-details?id=381&key=${3}`} >
                                                <span className="e-s-btn w-100 text-center mt-3">View details</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        // <Link to={`/webinar-details?id=${this.props.data.id}&key=${2}`}>
                        <div className="card e-course-card  e-card-transition card3 wow fadeInUp e-webinar-card"
                            data-wow-delay=".4s"
                            onClick={() => { this.routeChange() }}>
                            <div className="position-relative">
                                <img draggable='false'
                                    className="card-img-top e-course-cover"
                                    src={this.props.data.thumbnail}
                                    alt="card-img" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title e-p-ellipsis-2">{this.props.data.name}</h5>
                                {/* <p className="card-text e-p-ellipsis-1 e-web-tag">
                                    Host by :<span className='pl-1'>{this.props.data.creator}</span>
                                </p> */}
                                {/* <p className='card-text e-webinar-date-and-time mb-2'>Webinar ID :
                                    <span className='pl-1'>{this.props.data.webinar_id}</span>
                                </p> */}
                                <div className='text-truncate mb-2'>
                                    {this.props.data.category_ids && this.props.data.category_ids.map((category, index) => (
                                        < >
                                            <span key={index}
                                                className='card-text e-webinar-date-and-time mb-2 e-category'>
                                                {category.category}
                                            </span>
                                            {index !== this.props.data.category_ids.length - 1 && (
                                                <span className='px-1 e-category-dot'>•</span>
                                            )}
                                        </>
                                    ))}
                                </div>
                                {/* {this.props.data.category} */}

                                {/* <div className='text-truncate mb-2'>
                                    {this.props.data.language&& this.props.data.language.map((language, index) => (
                                        < >
                                            <span key={index}
                                                className='card-text e-webinar-date-and-time mb-2 e-category'>
                                                {language}
                                            </span>
                                            {index !== this.props.data.language.length - 1 && (
                                                <span className='px-1 e-category-dot'>•</span>
                                            )}
                                        </>
                                    ))}
                                </div> */}

 
                                <div className='d-flex e-flex-wrap'>

                                    {this.props.data.language_ids && this.props.data.language_ids.map((language, index) => (
                                        < >
                                            <p key={index}
                                                className='h-100 card-text e-webinar-date-and-time mb-2 e-category mr-2 e-language-chip'>
                                                {language.language}
                                            </p>
                                            {/* {index !== this.props.data.language_ids.length - 1 && (
                                                <span className='px-1 e-category-dot'>•</span>
                                            )} */}
                                        </>
                                    ))}
                                </div>

                                <p className="e-webinar-date-and-time mt-2 mb-3">Scheduled on :
                                    <span className="e-webinar-date-and-time e-webinar-date pl-1">
                                        {this.props.data.scheduled_time}
                                    </span>
                                </p>

                                {/* <span className="e-webinar-date-and-time">Scheduled on : {this.props.data.scheduled_time}</span> */}

                                {/* <div className='pt-1'>
                                    <span className="e-webinar-date-and-time e-webinar-date">{this.props.data.scheduled_time}</span>
                                </div> */}

                                <p className="e-webinar-date-and-time mb-3">Enrolled :
                                    <span className="pl-1">
                                        {this.props.data.enrolled_count}
                                    </span>
                                </p>

                                <p className='card-text e-webinar-date-and-time mb-1'>Duration :
                                    <span className='pl-1'>{this.props.data.duration}</span>
                                </p>

                                <div className='row'>
                                    <div className='col-lg-12'>
                                        {/* <Link to={`/webinar-details?id=${this.props.data.id}`}> */}
                                        <span className="e-s-btn w-100 text-center mt-3" >View details</span>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    // </Link>
                }
                {/* <Main liv/> */}

            </>

        );
    }
}

export default withRouter(WebinarCard);
