/*Package importing*/
import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import Toast from '../../components/popups/toast';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';




/*Assets*/


import Img1 from '../../assets/images/webinar/latest.svg';
import Save from '../../assets/images/webinar/save.svg';
import PlayBtn from '../../assets/images/webinar/play-btn.svg';
import Fav from '../../assets/images/webinar/fav-active.svg'

class LatestWebinarCard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        // new WOW.WOW().init();
        // $('html,body').animate({ scrollTop: 0 }, 'slow');

    }

    addFav = (temp) => {
        var url = devconfig.user + "/webinar/bookmark";
        var data = JSON.stringify({
            "webinar_id": parseInt(temp),
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.triggerToast(response.code);
                // this.props.reload();
            }

            else {
            }
        }.bind(this));
    }
    triggerToast(temp) {
        if (temp == 0) {
            this.setState({ toast: "Remove From Saved", toastType: "success" })
            setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
            // this.getData(this.state.blogId);
        } else {
            this.setState({ toast: "Add to Saved", toastType: "success" })
            setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
        }
    }

    _routeChange = () => {
        // if (localStorage.getItem("tradeschool_token")) {
        // window.location.href = `webinar-details?id=${this.props.data.id}`;
        // }
        // else {
        //   $('#loginModal').modal('toggle');  //login modal
        //   // $('#joinWebinarModal').modal('toggle') //join webinar modal
        // }


        this.props.onNavigation(this.props.type, this.props.data.id)
    }


    render() {
        console.log('type::',this.props.type)
        return (
            <React.Fragment>

                {
                    this.props.data ?
                        <div className="card e-course-card e-card-transition card3 wow fadeInUp e-live-webinar-card e-upcoming-webinar-card e-latest-webinar e-overlay "
                            data-wow-delay=""
                            onClick={this._routeChange}>
                            <div className="position-relative">
                                <img className="card-img-top e-course-cover"
                                    src={this.props.data.thumbnail} alt="" />
                                <span className="e-course-header e-ellipse">
                                    {this.props.data.name}</span>
                                <img className="e-play-btn2"
                                    src={PlayBtn}
                                    alt="play" />

                                {/*<button className="e-course-save e-save-btn" onClick={()=>{this.addFav(this.props.data.id)}}> <img className="" src={this.props.data.is_bookmarked ? Fav : Save} alt=""/></button>*/}
                                <span className="e-course-author">
                                    {this.props.data.duration}
                                </span>
                            </div>

                        </div>
                        :
                        null
                }
                {
                    this.state.toast ?
                        <Toast data={this.state.toast} type={this.state.toastType} />
                        :
                        null
                }

            </React.Fragment>

        );
    }
}

export default LatestWebinarCard;
// <span className="e-course-author">iii</span>
// <img className="" src={PlayBtn} alt=""/>
// <span className="e-course-live">Live</span>

// this.state({
//   'name' : 'Abhay',
//   'location': 'Calicut'
// },()=>{
//   this.findme();
// });


// useEffect(()=> {
//   findme();
// }, [name, location])