/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pattern1 from '../../assets/images/home/banner-pattern-1.svg';


/*Components*/
import CourseCard from '../cards/card';


class FeatureSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: "1"

    }
  }

  componentDidMount() {

  }




  render() {
    var settings = {
     
      dots: false,
      arrows: true,
      infinite: false,
      speed: 1000,
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: false,
     
      responsive: [
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            autoplay: true,
            dots: false,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: false,
            autoplaySpeed: 1000,
            cssEase: 'linear',
            autoplay: true,
            dots: false,
          }
        }
      ]
    };

    return (
      
        <Slider {...settings}>

          <div className='mr-3'>
            <span className={this.state.click === "1" ? "e-active-btn" : "e-btn"} onClick={() => this.setState({click:"1"})}>All</span>
          </div>
          <div className='mr-3'>
            <span className={this.state.click === "2" ? "e-active-btn" : "e-btn"} onClick={() => this.setState({click:"2"})}>Free course</span>
          </div>
          <div className='mr-3'>
            <span className={this.state.click === "3" ? "e-active-btn" : "e-btn"} onClick={() => this.setState({click:"3"})}>Paid course</span>
          </div>
          <div className='mr-3'>
            <span className={this.state.click === "4" ? "e-active-btn" : "e-btn"} onClick={() => this.setState({click:"4"})}>Offline course</span>
          </div>
          <div className='mr-3'>
            <span className={this.state.click === "5" ? "e-active-btn" : "e-btn"} onClick={() => this.setState({click:"5"})}>Process webinar</span>
          </div>
          <div className='mr-3'>
            <span className={this.state.click === "6" ? "e-active-btn" : "e-btn"} onClick={() => this.setState({click:"6"})}>Stragic-technical webinar</span>
          </div>
          <div className='mr-3'>
            <span className={this.state.click === "7" ? "e-active-btn" : "e-btn"} onClick={() => this.setState({click:"7"})}>Partner webinar</span>
          </div>
          <div className='mr-3'>
            <span className={this.state.click === "8" ? "e-active-btn" : "e-btn"} onClick={() => this.setState({click:"1"})}>Free course</span>
          </div>
         
        


        </Slider>
      
    );


  }
}

export default FeatureSlide;
