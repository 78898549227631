/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
/*Components*/


/*Assets*/
import card from '../../assets/images/placeholder/card.png';

class CourseCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  setBalance=()=>{
    var url = devconfig.user+"/auth/wallet-balance";
    APIGet(url).then(function(response){
      if(response.status){
        // this.setState({ balance: response.data.balance});
        localStorage.setItem("wallet_balance", response.data.balance);
      }
      else{
        // this.setState({ balance: response.data.balance});
      }
    }.bind(this))
  }

  render() {
    return (
      <div className="card e-course-card e-card-transition card3 wow fadeInUp" data-wow-delay={this.props.delay} onClick={this.setBalance}>
        <div className="position-relative">
          {
            this.props.data.offer ?
              <span className="e-course-offer">Offer</span>
            :
              null
          }
          
          {/*<span className="e-course-wishlist"></span>*/}
          <img className="card-img-top e-course-cover" src={this.props.data.image} alt={this.props.data.name}/>
          <span className="e-course-author">{this.props.data.creator}</span>
        </div>
        <div className="card-body">
          <h5 className="card-title e-p-ellipsis-2">{this.props.data.name}</h5>
          {
            this.props.data.categories ?
              <p className="card-text e-course-tag e-p-ellipsis-1">
                { 
                  this.props.data.categories.map( (data, index) => {
                    return(
                      <span key={index}>{data.category} <b>•</b></span>
                    );
                  })
                }
              </p>
            :
              null
          }
          <div className="d-flex justify-content-between">
            <span className="e-review">{this.props.data.rating}</span>
            <span className="e-price-wrap">
              <span className="e-price-regular">
                ₹ {this.props.data.price}
              </span>
              <span className="e-price-offer">
                ₹ {this.props.data.offer_price}
              </span>
            </span>
          </div>
        </div>
      </div>
      
    );
  }
}

export default CourseCard;
