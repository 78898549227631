/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/
import Input from '../formElements/input';
import SelectBox from '../formElements/select-box';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import PhoneValidation from '../../services/validation/phone';
import EmailValidation from '../../services/validation/email';

/*Assets*/
import Success from '../../assets/images/modals/success.svg';

class OpenAccountModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subCatOption: { value: null, label: "City" },
            mainCatOption: [],
            mainCat: [],
            subCat: [],
            tempCities: [],
            uName: "",
            uPhone: "",
            uMail: "",
            sortDefault: { value: 1, label: 'State' },
            success: false,
            apiCallLoading: false,
            selectedState: {},
            categoryOption: null,
            showModal: false,
            loading: true


        }
    }

    componentDidMount() {

        var tempCountry = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"]
        var tempArray = [];
        tempCountry.map(function (key, index) {
            var temp = { value: index, label: key };
            tempArray.push(temp);
        });

        var s_a = new Array();
        s_a[0] = "";
        s_a[1] = "Hyderabad";
        s_a[2] = "Itanagar";
        s_a[3] = "Dispur";
        s_a[4] = "Patna";
        s_a[5] = "Raipur";
        s_a[6] = "Panaji";
        s_a[7] = "Ahmedabad";
        s_a[8] = "Chandigarh";
        s_a[9] = "Shimla";
        s_a[10] = "Srinagar";
        s_a[11] = "Ranchi";
        s_a[12] = "Bengaluru";
        s_a[13] = "Thiruvananthapuram";
        s_a[14] = "Bhopal";
        s_a[15] = "Mumbai|Pune|Nagpur|Nashik";
        s_a[16] = "Imphal";
        s_a[17] = "Shillong";
        s_a[18] = "Aizawl";
        s_a[19] = "Kohima";
        s_a[20] = "Bhubaneswar";
        s_a[21] = "Chandigarh";
        s_a[22] = "Jaipur";
        s_a[23] = "Gangtok";
        s_a[24] = "Chennai|Madurai|Erode|Karur|Coimbatore";
        s_a[25] = "Hyderabad";
        s_a[26] = "Agartala";
        s_a[27] = "Lucknow";
        s_a[28] = "Dehradun";
        s_a[29] = "Kolkata";

        this.setState({
            tempCities: s_a,
            mainCat: tempArray,
            uName: this.props.profileData.name,
            uMail: this.props.profileData.email,
            loading: false
        });

        if (this.props.profileData.state_id != undefined) {
            this.setState({
                mainCatOption: {
                    value: this.props.profileData.state_id,
                    label: this.props.profileData.state
                }
            }, () => {
                setTimeout(()=>{
                    this.mainCatChange({
                        value: this.props.profileData.state_id,
                        label: this.props.profileData.state
                    });
                }, 2000)
                
            });
        } else {
            this.setState({
                mainCatOption: []
            });
        }


    }


    typeChange = async (type) => {
        await this.setState({ type: type.value });
    }

    mainCatChange = (mainCatOption) => {
        this.setState({ mainCatOption });
        try{
            var tempCitiesArray = this.state.tempCities[mainCatOption.value + 1].split("|");
            var tempArray = [];
            tempCitiesArray.map(function (key, index) {
                if (index == 0) {
                    this.setState({ subCatOption: { value: index, label: key } });
                }
                var temp = { value: index, label: key };
                tempArray.push(temp);
            }.bind(this));

            this.setState({ subCat: tempArray });
        }catch(err){
            console.log(err)
        }
        
    }

    subCatChange = (subCatOption) => {
        this.setState({ subCatOption });
    }

    async openAccountSubmit() {

        var validationSuccess = true;
        await PhoneValidation(this.state.uPhone).then(function (response) {
            if (!response) {
                this.setState({
                    errorMessage: "Invalid phone number, please check and retry.",
                    apiLoading: false
                });
                validationSuccess = false;
            }
        }.bind(this))

        await EmailValidation(this.state.uMail).then(function (response) {
            if (!response) {
                this.setState({ errorMessage: "Invalid email address, please check and retry.", apiLoading: false });
                validationSuccess = false;
            }
        }.bind(this))


        if (validationSuccess) {
            this.setState({ apiCallLoading: true });
            var url = devconfig.url + "/general/digilink-api";
            var data = JSON.stringify({
                "Name": this.state.uName,
                "EmailID": this.state.uMail,
                "MobileNo": this.state.uPhone,
                "State": this.state.mainCatOption.label,
                "City": this.state.subCatOption.label,
                "Source": "SCHOOL",
                "refer_id": localStorage.getItem("ts_ref"),
            });
            APIPost(url, data).then((response) => {
                if (response.status) {
                    this.setState({ success: true });

                    setTimeout(function () {
                        $('#openAccountModal').modal('hide');
                        this.setState({ success: false });
                        window.location.reload();
                        localStorage.removeItem("ts_ref");
                    }.bind(this), 2000);
                }
                else {
                    this.setState({ errorMessage: response.message });
                }

                this.setState({
                    apiCallLoading: false,
                    subCatOption: { value: null, label: "City" },
                    mainCatOption: { value: null, label: "State" },
                    mainCat: [],
                    subCat: [],
                    tempCities: [],
                    uName: "",
                    uPhone: "",
                    uMail: "",
                    apiLoading: false
                });

            })

        }
    }

    render() {
        return (
            <>

                <div className="modal fade e-form-modal e-open-account-modal" id="openAccountModal" tabIndex="-1" role="dialog" aria-labelledby="joinTutorModalLabel" aria-hidden="true">

                    <div className="modal-dialog" role="document">
                        {
                            this.state.success ?
                                <div className="modal-content">
                                    <div className="e-success">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <img src={Success} alt="success" />
                                            <h6 className="mt-3">Success!</h6>
                                            <p>Thank you for contacting us, our business executive will contact you soon.</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    {
                                        this.state.loading == false &&
                                        <div className="modal-content">
                                            <div className="modal-header text-left">
                                                <h5 className="modal-title e-secondary-head"><b>Start a new trading account</b> <br /> with Aliceblue</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div className="modal-body ">
                                                {/* name */}
                                                <Input label="Your Name"
                                                    id="u-name"
                                                    type="text"
                                                    value={this.state.uName}
                                                    valueChange={(tempVal) => this.setState({
                                                        uName: tempVal,
                                                        errorMessage: ""
                                                    })} />

                                                {/* phone number */}
                                                <Input label="Phone Number"
                                                    id="u-phone"
                                                    type="text"
                                                    value={this.state.uPhone}
                                                    valueChange={(tempVal) => {
                                                        if (tempVal.length < 11) {
                                                            this.setState({
                                                                uPhone: tempVal,
                                                                errorMessage: ""
                                                            })
                                                        }
                                                    }}
                                                />

                                                {/* email */}
                                                <Input label="Email Address"
                                                    id="u-email"
                                                    type="text"
                                                    value={this.state.uMail}
                                                    valueChange={(tempVal) => this.setState({
                                                        uMail: tempVal,
                                                        errorMessage: ""
                                                    })} />

                                                {/* state */}
                                                <SelectBox search={true}
                                                    defaultValue={this.state.mainCatOption}
                                                    options={this.state.mainCat}
                                                    selectchange={this.mainCatChange}
                                                    placeholder="State" />

                                                {/* cities if there are more than one */}
                                                {

                                                    this.state.subCat.length > 1 ?
                                                        <div className="mt-3">
                                                            <SelectBox search={true}
                                                                placeholder="City"
                                                                defaultValue={this.state.subCatOption}
                                                                options={this.state.subCat}
                                                                selectchange={this.subCatChange} />
                                                        </div>
                                                        :
                                                        null
                                                }

                                                {
                                                    this.state.errorMessage && this.state.errorMessage.length ?
                                                        <div className="e-error">{this.state.errorMessage}</div>
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className="modal-footer pt-0">
                                                {
                                                    !this.state.apiCallLoading && this.state.uName && this.state.uMail && this.state.uPhone && (this.state.mainCatOption.value || this.state.mainCatOption.value == 0) ?
                                                        <span className="e-p-btn e-login-with-gmail" onClick={this.openAccountSubmit.bind(this)}>Send Enquiry</span>
                                                        :
                                                        <span className="e-p-btn e-login-with-gmail e-inactive">Send Enquiry</span>
                                                }
                                            </div>
                                        </div>}
                                </>
                        }

                    </div>
                </div>

            </>
        );
    }
}

export default OpenAccountModal;
