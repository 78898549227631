import $ from 'jquery';

export default async function APIGet(url) {

  return await $.ajax({
    type: "GET",
    url: url,
    dataType: 'json',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Token': localStorage.getItem("tradeschool_access_token")
    }
  })
  .then(function(response){
    if(response){
      return response
    }
  }.bind(this))

  .fail(function(xhr, textStatus, errorThrown){
    return textStatus
  }.bind(this))

}
