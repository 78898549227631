/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import CardPlaceholder from '../../components/placeholder/card';
import CourseCard from '../../components/cards/card';
import NoDataFound from '../../components/common/nothing';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';

/*Assets*/
import cover from '../../assets/images/home/cover.png';
import BannerPattern1 from '../../assets/images/all-courses/banner-pattern-1.svg';
import BannerPattern2 from '../../assets/images/all-courses/banner-pattern-2.svg';

class Search extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      dataLoading: false,
      placeholder4: [1, 2, 3, 4],
      kw: ""
    }
  }

  componentDidMount() {
    new WOW.WOW().init();
    $('html,body').animate({ scrollTop: 0 }, 'slow');

    var query = this.getUrlVars();
    if(query.kw){
      this.getData(query.kw.replace(/%20/g, " "));
      this.setState({kw: query.kw.replace(/%20/g, " ")});
    }
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  getData(tempKW){
    this.setState({dataLoading: true});

    var url = devconfig.user+"/home/search";
    var data = JSON.stringify({
      "text" : tempKW,
      "type" : 3
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        this.setState({ data: response.data, dataLoading: false});
        new WOW.WOW().init();
      }
      else{
        this.setState({ data: []});
      }
    }.bind(this))
  }


  render() {
    var tempIndex = 1;
    return (
      <div className="e-home e-all-course-wrap pb-5">
        <section className="e-banner-section position-relative e-result-banner">
          <div className="container position-relative">
            <img className="e-banner-pattern-1 wow fadeIn" data-wow-duration="4s" src={BannerPattern1} alt="Tradeschool courses listing"/>
            <div className="row">
              <div className="col-lg-12">
                <h3>Results for “{this.state.kw}”</h3>
              </div>
            </div>
            <img className="e-banner-pattern-2 wow fadeIn" data-wow-duration="4s" src={BannerPattern2} alt="Tradeschool courses listing"/>
          </div>
        </section>

        <section className="my-4 e-serach-results">
          <div className="container">
            
            {
              this.state.dataLoading ?
                <div className="row">
                  { 
                    this.state.placeholder4.map( (data, index) => {
                      return(
                        <div className="col-lg-3 col-md-6 col-6" key={index}>
                          <CardPlaceholder/>
                        </div>
                      );
                    })
                  }
                </div>
              :
                <div>
                  {
                    this.state.data && this.state.data.length ?
                      <div className="row">
                        { 
                          this.state.data.map( (data, index) => {
                            if(tempIndex>9){
                              tempIndex = 1;
                            }
                            tempIndex = parseInt(tempIndex) + 1;

                            var tempDelay = "."+tempIndex+"s";
                            var tempURL = "/course-details?id="+data.id;
                            return(
                              <div className="col-lg-3 col-md-6 mb-4" key={index}>
                                <Link to={tempURL}>
                                  <CourseCard data={data} delay={tempDelay}/>
                                </Link>
                              </div>
                            );
                          })
                        }
                      </div>
                    :
                      <div>
                        <NoDataFound head="No Result" data="Search with another keyword for courses."/>
                      </div>
                  }
                </div>
            }
          </div>
        </section>
      </div>

      
    );
  }
}

export default Search;
