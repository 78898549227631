/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import ReactPlayer from 'react-player/lazy';
import $ from 'jquery';
import parse from 'html-react-parser';

/*Components*/
import Toast from '../../components/popups/toast';
import ReviewCard from '../../components/cards/review.js';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import GetURLVar from '../../services/getParams/get-url';

/*Assets*/
import playbtn from '../../assets/images/carbon_play-outline.svg';
import Arrow from '../../assets/images/webinar-detail/arrow.svg';
import Nav1 from '../../assets/images/webinar-detail/nav1.png';
import Nav2 from '../../assets/images/webinar-detail/nav2.png';
import Appstore from '../../assets/images/webinar-detail/appstore.svg';
import Playstore from '../../assets/images/webinar-detail/playstore.svg';
import Mask from '../../assets/images/webinar-detail/mask.svg';
import RightArrow from '../../assets/images/webinar-detail/r-arrow.svg';
import LiveIcon from '../../assets/images/webinar-detail/live.svg';
import Select from "../../assets/images/bookmark-select.png";
import Unselect from "../../assets/images/bookmark-unselect.png";
import ArrowR from "../../assets/images/arrow-right.png";
import ArrowL from "../../assets/images/arrow-left.png";
import Twitter from "../../assets/images/social/twitter.svg";
import Facebook from "../../assets/images/social/facebook.svg";
import Whatsapp from "../../assets/images/social/whatapp.svg";
import Telegram from "../../assets/images/social/telegram.png";
import LinkIcon from "../../assets/images/social/link.png";
import LinkdIn from "../../assets/images/social/linkdin.svg";
import Share from '../../assets/images/webinar-detail/share.svg';

/*import container */
import Faq from './faq.js';
import APIGet from '../../services/apiCall/get';
import JoinWebinarModal from '../../components/modals/join-webinar';
import YoutubeModal from '../../components/modals/youtube';
import NotFound from '../404';
import ReferEarn from '../../components/widget/ReferEarn';
import OpenAccountModal from '../../components/modals/open-account';

class WebinarDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            tabIndex: 1,
            key: 1,
            selectMark: true,
            bookmarked: false,
            profileData: {},
            showEnrollModal: false,
            showYoutubeModal: false,
            type: '',
            dataLoading: true,
            youtubeData: {},
            showModal: false,

        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
        this._getProfileData();
        GetURLVar().then(function (query) {
            this.getData(query.id);
            this.setState({
                id: query.id,
                key: Number(query.key)
            });

            if (query.ts_refcode != undefined) {
                localStorage.setItem("ts_id", query.id);
                localStorage.setItem("ts_ref", query.ts_refcode);
            }
        }.bind(this));
        new WOW.WOW().init();
    }

    // API - Get webinar details
    getData = (tempID) => {
        var url = devconfig.user + "/webinar/detail";
        var data = JSON.stringify({
            "webinar_id": parseInt(tempID),
        });

        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({
                    data: response.data,
                    dataLoading: false,
                    bookmarked: response.data.is_bookmarked,
                    status: response.status,
                    intro_video: response.data.intro_video
                });

                // Input YouTube URL
                const youtube_url = this.state.intro_video;
                if (youtube_url != null) {
                    const new_url = youtube_url.split('/');
                    const updated_url = new_url[new_url.length - 1];
                    // Split the last part by '?' to remove query parameters
                    const videoId = updated_url.split('?')[0];
                    const image_url = "https://i.ytimg.com/vi/" + videoId + "/maxresdefault.jpg";
                    this.setState({
                        converted_thumbnail: image_url
                    });
                } else {
                    this.setState({
                        converted_thumbnail: null
                    });
                }
            } else {
                this.setState({
                    dataLoading: false,
                    data: []
                });
            }
        }.bind(this));
    }

    //API - Add or remove webinar from bookmarked list
    _postBookmark() {
        var url = devconfig.user + "/webinar/bookmark";
        var data = JSON.stringify({
            "webinar_id": this.state.id
        })
        APIPost(url, data).then(function (response) {
            if (localStorage.getItem("tradeschool_token")) {
                if (response.status) {
                    this.setState({
                        bookmarked: !this.state.bookmarked
                    });
                    if (response.code == 0) {
                        this.setState({
                            toast: "Webinar removed from saved list",
                            toastType: "success"
                        });
                        setTimeout(function () {
                            this.setState({
                                toast: null
                            });
                        }.bind(this), 5000);
                    } else {
                        this.setState({
                            toast: "Webinar successfully added to saved list",
                            toastType: "success"
                        });
                        setTimeout(function () {
                            this.setState({
                                toast: null
                            });
                        }.bind(this), 5000);
                    }
                }
            } else {
                $('#loginModal').modal('toggle');  //Login modal
            }
        }.bind(this))
    }

    //API - Get Profile data
    _getProfileData() {
        var base_url = devconfig.user;
        let partner_token = localStorage.getItem("partner_token") ;
        // # checking if partner 
        if(partner_token==="true"){
            base_url = devconfig.partnerV1;
        }
        var url = base_url + "/auth/profile";
        APIGet(url).then(function (response) {

            if (response.status) {
                this.setState({
                    profileData: response.data
                });
            }
            else {
                this.setState({
                    profileData: []
                });
            }
        }.bind(this))
    }

    async tabItemClick(tempItem) {
        await this.setState({ tabIndex: tempItem });
    }

    //join webinar modal handleClick
    _handleclick = () => {
        if (localStorage.getItem("tradeschool_token")) {
            this.setState({
                showEnrollModal: true // enable the modal
            }, () => {
                $('#joinWebinarModal').modal('show'); // activate the bootstrap style
            });
        } else {
            $('#loginModal').modal('toggle');  //Login modal
        }


    }

    //open an account modal handleClick
    _accountHandleclick = () => {
        this.setState({
            showModal: true // enable the modal
        }, () => {
            $('#openAccountModal').modal('show');
        });
    }


    //youtube modal open
    _handleOpenModal = () => {
        this.setState({
            showYoutubeModal: true
        }, () => {
            $('#youtubeModal').modal('show');
        })

    }


    _copyNavigationURL = () => {
        const currentURL = window.location.href;
        // Check if the URL contains the ts_refcode parameter
        if (currentURL.includes('ts_refcode=')) {
            var newURL = currentURL.replace(/ts_refcode=[^&]+&?/, '');
            navigator.clipboard.writeText(newURL);
        } else {
            navigator.clipboard.writeText(currentURL);
        }
        this.setState({
            toast: "Copied",
            toastType: "success"
        });
        setTimeout(function () {
            this.setState({
                toast: null
            });
        }.bind(this), 3000);
    }

    render() {
        // console.log('this.state.data.language_ids', this.state.data)

        return (
            <React.Fragment>
                {
                    this.state.dataLoading ?
                        <div className="e-home e-course-detail-wrap">
                            <section className="e-banner-section position-relative e-placeholder">
                            </section>
                            <section className="e-tab-title-wrap 
                                    e-placeholder e-card-placeholder 
                                    e-order-plac position-relative">
                                <span></span>
                            </section>
                            <section className="e-modules-wrap e-placeholder mt-5">
                            </section>
                        </div>
                        :
                        null
                }

                {
                    this.state.data ?
                        <React.Fragment>
                            {
                                this.state.status ?
                                    <div className="e-home e-course-detail-wrap e-webinar-detail-container">
                                        <section className="position-relative">
                                            <div className='container mb-5 pb-5'>
                                                <div className='row'>
                                                    <div className='col-lg-12 pb-3'>
                                                        <a className="e-webinar-link" href="/home">Home</a>
                                                        <img draggable="false" src={Arrow} alt="arrow" className="mx-1" />
                                                        <a className="e-webinar-link" href="/">Webinar
                                                            {/* <img src={Arrow} alt="arrow" className="mx-1" /> */}
                                                            <span>/</span>
                                                            {this.state.data.type == 1 ?
                                                                <a className=" e-webinar-link">Upcoming</a> :
                                                                this.state.data.type == 2 ?
                                                                    <a className="e-webinar-link">Completed</a>
                                                                    :
                                                                    <a className="e-webinar-link">Ongoing</a>
                                                            }
                                                        </a>
                                                        <img draggable="false" src={Arrow} alt="arrow" className="mx-1" />
                                                        <a className='e-webinar-name'>{this.state.data.name}</a>
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <h6 className="e-webinarDetails-h6">
                                                            {this.state.data.name}
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className='row pt-3'>
                                                    <div className='col-lg-8'>
                                                        {
                                                            this.state.converted_thumbnail == null ?
                                                                <div className='carousel slide '>
                                                                    <div className='pt-4 mt-3 e-gap-18px e-tab-items d-flex'>
                                                                        <div className='carousel-item active'>
                                                                            <img data-slide-to="1"
                                                                                draggable="false"
                                                                                className='webinar-detail-img'
                                                                                src={this.state.data.thumbnail}
                                                                                alt="thumbnail" />
                                                                        </div></div>

                                                                </div>
                                                                :
                                                                <div id="carouselWebinar"
                                                                    className='carousel slide '
                                                                    data-bs-wrap="false"
                                                                    data-bs-ride="true" >

                                                                    <div className="carousel-inner e-border-radius-8px">
                                                                        <div className='carousel-item active'>
                                                                            <img draggable="false"
                                                                                className='webinar-detail-img'
                                                                                src={this.state.converted_thumbnail}
                                                                                alt='detail' />

                                                                            {this.state.converted_thumbnail ?
                                                                                <img draggable="false"
                                                                                    className='webinar-detail-play'
                                                                                    src={playbtn}
                                                                                    alt='play'
                                                                                    onClick={this._handleOpenModal} /> : null
                                                                            }
                                                                        </div>
                                                                        <div className='carousel-item'>
                                                                            <img draggable="false"
                                                                                className='webinar-detail-img'
                                                                                src={this.state.data.thumbnail}
                                                                                alt='detail' />
                                                                        </div>
                                                                    </div>

                                                                    {/* hide left right navigation if thumbnail is empty */}

                                                                    <a className="carousel-control-prev" >
                                                                        <img draggable="false"
                                                                            href="#carouselWebinar"
                                                                            role="button"
                                                                            data-slide="prev"
                                                                            className="carousel-control-prev-icon"
                                                                            aria-hidden="true"
                                                                            src={ArrowL}
                                                                            alt='arrow' />
                                                                        <span className="sr-only">Previous</span>
                                                                    </a>
                                                                    <a className="carousel-control-next" >
                                                                        <img draggable="false"
                                                                            href="#carouselWebinar"
                                                                            role="button"
                                                                            data-slide="next"
                                                                            className="carousel-control-next-icon"
                                                                            aria-hidden="true"
                                                                            src={ArrowR} alt='arrow' />
                                                                        <span className="sr-only">Next</span>
                                                                    </a>

                                                                    <div className='e-detail-thumbnail'>
                                                                        <div className='pt-4 mt-3 e-gap-18px e-tab-items d-flex'>

                                                                            <img data-target="#carouselWebinar" data-slide-to="0" className="active"
                                                                                draggable="false"
                                                                                src={this.state.converted_thumbnail}
                                                                                alt="thumbnail" />

                                                                            <img data-target="#carouselWebinar" data-slide-to="1"
                                                                                draggable="false"
                                                                                src={this.state.data.thumbnail}
                                                                                alt="thumbnail" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                        <div className='d-lg-none d-md-block d-block mt-5'>
                                                            <div className='row'>
                                                                <div className='col-lg-12'>
                                                                    <div className='e-webinar-details-wrapper pb-3'>
                                                                        <div className='row'>
                                                                            <div className='col-7'>
                                                                                <h5 className='pt-3'>Webinar details</h5>
                                                                            </div>
                                                                            {/* <div className='col-5 text-right'>
                                                                                    <span className='e-share'>
                                                                                        <img draggable="false"
                                                                                            src={Share} alt="" className='mr-3' />
                                                                                        Share now
                                                                                    </span>
                                                                                </div> */}
                                                                            <div className='col-5 text-right e-bookmark '>
                                                                                <img
                                                                                    draggable="false"
                                                                                    className='e-bookmark-img mt-0'
                                                                                    src={this.state.bookmarked ? Select : Unselect}
                                                                                    onClick=
                                                                                    {this._postBookmark.bind(this)}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='row pt-3'>
                                                                            <div className='col-lg-12'>
                                                                                <p className='e-host-detail'>
                                                                                    Webinar ID:
                                                                                    <span className='pl-2'>{this.state.data.webinar_id}</span>
                                                                                </p>
                                                                                <p className='e-host-detail'>
                                                                                    Scheduled on:
                                                                                    <span className='pl-2'>
                                                                                        {this.state.data.scheduled_time}
                                                                                    </span>
                                                                                </p>
                                                                                <p className='e-host-detail'>Duration:
                                                                                    <span className='pl-2'>
                                                                                        {this.state.data.duration}
                                                                                    </span>
                                                                                </p>
                                                                                <p className='e-host-detail d-flex e-flex-wrap'>Language:
                                                                                    {this.state.data.language_ids.map((language, key) => (
                                                                                        <>
                                                                                            <p key={key} className='pl-1 mb-0'>
                                                                                                {key > 0 && <span className='pr-1'>•</span>}
                                                                                                {language.language}
                                                                                            </p>
                                                                                            {/* {
                                                                                                key !== this.state.data.language_ids.length - 1 && (
                                                                                                    <span className='pl-1'>•</span>
                                                                                                )
                                                                                            } */}
                                                                                        </>
                                                                                    ))}
                                                                                </p>

                                                                                {this.state.data.type == 1 ?

                                                                                    <p className='e-host-detail'>Enrolled:
                                                                                        <span className='pl-2'>
                                                                                            {this.state.data.enrolled_count}
                                                                                        </span>
                                                                                    </p>
                                                                                    :
                                                                                    null
                                                                                }

                                                                                <p className='e-host-detail'>
                                                                                    <span className='mr-1 e-course-for'>Category:</span>
                                                                                    {this.state.data.category_ids && this.state.data.category_ids.map((category, index) => (
                                                                                        <>
                                                                                            <span key={index}
                                                                                                className=' e-detail-category'>
                                                                                                {category.category}
                                                                                            </span>

                                                                                            {
                                                                                                index !== this.state.data.category_ids.length - 1 && (
                                                                                                    <span className='px-1'>•</span>
                                                                                                )
                                                                                            }

                                                                                        </>
                                                                                    ))
                                                                                    }

                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                        {this.state.data.type == 1 || this.state.data.type == 3 ?

                                                                            <div className='d-flex'>
                                                                                {this.state.data.is_enrolled == true ?

                                                                                    <div className='e-enrollnow-btn'>
                                                                                        <span className="e-p-btn e-write-review-btn w-100 e-disable">
                                                                                            Already Enrolled
                                                                                        </span>
                                                                                    </div> :
                                                                                    <div className='e-enrollnow-btn'
                                                                                        onClick={this._handleclick}>
                                                                                        <span className="e-p-btn e-write-review-btn w-100">
                                                                                            ENROLL NOW
                                                                                        </span>
                                                                                    </div>
                                                                                }

                                                                                {/* <div className='e-bookmark text-center'>
                                                                                    <img
                                                                                        draggable="false"
                                                                                        className='e-bookmark-img'
                                                                                        src={this.state.bookmarked ? Select : Unselect}
                                                                                        onClick=
                                                                                        {this._postBookmark.bind(this)}
                                                                                    />
                                                                                </div> */}
                                                                            </div>
                                                                            :
                                                                            this.state.data.type == 2 ?
                                                                                <div className='d-flex'>
                                                                                    <div className='e-enrollnow-btn'>
                                                                                        <a href='https://www.google.com' target='_blank'>
                                                                                            <span className="e-p-btn e-write-review-btn w-100">
                                                                                                Watch Webinar
                                                                                            </span>
                                                                                        </a>
                                                                                    </div>
                                                                                    {/* <div className='e-bookmark text-right'>
                                                                                        <img
                                                                                            draggable="false"
                                                                                            className='e-bookmark-img'
                                                                                            src={this.state.bookmarked ? Select : Unselect}
                                                                                            onClick=
                                                                                            {this._postBookmark.bind(this)} />
                                                                                    </div> */}
                                                                                </div> : null
                                                                        }
                                                                    </div>


                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            {this.state.profileData.alice_blue_id == null ?
                                                                                <span className="e-s-btn mt-4 py-3 w-100 text-center"
                                                                                    onClick={this._accountHandleclick}>
                                                                                    Open An Account
                                                                                </span> : null
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {this.state.profileData.alice_blue_id != null ?
                                                                        <div className='row e-social-icon mt-4 mx-1'>

                                                                            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://tradeschool.aliceblueonline.com/webinar-detail?id=${this.state.data.id}`}
                                                                                target='_blank'>
                                                                                <img draggable="false" src={LinkdIn} alt='linkdIn' />
                                                                            </a>
                                                                            <a href={`https://twitter.com/intent/tweet?url=https://tradeschool.aliceblueonline.com/webinar-detail?id=${this.state.data.id}&text=Hey, I recommend you to register for this exclusive TradeSchool Webinar which gives you valuable insights and practical strategies. Join here `}
                                                                                target='_blank'>
                                                                                <img draggable="false" src={Twitter} alt='twitter' />
                                                                            </a>
                                                                            <a href={`https://web.whatsapp.com/send?text=Hey, I recommend you to register for this exclusive TradeSchool Webinar which gives you valuable insights and practical strategies. Join through my referral link https://tradeschool.aliceblueonline.com/webinar-detail?id=${this.state.data.id}`}
                                                                                target='_blank'>
                                                                                <img draggable="false" src={Whatsapp} alt='whatsapp' />
                                                                            </a>
                                                                            <a href={`https://www.facebook.com/sharer/sharer.php?u=https://tradeschool.aliceblueonline.com/webinar-detail?id=${this.state.data.id}`}
                                                                                target='_blank'>
                                                                                <img draggable="false" src={Facebook} alt='facebook' />
                                                                            </a>

                                                                            <a>
                                                                                <img draggable="false"
                                                                                    className='e-social-icon-img'
                                                                                    src={LinkIcon}
                                                                                    alt='link' onClick={this._copyNavigationURL} />
                                                                            </a>

                                                                        </div> : null}

                                                                    {/* <div className='e-available-wrap mt-4'>
                                                                        <div className='row'>
                                                                            <div className='col-lg-12'>
                                                                                <h4>Join our platform now to enjoy amazing offers</h4>
                                                                                <h6 className='pt-3'>Available on</h6>
                                                                                <div className='position-relative e-z-index-9'>
                                                                                    <a target="_blank"
                                                                                        href='https://play.google.com/store/apps/details?id=com.tradeschool&hl=en_IN'>
                                                                                        <img draggable="false"
                                                                                            src={Playstore}
                                                                                            alt="play-store"
                                                                                            className='e-cursor-pointer' />
                                                                                    </a>

                                                                                </div>
                                                                                <div>
                                                                                    <img draggable="false"
                                                                                        src={Mask} alt="" className='e-mask-img' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}

                                                                    {/* <div className='e-refer-earn-wrap-webinar mt-4'>
                                                                            <div className='row'>
                                                                                <div className='col-lg-12'>
                                                                                    <h5 >Refer and earn</h5>
                                                                                    <p className='pt-2'>Refer this product with your friends and family; earn 10% of product purchase amount as referral bonus when your friends or family purchase it.</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row e-enroll-wrap'>
                                                                                <div className='col-lg-12 '>
                                                                                    <div className=''>
                                                                                        <div className='row'>
                                                                                            <div className='col-lg-12'>
                                                                                                <span>Generate link</span>
                                                                                            </div>
                                                                                            <div className='col-lg-9 col-9 pt-2'>
                                                                                                <input type="text" className="e-text e-webinar-id-refer" placeholder="Enter aliceblue customer ID" value={this.state.ids} onChange={(e) => this.setState({ ids: e.target.value })} />
                                                                                            </div>
                                                                                            <div className='col-lg-3 col-3 pl-0 pt-3'>
                                                                                                <span className='e-id-arrow'>
                                                                                                    <img draggable="false"
                                                                                                        src={RightArrow} alt="" />
                                                                                                </span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row e-social-icon'>
                                                                                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=tradeschool.com/webinar-detail?id=${this.state.data.id}`}
                                                                                    target='_blank'>
                                                                                    <img draggable="false" src={LinkdIn} alt='linkdIn' />
                                                                                </a>
                                                                                <a href={`https://twitter.com/intent/tweet?url=tradeschool.com/webinar-detail?id=${this.state.data.id}`}
                                                                                    target='_blank'>
                                                                                    <img draggable="false" src={Twitter} alt='twitter' />
                                                                                </a>
                                                                                <a href={`https://web.whatsapp.com/send?text=tradeschool.com/webinar-detail?id=${this.state.data.id}`}
                                                                                    target='_blank'>
                                                                                    <img draggable="false" src={Whatsapp} alt='whatsapp' />
                                                                                </a>
                                                                                <a href={`https://www.facebook.com/sharer/sharer.php?u=tradeschool.com/webinar-detail?id=${this.state.data.id}`}
                                                                                    target='_blank'>
                                                                                    <img draggable="false" src={Facebook} alt='facebook' />
                                                                                </a>
                                                                                <a >
                                                                                    <img draggable="false"
                                                                                        className='e-social-icon-img'
                                                                                        src={LinkIcon}
                                                                                        alt='link' onClick={this._copyNavigationURL} />
                                                                                </a>
                                                                                
                                                                            </div>
                                                                            
                                                                        </div> */}
                                                                    {this.state.profileData.alice_blue_id != null ?
                                                                        <ReferEarn productId={this.state.id}
                                                                            webinarName={this.state.data.name}
                                                                            partner_id={this.state.profileData ? this.state.profileData.alice_blue_id : null} />
                                                                        :
                                                                        null
                                                                    }

                                                                    {/* {
                                                                            this.state.profileData.alice_blue_id != null ?
                                                                                <ReferEarn productId={this.state.id}
                                                                                    partner_id={this.state.profileData ? this.state.profileData.alice_blue_id : null} />
                                                                                :
                                                                                null
                                                                        } */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row pt-4 mt-2 position-relative">
                                                            <div className="col-lg-12 col-md-12 col-12 e-webinar-tab-wrap">
                                                                {
                                                                    this.state.tabIndex === 1 ?
                                                                        <span className="e-tabs-item e-active mr-5">About</span>
                                                                        :
                                                                        <span onClick={this.tabItemClick.bind(this, 1)}
                                                                            className="e-color-inactive mr-5">About</span>
                                                                }

                                                                {/* {
                                                                    this.state.tabIndex === 2 ?
                                                                        <span className="e-tabs-item e-active mr-5">Review</span>
                                                                        :
                                                                        <span onClick={this.tabItemClick.bind(this, 2)}
                                                                            className="e-color-inactive mr-5">Review</span>
                                                                } */}

                                                            </div>
                                                        </div>

                                                        <div className='row pt-5 e-webinar-about'>
                                                            {
                                                                this.state.tabIndex == 1 ?
                                                                    <>
                                                                        <div className='col-lg-12'>
                                                                            <h4>Agenda</h4>
                                                                            <ul>
                                                                                {this.state.data.agenda && this.state.data.agenda.map((item, index) => (
                                                                                    <li key={index}>{item}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>

                                                                        <div className='col-lg-12 pt-2'>
                                                                            <h4>Description</h4>
                                                                            {parse(this.state.data.description)}
                                                                        </div>

                                                                        <div className='col-lg-12 pt-2'>
                                                                            <h4 className='pb-2'>FAQ</h4>
                                                                            < Faq />
                                                                        </div>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className='col-lg-9'>
                                                                            <h4>All reviews</h4>
                                                                        </div>
                                                                        <div className='col-lg-3 text-right'>
                                                                            <span className="e-p-btn e-write-review-btn">Write review</span>
                                                                        </div>
                                                                        <div className='col-lg-12 pt-2'>
                                                                            <ReviewCard />
                                                                        </div>
                                                                        <div className='col-lg-12 pt-2'>
                                                                            <ReviewCard />
                                                                        </div>
                                                                        <div className='col-lg-12 pt-2'>
                                                                            <ReviewCard />
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 d-lg-block d-md-none d-none'>
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                <div className='e-webinar-details-wrapper pb-3'>
                                                                    <div className='row'>
                                                                        <div className='col-7'>
                                                                            <h5 className='pt-3'>Webinar details</h5>
                                                                        </div>
                                                                        <div className='col-5 text-right e-bookmark '>
                                                                            <img
                                                                                draggable="false"
                                                                                className='e-bookmark-img mt-0'
                                                                                src={this.state.bookmarked ? Select : Unselect}
                                                                                onClick=
                                                                                {this._postBookmark.bind(this)}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                    <div className='row pt-3'>
                                                                        <div className='col-lg-12'>
                                                                            <p className='e-host-detail'>
                                                                                Webinar ID:
                                                                                <span className='pl-2'>
                                                                                    {this.state.data.webinar_id}
                                                                                </span>
                                                                            </p>
                                                                            <p className='e-host-detail'>
                                                                                Scheduled on:
                                                                                <span className='pl-2'>
                                                                                    {this.state.data.scheduled_time_text}
                                                                                </span>
                                                                            </p>
                                                                            <p className='e-host-detail'>Duration:
                                                                                <span className='pl-2'>
                                                                                    {this.state.data.duration}
                                                                                </span>
                                                                            </p>
                                                                            <p className='e-host-detail d-flex e-flex-wrap'>Language:
                                                                                {this.state.data.language_ids.map((language, key) => (
                                                                                    <>

                                                                                        <p key={key} className='mb-0 pl-1'>
                                                                                            {key > 0 && <span className='pr-1'>•</span>}
                                                                                            {language.language}
                                                                                        </p>
                                                                                        {/* {
                                                                                            key !== this.state.data.language_ids.length - 1 && (
                                                                                                <span className='pl-1'>•</span>
                                                                                            )
                                                                                        } */}
                                                                                    </>
                                                                                ))}
                                                                            </p>
                                                                            {
                                                                                this.state.data.type == 1 ?
                                                                                    <p className='e-host-detail'>Enrolled:
                                                                                        <span className='pl-2'>
                                                                                            {this.state.data.enrolled_count}
                                                                                        </span>
                                                                                    </p>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            <p className='e-host-detail text-truncate'>
                                                                                <span className='mr-1 e-course-for'>Category:</span>

                                                                                {this.state.data.category_ids && this.state.data.category_ids.map((category, index) => (
                                                                                    <>
                                                                                        <span className='e-detail-category'
                                                                                            key={index}>
                                                                                            {category.category}
                                                                                        </span>

                                                                                        {
                                                                                            index !== this.state.data.category_ids.length - 1 && (
                                                                                                <span className='px-1'>•</span>
                                                                                            )
                                                                                        }

                                                                                    </>
                                                                                ))
                                                                                }
                                                                            </p>
                                                                            {this.state.data.type == 2 ?
                                                                                <p className='e-host-detail'>Pass code:
                                                                                    <span className='pl-2'>
                                                                                        {this.state.data.passcode}
                                                                                    </span>
                                                                                </p> : null
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {this.state.data.type == 1 || this.state.data.type == 3 ?

                                                                        <div className='d-flex'>
                                                                            {this.state.data.is_enrolled == true ?

                                                                                <div className='e-enrollnow-btn'>
                                                                                    <span className="e-p-btn e-write-review-btn w-100 e-disable">
                                                                                        Already Enrolled
                                                                                    </span>
                                                                                </div> :
                                                                                <div className='e-enrollnow-btn'
                                                                                    onClick={this._handleclick}>
                                                                                    <span className="e-p-btn e-write-review-btn w-100">
                                                                                        ENROLL NOW
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        this.state.data.type == 2 ?
                                                                            <div className='d-flex'>
                                                                                <div className='e-enrollnow-btn'>
                                                                                    <a href={this.state.data.recorded_video_url} target='_blank'>
                                                                                        <span className="e-p-btn e-write-review-btn w-100">
                                                                                            Watch Webinar
                                                                                        </span>
                                                                                    </a>
                                                                                </div>
                                                                                {/* <div className='e-bookmark text-right'>
                                                                                    <img
                                                                                        draggable="false"
                                                                                        className='e-bookmark-img'
                                                                                        src={this.state.bookmarked ? Select : Unselect}
                                                                                        onClick=
                                                                                        {this._postBookmark.bind(this)} />
                                                                                </div> */}
                                                                            </div> : null
                                                                    }
                                                                </div>

                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        {this.state.profileData.alice_blue_id == null ?
                                                                            <span className="e-s-btn mt-4 py-3 w-100 text-center"
                                                                                onClick={this._accountHandleclick}>
                                                                                Open An Account
                                                                            </span> : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.profileData.alice_blue_id != null ?
                                                                        <div className='row e-social-icon mt-4 mx-1'>

                                                                            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=tradeschool.com/webinar-detail?id=${this.state.data.id}`}
                                                                                target='_blank'>
                                                                                <img draggable="false" src={LinkdIn} alt='linkdIn' />
                                                                            </a>
                                                                            <a href={`https://twitter.com/intent/tweet?url=https://tradeschool.com/webinar-detail?id=${this.state.data.id}&text=Hey, I recommend you to register for this exclusive TradeSchool Webinar which gives you valuable insights and practical strategies. Join here`}
                                                                                target='_blank'>
                                                                                <img draggable="false" src={Twitter} alt='twitter' />
                                                                            </a>
                                                                            <a href={`https://web.whatsapp.com/send?text=Hey, I recommend you to register for this exclusive TradeSchool Webinar which gives you valuable insights and practical strategies. Join here https://tradeschool.com/webinar-detail?id=${this.state.data.id}`}
                                                                                target='_blank'>
                                                                                <img draggable="false" src={Whatsapp} alt='whatsapp' />
                                                                            </a>
                                                                            <a href={`https://www.facebook.com/sharer/sharer.php?u=tradeschool.com/webinar-detail?id=${this.state.data.id}`}
                                                                                target='_blank'>
                                                                                <img draggable="false" src={Facebook} alt='facebook' />
                                                                            </a>

                                                                            <a>
                                                                                <img draggable="false"
                                                                                    className='e-social-icon-img'
                                                                                    src={LinkIcon}
                                                                                    alt='link' onClick={this._copyNavigationURL} />
                                                                            </a>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                                {/* <div className='e-available-wrap mt-4'>
                                                                    <div className='row'>
                                                                        <div className='col-lg-12'>
                                                                            <h4>Join our platform now to enjoy amazing offers</h4>
                                                                            <h6 className='pt-3'>Available on</h6>
                                                                            <div className='position-relative e-z-index-9'>
                                                                                <a target="_blank"
                                                                                    href='https://play.google.com/store/apps/details?id=com.tradeschool&hl=en_IN'>
                                                                                    <img draggable="false"
                                                                                        src={Playstore}
                                                                                        alt="play-store"
                                                                                        className='e-cursor-pointer' />
                                                                                </a>
                                                                            </div>

                                                                            <div>
                                                                                <img draggable="false"
                                                                                    src={Mask} alt="" className='e-mask-img' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}


                                                                {
                                                                    this.state.profileData.alice_blue_id != null ?
                                                                        <ReferEarn productId={this.state.id}
                                                                            webinarName={this.state.data.name}
                                                                            partner_id={this.state.profileData ? this.state.profileData.alice_blue_id : null} />
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    :
                                    <NotFound />
                            }
                        </React.Fragment>
                        :
                        null
                }
                {
                    this.state.toast ?
                        <Toast data={this.state.toast} type={this.state.toastType} />
                        :
                        null
                }

                {
                    this.state.showEnrollModal == true &&
                    <JoinWebinarModal webinarId={this.state.id}
                        success={() => {
                            this.setState({
                                showEnrollModal: false
                            }, () => {
                                $('#joinWebinarModal').modal('hide');
                            })
                        }}
                        profileData={this.state.profileData} />
                }

                {
                    this.state.showModal == true &&
                    <OpenAccountModal webinarId={this.state.id}
                        success={() => {
                            this.setState({
                                showModal: false
                            }, () => {
                                $('#openAccountModal').modal('hide');
                            })
                        }}
                        profileData={this.state.profileData} />
                }

                {
                    this.state.showYoutubeModal == true &&
                    <YoutubeModal youtubeData={this.state.data} />
                }

            </React.Fragment>
        );
    }
}

export default WebinarDetail;
