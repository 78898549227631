/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/

/*Services*/




/*Assets*/


import Img1 from '../../assets/images/webinar/latest.svg';
import Save from '../../assets/images/profile/save.svg';
import PlayBtn from '../../assets/images/webinar/play-btn.svg';

class ProfileWebinarCard extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    new WOW.WOW().init();
    $('html,body').animate({ scrollTop: 0 }, 'slow');
  }


  render() {
    return (
        <div className="card e-course-card e-card-transition card3 wow fadeInUp e-live-webinar-card e-upcoming-webinar-card e-latest-webinar e-overlay" data-wow-delay="">
        <div className="position-relative">

          <img className="card-img-top e-course-cover" src={Img1} alt=""/>


          <span class="e-course-header mr-5">Virtual zero administration archive</span>
          <img className="e-play-btn2" src={PlayBtn} alt=""/>

          <span className="e-course-save"> <img className="" src={Save} alt=""/></span>
          <span className="e-course-author">02:00:00</span>
        </div>

      </div>


    );
  }
}

export default ProfileWebinarCard;
// <span className="e-course-author">iii</span>
// <img className="" src={PlayBtn} alt=""/>
// <span className="e-course-live">Live</span>
