/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';

/*assets*/
import Arrow from '../../assets/images/icons/category-2.svg';


class CategoryHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      category: '',
      subCategory: '',
      menu: false
    }
  }

  componentDidMount() {
    this.checkWidth();
    this.getCategoryData();
    this.getWebinarCat();
  }

  getCategoryData(){
    var url = devconfig.user+"/course-category/get-all";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ Courses: response.data});
      }
      else{
        this.setState({ Courses: null});
      }
    }.bind(this))
  }

   getWebinarCat(){
    var url = devconfig.user+"/category/get-all";

    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ Webinars: response.data});
      }
      else{
        this.setState({ Webinars: null});
      }
    }.bind(this))
  }

  checkWidth(){
    if(window.innerWidth>991){
      this.setState({category: 'Webinars'});
    }
  }
  catClick(cat, id){
    if(cat == 'Courses'){
      window.location.href = '/all-courses?id='+id;
    }else{
      window.location.href = '/all-webinars?id='+id;
    }
    // this.setState({product: element, menu: false})
  }

  render() {

    const categories = [ 'Webinars'];
    // const subCategories = ['Fashion & Clothing', 'Footwear', 'Watches', 'Accessories', 'Fabrics', 'Ties & Socks', 'Grooming'];
    // const products = ['Algo trading', 'Fundamental analysis', 'Indicators', 'Investments','Options','Softwares']
    // const products={'Webinars':['Algo trading', 'Fundamental analysis', 'Indicators', 'Investments','Options','Softwares'],'Courses':['All Courses','Algo trading','Indicators']}

    return (
      <div className="dropdown d-inline-block e-mega-header">
        <button className="btn e-dropdown-btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" onClick={()=>this.setState({menu: !this.state.menu})}>
          Categories <img src={Arrow} alt='Ad' className="e-mega-header-ad"/>
        </button>
        <div className={this.state.menu? "position-fixed d-block": "d-none"} style={{zIndex:"900", width:"100vw", height: "100vh", top: "0", left:"0", backgroundColor: "#00000005"}} onClick={()=>this.setState({menu: false})}></div>
          <div className={this.state.menu? "dropdown-menu show mx-3":"dropdown-menu mx-3"} aria-labelledby="dropdownMenuButton">
            <div className="row mx-0 d-none d-lg-flex">
              <div className="col-lg-12">
                <div className="row p-2">
                  <div className="col-lg-4 px-0">
                    {
                      categories.map((element,key) =>(
                        <span key={key} className={this.state.category==element? "e-header-btn d-block py-2 pl-3 e-btn-on": "e-header-btn d-block py-2 pl-3"} onMouseEnter={()=>this.setState({category: element})}>{element}</span>
                      ))
                    }
                  </div>

                   {/*<div className="col-lg-6 px-0 border-right">
                  {
                    this.state.category=='Webinars'||this.state.category=='Courses'?
                    <>
                    {
                      subCategories.map(element=>(
                        <span className={this.state.subCategory==element? "d-block py-2 pl-3 e-btn-on": " d-block py-2 pl-3"} onMouseEnter={()=>this.setState({subCategory: element})}>{element}</span>
                      ))
                    }
                    </>
                    :
                    null
                    }
                  </div>*/}


                  <div className="col-lg-8 px-0">
                  {
                    this.state.category=='Courses'?
                    <React.Fragment>
                      {
                      this.state.Courses && this.state.Courses.map((element,key)=>(
                        <span key={ key} className="w-100 d-block pt-2 pl-3 e-product-height e-active-color" style={{cursor: "pointer"}} onClick={()=>{this.catClick(this.state.category, element.id)}}>{element.name}</span>
                      ))
                    }
                    </React.Fragment>
                    :
                    this.state.category=='Webinars'?
                    <React.Fragment>
                      {
                      this.state.Webinars && this.state.Webinars.map((element,key)=>(
                        <span key={key} className="w-100 d-block pt-2 pl-3 e-product-height e-active-color" style={{cursor: "pointer"}} onClick={()=>{this.catClick(this.state.category, element.id)}}>{element.name}</span>
                      ))
                    }
                    </React.Fragment>
                    :
                    null
                  }
                  </div>

                </div>
              </div>

            </div>
            {/*<div className="row mx-0 d-flex d-lg-none">
              <div className="col-12 px-0">
                {
                  categories.map(element=>(
                   <>
                    <span className={this.state.category==element? "e-header-btn d-block py-2 pl-3 e-btn-on": "e-header-btn d-block py-2 pl-3"} onClick={()=>{if(this.state.category!=element){this.setState({category: element})}else{this.setState({category: ''})}}}>{element}</span>
                    <div className={this.state.category==element? "container": "container d-none"}>
                      {
                        subCategories.map(sub=>(
                          <>
                            <span className="e-header-btn d-block py-2 pl-3" onClick={()=>{if(this.state.subCategory!=sub){this.setState({subCategory: sub})}else{this.setState({subCategory: ''})}}}>{sub}</span>
                            <div className={this.state.subCategory==sub? "container": "container d-none"}>
                              {
                                products.map(prod=>(
                                  <span className="d-block py-2 pl-3" style={{cursor: "pointer"}} onClick={()=>this.setState({product: element, menu: false, category: '', subCategory:''})}>{prod}</span>
                                ))
                              }
                            </div>
                          </>
                        ))
                      }
                    </div>
                   </>
                  ))
                }
              </div>

            </div>*/}
          </div>
       </div>
    );
  }
}

export default CategoryHeader;
