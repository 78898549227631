/*
 *   File : search.js
 *   Author URI : https://evoqins.com
 *   Description : search component
 *   Integrations : NA
 *   Version : v1.1
 */

import React, { Component } from 'react';

class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {

            search: "",

        };
    }

    searchChange = (e) => {
        this.setState({
            search: e.target.value
        });
        this.props.searchChange(e.target.value);
    }



    render() {

        return (
            <>
                <input
                    type="text"
                    value={this.props.value}
                    className={this.props.className}
                    placeholder={this.props.placeholder}
                    onKeyPress={this.props.onKeyPress}
                    onChange={(e) => { this.searchChange(e) }}
                />
            </>
        );
    }

}
export default SearchInput;