/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';
import NoDataFound from '../../components/common/nothing';

/*Components*/
import CardPlaceholder from '../../components/placeholder/card';
import CourseCard from '../../components/cards/card';
import orderHistory from './orderHistory';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';
import ApiLoader from '../../components/common/apiLoader';

/*Assets*/
import cover from '../../assets/images/home/cover.png';
import BannerPattern1 from '../../assets/images/all-courses/banner-pattern-1.svg';
import BannerPattern2 from '../../assets/images/all-courses/banner-pattern-2.svg';
import CourseBanner from '../../assets/images/profile/course.svg';

class Notification extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      dataLoading: false,
    }
  }

  componentDidMount() {
    new WOW.WOW().init();

    this.getData(1);
  }

  getData(tempPageNum){
    this.setState({dataLoading: true});

    var url = devconfig.user+"/notification/web-notifications";
    APIGet(url).then(function(response){
      if(response.status){
        this.setState({ data: response.data, dataLoading: false});
        new WOW.WOW().init();
      }
      else{
        this.setState({ data: [], dataLoading: false});
      }
    }.bind(this))
  }

  notificationClick(tempID){
    var url = devconfig.user+"/notification/read";
    var data = JSON.stringify({
      "notification_id" : tempID
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        
      }
    }.bind(this))
  }

  render() {
    return (
      <div className="e-notification-wrap">
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4 className="pb-4">All notifications</h4>
              </div>
            </div>

            {
              this.state.dataLoading ?
                <ApiLoader/>
              :
                <div>
                  {
                    this.state.data && this.state.data.length ?
                      <div>
                        <div className="row">
                          <div className="col-lg-12">
                          <NoDataFound head="Nothing Found" data="No notification found yet"/>
                            {/* <div className="e-notify-wrap">
                            { 
                              this.state.data.map( (data, index) => {
                                var tempClass = "e-notify-item"

                                if(!data.read){
                                  tempClass = "e-notify-item e-active"
                                }

                                var tempUrl = "/all-courses"
                                if(!data.general){
                                  tempUrl = "course-details?id="+data.data_id
                                }
                                return(
                                  <Link key={index} onClick={this.notificationClick.bind(this, data.id)} to={tempUrl}>
                                    <div className={tempClass}>
                                      {data.text}
                                    </div>
                                  </Link>
                                );
                              })
                            }
                            </div> */}
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="col-lg-12 text-center">
                            <span className="e-s-btn">Load more</span>
                          </div>
                        </div> */}
                      </div>
                    :
                      <div>
                        <NoDataFound head="Nothing Found" data="No new notification found yet!"/>
                      </div>
                  }
                </div>
            }

          </div>
        </section>
      </div>
    );
  }
}

export default Notification;
