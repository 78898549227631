/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';

/*Components*/

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';


/*Assets*/
import illustration from '../../assets/images/modals/buy-modal-illustration.png';
import Success from '../../assets/images/modals/success.svg';


class BuyCourserModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      amount: 100,
      enrolledId: null,
      paymentUpdated: 0,
      url: ""
    }
    this.openCheckout = this.openCheckout.bind(this);
  }

  componentDidMount() {
    var query = this.getUrlVars();
    if(query.pstatus && query.pstatus){
      this.setState({ paymentUpdated: 1});
      $('#buyCourserModal').modal('show');
      this.paymentStatus(true, query.transaction_id);
    }
  }

  getUrlVars(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  openCheckout() {
    console.log(this.props.data)
    this.enroll();
  }

  enroll(){
     var tempref = ""
    if(localStorage.getItem("ts_ref") && this.props.id == localStorage.getItem("ts_id")){
      tempref = localStorage.getItem("ts_ref")
    }

    var url = devconfig.user+"/course/enroll";
    var data = JSON.stringify({
      "course_id" : this.props.id,
      "state_id" : this.state.state_id,
      "gst_no" : this.state.gst_no,
      "referral_code" : tempref,
      "buy_using_wallet" : this.props.pmethod
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        sessionStorage.setItem("enrolledId", response.data.enrolled_id);
        if(response.data.payment_status){
          this.setState({ paymentUpdated: 1});
        }
        if(!this.props.pmethod){
          var tempData = JSON.parse(localStorage.getItem('userData'));
    
          var tempPrice = parseInt(this.props.data.total_amount) * 100;
    
          if(this.props.data.offer_price){
            tempPrice = parseInt(this.props.data.offer_price) * 100;
          }
          else{
            tempPrice = parseInt(this.props.data.price) * 100;
          }
          
    
          let options = {
            "key": "rzp_live_ybjIzKToKDFtXK",  //rzp_test_T56yrs8IVNrcbK rzp_live_ybjIzKToKDFtXK
            "amount": tempPrice, // 2000 paise = INR 20, amount in paisa
            "currency": "INR",
            "name": "Aliceblue",
            "description": "Trade school",
            "image": "https://alicebluecoursebucket.s3.ap-south-1.amazonaws.com/logo.png",
            "callback_url": 'https://your-server/callback_url',
            // "order_id": response.data.razorpay_order_id, // comment this dev
            "handler": function (response){
              if(response.razorpay_payment_id){
                var tempUrl = window.location.href;
                var tempUpdatedUrl = tempUrl + "&pstatus=true&transaction_id="+response.razorpay_payment_id;
                window.location.replace(tempUpdatedUrl);
              }
            },
            "prefill": {
              "name": tempData.name,
              "email": tempData.email,
              "contact": tempData.mobile
            },
            "notes": {
              "address": "Course enrolled - Id : "+this.props.id,
              "app_name": "Tradeschool",
              "customer_name": "Customer Name :"+ tempData.name,
              "tutor_name": "Tutor Name :"+ this.props.data.creator,
              "course_name" : "Course Name :"+this.props.data.name,
              "tutor_id" :  "Tutor - Id :"+this.props.data.creator_id
            },
            "theme": {
              "color": "#065CAB"
            }
          };
    
          let rzp = new window.Razorpay(options);
          rzp.open();
        }
      }
      else{
        
      }
    }.bind(this))

  }

  paymentStatus(tempStatus, tempTranID){
    var url = devconfig.user+"/course/payment-status";
    var data = JSON.stringify({
      "enrolled_id": sessionStorage.getItem("enrolledId"),
      "payment_status" : tempStatus,
      "transaction_id" : tempTranID
    })
    APIPost(url, data).then(function(response){
      if(response.status){
        // setTimeout(
        //   function() {
        //     // var tempUrl = window.location.href.split('pstatus')[0];
        //     // window.location.replace(tempUrl);
        //   }.bind(this), 3000
        // );
      }
    }.bind(this))
  }
   close=()=>{
    // this.props.openReview()
    var tempUrl = window.location.href.split('pstatus')[0];
    window.location.replace(tempUrl+'purchased=true');
    
  }
    
    // if(this.props.id == localStorage.getItem("ts_id")){
    //   // window.location ='/course-details?id='+this.props.id
    // }
    

  render() {
    return (
      <div className="modal fade e-buy-course-wrap" id="buyCourserModal" tabIndex="-1" role="dialog" aria-labelledby="buyCourserModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          {
            !this.state.paymentUpdated ?
              <div className="modal-content">
                <div className="modal-header py-0">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ">
                  <div className="text-center">
                    <img src={illustration} alt="Tradeschool buy popup"/>
                  </div>
                  <h6>Buy this course?</h6>
                  <p className="pb-3">{this.props.name}</p>

                  {/*<div className="e-gst-disclaimer"> {this.props.data.offer_price ? <span> Offer Price ({this.props.data.offer_price})</span> : <span>Price ({this.props.data.price.toFixed(2)}) </span>} + GST( {this.props.data.total_gst.toFixed(2)})</div>*/}
                  <div className="text-center mb-2">
                    <span className="e-p-btn" onClick={this.openCheckout}>Yes, Buy for ₹ {this.props.data.offer_price || this.props.data.free ? this.props.data.offer_price : this.props.data.price}</span>
                  </div>

                </div>
              </div>
            :
              null
          }
          {
            this.state.paymentUpdated == 1 ?
              <div className="modal-content">
                <div className="e-success">
                  <div className="modal-body">
                    <img src={Success} alt="success"/>
                    <h6 className="mt-3">Payment Successful!</h6>
                    <p>Transaction successfully.</p>
                    <div className="text-center">
                        <button className="e-p-btn" onClick={this.close}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            :
              null
          }
          
          {
            this.state.paymentUpdated == 2 ?
              <div className="modal-content">
                <div className="e-success">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <img src={Success} alt="success"/>
                    <h6 className="mt-3">Success!</h6>
                    <p>Thank you for contacting us, our business executive will contact you soon.</p>
                    <div className="text-center mt-3">
                      <a href="/profile?order=true">
                        <span className="e-p-btn">View Order</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            :
              null
          }

        </div>
      </div>
      
    );
  }
}

export default BuyCourserModal;
