/*Package importing*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import Toast from '../../components/popups/toast';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';




/*Assets*/


import Img1 from '../../assets/images/webinar/live1.svg';
import Save from '../../assets/images/webinar/save.svg';
import Fav from '../../assets/images/webinar/fav-active.svg'

class UpcomingWebinarCard extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	componentDidMount() {
		new WOW.WOW().init();
		// $('html,body').animate({ scrollTop: 0 }, 'slow');
		console.log(this.props.data)
	}

	addFav = (temp) => {
		var url = devconfig.user + "/webinar/bookmark";
		var data = JSON.stringify({
			"webinar_id": parseInt(temp),
		})
		APIPost(url, data).then(function (response) {
			if (response.status) {
				this.triggerToast(response.code);
				this.props.reload();
			}

			else {
			}
		}.bind(this));
	}

	triggerToast(temp) {
		if (temp == 0) {
			this.setState({ toast: "Remove From Saved", toastType: "success" })
			setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
			// this.getData(this.state.blogId);
		} else {
			this.setState({ toast: "Add to Saved", toastType: "success" })
			setTimeout(function () { this.setState({ toast: null }); }.bind(this), 4000);
		}
	}
	routeChange(temp) {
		if (!localStorage.getItem("tradeschool_token")) {
			$('#loginModal').modal('toggle');
		}


	}

	render() {
		return (
			<React.Fragment>
				{
					this.props.data ?
						<div className="card e-course-card mb-4 card3 wow fadeInUp e-live-webinar-card e-upcoming-webinar-card e-live-overlay" data-wow-delay="" onClick={() => { this.routeChange(this.props.data.id) }}>
							<div className="position-relative">

								<img className="card-img-top e-course-cover" src={this.props.data.thumbnail} alt="" />
								<span class="e-course-header e-ellipse">{this.props.data.name}</span>
								{/*<button className="e-course-save e-save-btn" onClick={()=>{this.addFav(this.props.data.id)}}> <img className="" src={this.props.data.is_bookmarked ? Fav : Save} alt=""/></button>*/}
								<span className="e-course-author">{this.props.data.scheduled_time}</span>
							</div>

						</div>
						:
						null
				}
				{
					this.state.toast ?
						<Toast data={this.state.toast} type={this.state.toastType} />
						:
						null
				}

			</React.Fragment>

		);
	}
}

export default UpcomingWebinarCard;
// <span className="e-course-author">iii</span>
// <span className="e-course-live">Live</span>
