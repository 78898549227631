/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/


/*Services*/



/*Assets*/
import banner from '../../assets/images/webinar/banner.svg';
import pattern1 from '../../assets/images/webinar/pattern1.svg';
import pattern2 from '../../assets/images/home/banner-pattern-2.svg';
import pattern3 from '../../assets/images/webinar/pattern3.svg';


class WebinarBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        new WOW.WOW().init();
        $('html,body').animate({ scrollTop: 0 }, 'slow');
    }


    render() {
        return (
            <section className="e-banner-section e-webinar-banner position-relative  e-overfloqY-hidden">
                <img
                    className="e-banner-pattern-1 wow fadeIn"
                    data-wow-duration="4s"
                    src={pattern1}
                    alt="Tradeschool courses listing" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h1>Webinars from Experts.</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <h2 className="pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Avitae, quis arcu donec risus vestibulum.</h2>
                            <span className="e-p-btn e-login-with-gmail" data-toggle="modal" data-target="#loginModal">Login Now</span>
                        </div>
                    </div>
                </div>
                <img className="e-banner-pattern-2 wow fadeIn"
                    data-wow-duration="1s"
                    src={pattern2}
                    alt="Tradeschool courses listing" />
                <img className="e-banner-pattern-3 wow fadeIn"
                    data-wow-duration="3s"
                    src={pattern3}
                    alt="Tradeschool courses listing" />
                <img
                    className="e-home-banner"
                    src={banner}
                    alt="best online stock trading courses listing platform" />
            </section>
        );
    }
}

export default WebinarBanner;
