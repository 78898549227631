/*Package importing*/
import React, { Component } from 'react';
import WOW from 'wowjs';

/*Assets and Components*/
import noResultImg from '../../assets/images/placeholder/no-result.svg';

class NoDataFound extends Component {
  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="col-lg-12 pt-0 text-center mt-5 e-no-result-wrap wow fadeIn">
        <img draggable='false' className="e-no-result" src={noResultImg} alt="No data found"/>
        <h6>{this.props.head}</h6>
        <div className="e-noData-txt">{this.props.data}</div>
      </div>
    );
  }
}

export default NoDataFound;
