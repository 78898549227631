import React from 'react';

// import asset from files
import loader from '../../assets/images/loader/lazyload.gif';
function ApiLoader(){
	return(
		<div className="pt-0 text-center mt-5 ">
      <img className="e-page-loader" src={loader} alt="Loader"/>
    </div>
	)
}
export default ApiLoader;