/*Package importing*/
import React, { Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import $ from 'jquery';
import WOW from 'wowjs';

/*Components*/
import ThreeCardSlider from '../../components/slider/three-card-slider';
import ProfileWebinarCard from '../../components/cards/profileSavedWebinar.js';
import NoDataFound from '../../components/common/nothing';
import LatestWebinarCard from '../../components/cards/latestWebinar.js';
import ApiLoader from '../../components/common/apiLoader';
import CardPlaceholder from '../../components/placeholder/card';
import WebinarCard from '../../components/cards/webinar.js';

/*Services*/
import devconfig from '../../config/config.js';
import APIPost from '../../services/apiCall/post';
import APIGet from '../../services/apiCall/get';

// history.push('/')
class YourWebinars extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            pageNum: 1,
            placeholder4: [1, 2, 3, 4],
            saved: [],
            upcoming: [],
            dataLoading: false,
            tabIndex: 1
        }
    }

    componentDidMount() {
        new WOW.WOW().init();
        this.getUpcoming();
        this.getSaved();
    }

    //API - Upcoming saved list
    getUpcoming = () => {
        this.setState({
            dataLoading: true
        });

        var url = devconfig.user + "/webinar/upcoming-bookmarked";
        var data = JSON.stringify({
            "page_num": parseInt(this.state.pageNum)
        })
        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({
                    upcoming: response.data,
                    dataLoading: false
                })
            }
            else {
                this.setState({ dataLoading: false });
            }
        }.bind(this))
    }

    //API - all saved webinars
    getSaved = () => {
        this.setState({
            dataLoading: true
        });
        var url = devconfig.user + "/webinar/bookmarked";
        var data = JSON.stringify({
            "page_num": parseInt(this.state.pageNum),
            "type":1
        })

        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({
                    saved: response.data,
                    dataLoading: false
                })
            }
            else {
                this.setState({
                    dataLoading: false
                });
            }
        }.bind(this))
    }

    // Handle webinar navigation to detail screen
    _handleChangeNavigation = (type, webinar_id) => {
        this.props.history.push(`/webinar-details?id=${webinar_id}`, {
            type: type,
            webinarId: webinar_id
        });
    }

    //handle tab change
    _handleTab = (tempItem) => {
        this.setState({
            tabIndex: tempItem
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="e-section e-profile-web-sec">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 my-3">
                                <span className={`e-profile-tab-item e-saved pl-0 ${this.state.tabIndex === 1 ? 'e-active' : ''}`}
                                    onClick={() => this._handleTab(1)}>
                                    Upcoming
                                </span>
                                <span className={`e-profile-tab-item e-saved  pl-0 ${this.state.tabIndex === 2 ? 'e-active' : ''}`}
                                    onClick={() => this._handleTab(2)}>
                                    Completed
                                </span>
                            </div>
                        </div>
                        {
                            this.state.tabIndex == 1 ?
                                <div className='row'>
                                    {
                                        this.state.upcoming.length == 0?
                                            <NoDataFound data="No Webinar added in saved list" />
                                        :
                                            this.state.upcoming.map((item, key) => (
                                                < >
                                                    {
                                                        this.state.dataLoading ?
                                                            <div className="row" key={key}>
                                                                {
                                                                    this.state.placeholder4.map((data, index) => {
                                                                        return (
                                                                            <div className="col-lg-4 col-md-4 col-6" key={index}>
                                                                                <CardPlaceholder />
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            <div className='col-lg-4 col-md-6 col-sm-6 mt-4'>
                                                                <WebinarCard
                                                                    data={item}
                                                                    type={1}
                                                                    key={this.state.tabIndex}
                                                                    onNavigation={() => this._handleChangeNavigation(1, item.id)} />
                                                            </div>
                                                    }
                                                </>
                                            ))
                                    }
                                </div> 
                            :
                                <div className='row'>
                                    {   
                                        this.state.saved.length == 0?
                                            <NoDataFound data="No Webinar added in saved list" />
                                        :
                                            this.state.saved.map((item, key) => (
                                                < >
                                                    {
                                                        this.state.dataLoading ?
                                                            <div className="row" key={key}>
                                                                {
                                                                    this.state.placeholder4.map((data, index) => {
                                                                        return (
                                                                            <div className="col-lg-4 col-md-4 col-6" key={index}>
                                                                                <CardPlaceholder />
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            <div className='col-lg-3 col-md-4 col-sm-6 mt-4'>
                                                                <LatestWebinarCard
                                                                    data={item}
                                                                    type={1}
                                                                    key={this.state.tabIndex}
                                                                    onNavigation={() => this._handleChangeNavigation(1, item.id)} />
                                                            </div>
                                                    }
                                                </>
                                            ))
                                    }

                                </div>
                        }

                     
                    </div>

                </section>

                {/* <section className="e-section e-grey-bg">
          <div className="container">
            <div className="row mb-4">
              <div className="col-lg-12 col-md-8 col-8">
                <h4>Saved Webinars</h4>
              </div>
            </div>
            <div className="row">
            {
            this.state.dataLoading ?
            <div className="row">
                  { 
                    this.state.placeholder4.map( (data, index) => {
                      return(
                        <div className="col-lg-3 col-md-6 col-6" key={index}>
                          <CardPlaceholder/>
                        </div>
                      );
                    })
                  }
                </div>
            :
            null
           }
              {
                !this.state.dataLoading && this.state.saved && !this.state.saved.length ?
                  <NoDataFound data="No Webinar Saved!!"/>
                  :

                this.state.saved && this.state.saved.map((item , key)=>{
                  return(
                    <div className="col-lg-3 col-md-6 mb-4" key={key}>
                      <LatestWebinarCard data={item} reload={this.getSaved}/> 
                      <WebinarCard  type={true}/>
                     </div>
                  );
                })
                
               
              }
              
            </div>
          </div>
        </section> */}
            </React.Fragment>

        );
    }
}

export default withRouter(YourWebinars);
