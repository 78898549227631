/*
 *   File : join-webinar.js
 *   Author URI : https://evoqins.com
 *   Description : webinar join component
 *   Integrations :  NA
 *   Version : v1.1
*/

import Input from "../formElements/input";
import React, { Component } from "react";
import $ from 'jquery';

//custom components
import Success from '../../assets/images/modals/success.svg';
import APIPost from "../../services/apiCall/post";
import devconfig from "../../config/config";
import GetURLVar from "../../services/getParams/get-url";
import SelectBox from "../formElements/select-box";
import APIGet from "../../services/apiCall/get";
import Toast from "../popups/toast";

class JoinWebinarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            id: '',
            email: '',
            phone: '',
            city: '',
            EmailErr: '',
            PhoneErr: '',
            CityErr: '',
            selectedState: [],
            categoryOption: null,
            stateCategory: [],
            loading: true
        };
    }

    componentDidMount() {
        this.statesData();
        if (this.props.profileData.state_id != null) {
            this.setState({
                selectedState: {
                    value: this.props.profileData.state_id,
                    label: this.props.profileData.state
                }
            });
        } 
        this.setState({
            name: this.props.profileData.name,
            email: this.props.profileData.email,
            id: this.props.webinarId,
            // selectedState: { value: null, label: null },
            
            phone: this.props.profileData.mobile,
            city: this.props.profileData.city,
            loading: false,
        });

       
        console.log('this.props.profileData :: ',this.props.profileData)
    }

    // API - Enroll for the webinar
    _postEnroll() {

        var url = devconfig.user + "/webinar/enroll";
        var data = JSON.stringify({
            "webinar_id": this.state.id,
            "reffered_by": localStorage.getItem("ts_ref"),
            "mobile_number": this.state.phone,
            "city": this.state.city,
            "state_id": this.state.selectedState.value
        });

        APIPost(url, data).then(function (response) {
            if (response.status) {
                this.setState({
                    enroll: response.data,
                    toast: "Enrolled for webinar successfully",
                    toastType: "success"
                });
                setTimeout(function () {
                    this.setState({
                        toast: null
                    });
                }.bind(this), 5000);
                window.location.reload();
                $("#joinWebinarModal").modal("hide");
            } else {
                this.setState({
                    toast: response.message,
                    toastType: "fail"
                });
                setTimeout(function () {
                    this.setState({
                        toast: null
                    });
                }.bind(this), 5000);
                $("#joinWebinarModal").modal("hide");
            }
        }.bind(this))
    }

    statesData() {
        var url = devconfig.user + "/states/get-all";
        APIGet(url).then(function (response) {
            if (response.status) {
                var tempArray = [];
                response.data.map(function (key, index) {
                    var temp = { value: key.id, label: key.name };
                    tempArray.push(temp);
                }.bind(this));
                this.setState({
                    stateCategory: tempArray
                });
            }
        }.bind(this))
    }




    _handleCLickSend = () => {
        // email validation 
        let validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
        // phone number validation
        let validPhone = /^[6-9]\d{9}$/;
        //enroll handling and also validating Email and Phone 
        if (!validEmail.test(this.state.email)) {
            this.setState({
                EmailErr: 'Please Enter Valid Email'
            });
        }
        if (!validPhone.test(this.state.phone)) {
            this.setState({
                PhoneErr: 'Please Enter Valid Number'
            });
        }
        // if (this.state.city.length == 0) {
        //     this.setState({
        //         CityErr: 'City name required'
        //     });
        // }

        // if (this.state.city.length == 0) {
        //     this.setState({
        //         CityErr: 'City name required'
        //     });
        // }

        else {
            this.setState({
                EmailErr: '',
                PhoneErr: '',
            }, () => {
                this._postEnroll();
            });
        }
    }


    _handleCategoryChange = (categoryOption) => {
        this.setState({
            ...categoryOption,
            selectedState: categoryOption
        });
    }


    render() {
        // console.log("this.props.profileData", this.props.profileData.alice_blue_id);
        return (
            <>
                <div className="modal fade e-form-modal"
                    id="joinWebinarModal"
                    tabIndex="-1" role="dialog"
                    aria-labelledby="joinWebinarModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        {
                            this.state.loading == false &&
                            <div className="modal-content">
                                {
                                    this.state.success ?
                                        <div className="e-success">
                                            <div className="modal-header">
                                                <button type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <img src={Success} alt="success" />
                                                <h6 className="mt-3">Success!</h6>
                                                <p>Thank you for contacting us, our business executive will contact you soon.</p>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="modal-header">
                                                <h5 className="modal-title">Join Webinar</h5>
                                                <button type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => {
                                                        this.setState({
                                                            // phone: '',
                                                            PhoneErr: ''
                                                        })
                                                    }}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                {/* name */}
                                                <Input className='e-disable-input'
                                                    label="Your Name"
                                                    id="u-name"
                                                    type="text"
                                                    value={this.state.name}
                                                    valueChange={(tempVal) => this.setState({
                                                        name: tempVal,
                                                        errorMessage: ""
                                                    })} />

                                                {/* phone number */}
                                                <Input label="Phone Number"
                                                    id="u-phone"
                                                    type="number"
                                                    min="5555555555"
                                                    max="9999999999"
                                                    error={this.state.PhoneErr}
                                                    value={this.state.phone}
                                                    valueChange={(tempVal) => {
                                                        if (tempVal.length < 11) {
                                                            this.setState({
                                                                phone: tempVal
                                                            })
                                                        }
                                                    }} />

                                                {/* email */}
                                                <Input className='e-disable-input'
                                                    label="Email Address"
                                                    id="u-email"
                                                    type="text"
                                                    error={this.state.EmailErr}
                                                    value={this.state.email}

                                                    valueChange={(tempVal) =>
                                                        this.setState({
                                                            email: tempVal
                                                        })} />

                                                {/* select state */}
                                                {this.props.profileData.alice_blue_id ? null :
                                                    <SelectBox className="e-select-box mb-4"
                                                        search={true}
                                                        placeholder="State"
                                                        value={'dsad'}
                                                        defaultValue={this.state.selectedState}
                                                        options={this.state.stateCategory}
                                                        selectchange={this._handleCategoryChange} />}

                                                {/* city */}
                                                {this.props.profileData.alice_blue_id ? null :
                                                    <Input label="City"
                                                        id="u-city"
                                                        type="text"
                                                        error={this.state.CityErr}
                                                        value={this.state.city}
                                                        valueChange={(tempVal) =>
                                                            this.setState({
                                                                city: tempVal
                                                            })} />}
                                                {
                                                    this.state.errorMessage && this.state.errorMessage.length ?
                                                        <div className="e-error">{this.state.errorMessage}</div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {/* && this.state.city && this.state.stateCategory */}
                                            <div className="modal-footer pt-0">
                                                {this.props.profileData.alice_blue_id ?
                                                    <>
                                                        {
                                                            this.state.name && this.state.phone && this.state.email && this.state.selectedState.value != undefined ?
                                                                <span className="e-p-btn e-login-with-gmail"
                                                                    onClick={this._handleCLickSend}>ENROLL</span>
                                                                :
                                                                <span className="e-p-btn e-login-with-gmail e-inactive">ENROLL</span>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            this.state.name && this.state.phone && this.state.email && this.state.city && this.state.stateCategory && this.state.selectedState.value != undefined ?
                                                                <span className="e-p-btn e-login-with-gmail"
                                                                    onClick={this._handleCLickSend}>ENROLL</span>
                                                                :
                                                                <span className="e-p-btn e-login-with-gmail e-inactive">ENROLL</span>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.toast ?
                        <Toast data={this.state.toast} type={this.state.toastType} />
                        :
                        null
                }
            </>
        )
    }
}

export default JoinWebinarModal;