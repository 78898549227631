/*Package importing*/
import React, { Component } from 'react';
import $ from 'jquery';
import WOW from 'wowjs';

/*Assets*/
import cover from '../../assets/images/home/cover.png';
import BannerPattern1 from '../../assets/images/all-courses/banner-pattern-1.svg';
import BannerPattern2 from '../../assets/images/all-courses/banner-pattern-2.svg';

class Disclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        new WOW.WOW().init();
        $('html,body').animate({ scrollTop: 0 }, 'slow');

    }



    render() {
        return (
            <div className="e-home e-all-course-wrap e-terms-wrap pb-5 ">
                <section className="e-banner-section position-relative e-banner-privacy">
                    <div className="container position-relative">
                        <img className="e-banner-pattern-1 wow fadeIn" data-wow-duration="4s" src={BannerPattern1} alt="Tradeschool courses listing" />
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>Disclaimer</h3>
                            </div>
                        </div>
                        <img className="e-banner-pattern-2 wow fadeIn" data-wow-duration="4s" src={BannerPattern2} alt="Tradeschool courses listing" />
                    </div>
                </section>

                <section className="my-4 pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10">
                                <h6 className="c-dark">Trade School Disclaimer</h6>
                                <p>Investments in securities markets are subject to market risks. Read all the related documents carefully before investing. Brokerage will not exceed SEBI’s prescribed limit. The securities quoted are exemplary and are not recommendatory.</p>
                                <div className='e-disclaimer-numbers'>
                                    <p><span>SEBI Registration No.:</span>INZ000156038 (NSE, BSE, MCX)</p>
                                    <p><span>NSE Trading Member Code:</span>90112</p>
                                    <p><span>BSE Clearing Number:</span>6670</p>
                                    <p><span>MCX Member Code:</span>56710</p>
                                    <p><span>CDSL DP ID: 12085300, DP SEBI REG:</span>IN-DP-364-2018</p>
                                    <p className='py-3'><span>Corporate Office Address:</span>
                                        153, MRB Arcade, Bagalur Main Road, Yelahanka, Bengaluru- 560063
                                    </p>
                                    <p><span>Registered Office Address:</span>
                                        Old No.56/2, New No.58, LIC Nagar, Vinayagar Kovil Street, Erode -8, Tamil Nadu- 638002.
                                    </p>
                                    <p><span>Compliance Officer:</span> Saravanan Thangavel</p>
                                    <div className='e-disclaimer-link'>
                                        <p>Ph–<a href="tel:08045490859">08045490859</a></p>
                                        <p>Email:
                                            <a href="mailto:compliance@aliceblueindia.com">compliance@aliceblueindia.com</a>
                                        </p>
                                        <p className='py-3'>Investor grievance:
                                            <a href="mailto:grievances@aliceblueindia.com">grievances@aliceblueindia.com</a>
                                        </p>
                                    </div>



                                    <p><span>Disclaimer:</span>
                                        Alice Blue Financial Services Pvt Ltd is a financial services intermediary and is engaged as Stock Broker since 2007. Alice Blue and/or any of its affiliates do not provide any tips, advisory, solicitation, opinions or portfolio management services regarding buying, selling and trading of any securities, directly or indirectly, in any manner. Information displayed or laid out by Alice Blue, including social media, are purely for either educational, informational, or discussion purposes and should not be construed as direct or indirect recommendation to invest, trade or speculate in the markets. Alice Blue would welcome and appreciate immediate notification or notice, if any person would come across any such tips, advisory, solicitation, opinions or related services regarding buying, selling and trading of any securities, directly or indirectly, in any manner from any person or platform which is believed to be or likely to be believed as Alice Blue. Please contact compliance@aliceblueindia.com for any of your queries with regard to the same. In no event shall Alice Blue be liable for any investments, trades or speculative activities performed by any person based on any such information or content and all such activities shall be solely at their own risk.</p>
                                    <p>
                                        Customers need to check products & features before investing since the contours of the product rates may change from time to time. Alice Blue Financial Services is not liable for any loss or damage of any kind arising out of investments in these products.
                                    </p>
                                    <p>Investments in Equity, Currency, Futures & Options, and Commodities are subject to market risk.</p>
                                    <p>Clients should read the Risk Disclosure Document issued by SEBI & relevant exchanges & the T&C on www.aliceblueonline.com before investing.</p>
                                    <span>Name of the Investment Advisor: Tavaga</span>
                                    <p className='my-3'>
                                        Tavaga SEBI Registration number (Non-individual) – INA000013457
                                    </p>
                                </div>



                                <h6> Contact Us </h6>
                                <p>
                                    If you have any questions about this Discaimer, You can contact us:
                                </p>
                                <p>
                                    By email: <a href="mailto:tradeschool@aliceblueindia.com">tradeschool@aliceblueindia.com</a>
                                </p>
                                <p>
                                    ©2023 Alice Blue Commodities Pvt Ltd, All rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        );
    }
}

export default Disclaimer;